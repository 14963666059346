import { useState } from 'react'
import { Button, Col, Nav, Table } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
import AdminModals from './AdminModals'
import axios from 'axios'

const KitsWanted = () => {
  const { getCustomer, setSelectedBatch, kitsSent, kitsWanted } = useAdmin()
  const [showModal, setShowModal] = useState({ name: '', show: false })

  const sendEmail = async (id, name, email) => {
    try {
      await axios.post('/api/sendmail/', {
        to: email,
        text: `Dear ${name}, <br/><br/>We have posted sample kits to your address today. If you don't receive them in the next 4-5 days, please let us know.
<br/><br/>
Please use one sample kit for collecting the feather / blood samples of one bird only. There might be more sample kits in our post than what you have ordered, which you utilize for your future orders with us.
<br/><br/>
Should you need any further assistance, please feel free to contact us<br/><br/>
<p>Thanks & Regards,
            <br />
            Team Avigenics<br/><br/>
                      <img
            style='width: 150px;' height: 80px;' }}
            src='https://avigenicsusa.com/images/logo-6.png'
          /><br/><br/>
E-mail:   info@avigenicsusa.com |   Phone:   (313) 338-9022 <br/>
            Avigenics Inc,1562 Belvedere Ave,Okemos, MI 48864, USA. <br/>
            <a href='https://avigenicsusa.com/'>www.avigenicsusa.com</a><br/>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>`,
        subject: `Order ID: ${id}`,
        bcc: 'orders@avigenicsusa.com',
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <Col sm={10}>
        <Table responsive size='sm'>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>E-Mail</th>
              <th>Order id</th>
              <th>Samples</th>
              <th>Kits Type</th>
              <th>Sent?</th>
            </tr>
          </thead>
          <tbody>
            {kitsWanted &&
              kitsWanted.map((batch) => (
                <tr
                  key={batch.id}
                  style={{
                    backgroundColor:
                      (batch.status === 'Completed' && '#00cc00') ||
                      (batch.status === 'Results Ready' && 'lightgreen') ||
                      (batch.status === 'In Progress' && '#CCCC00') ||
                      (batch.status === 'Posted' && '#CCCCCC') ||
                      (batch.status === 'In Lab' && '#9ef') ||
                      (batch.status === 'Arrived' && 'pink') ||
                      (batch.status === 'Delete' && 'salmon'),
                    fontStyle: batch.orderType === 'Manual' && 'italic',
                    textDecoration: batch.orderType === 'Manual' && 'underline',
                  }}
                >
                  <td>
                    <Nav.Link
                      onClick={() => {
                        getCustomer(batch.custId)
                        setShowModal({ name: 'Customer', show: true })
                      }}
                    >
                      {batch.customerName}
                    </Nav.Link>
                  </td>
                  <td>{batch.custId}</td>
                  <td>
                    <Nav.Link
                      onClick={() => {
                        setSelectedBatch(batch)
                        setShowModal({
                          name: 'Order Details',
                          show: true,
                        })
                      }}
                    >
                      {batch.batchName}
                    </Nav.Link>
                  </td>
                  <td>{batch.samples.length}</td>
                  <td>{batch.kitsNeeded.type}</td>
                  {batch.kitsNeeded && batch.kitsNeeded.sent ? (
                    <td>{batch.kitsNeeded.sent}</td>
                  ) : (
                    <td>
                      <Button
                        onClick={() => {
                          kitsSent(batch.id, batch.kitsNeeded)
                          sendEmail(
                            batch.batchName,
                            batch.customerName,
                            batch.custId
                          )
                        }}
                      >
                        Send Now
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      </Col>
      {showModal.show && (
        <AdminModals showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  )
}

export default KitsWanted

import { useEffect } from 'react'
import { useAdmin } from '../../config/AdminContext'
import ComponentToShow from './ComponentToShow'

const Completed = () => {
  const { findFiltered, allBatches } = useAdmin()
  useEffect(() => {
    findFiltered('Completed')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBatches])

  return <ComponentToShow prev={'Results Ready'} next={'In Progress'} />
}

export default Completed

import { useState, useEffect } from 'react'
import {
  Button,
  Container,
  Form,
  Image,
  Modal,
  Nav,
  Table,
} from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import downloadjs from 'downloadjs'
import * as htmlToImage from 'html-to-image'
import ReactPaginate from 'react-paginate'
import './pagination.css'
import * as Icon from 'react-bootstrap-icons'
import moment from 'moment'
import { useGlobal } from '../../config/GlobalContext'
import { useOrder } from '../../config/OrderContext'
//import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
//import download from 'downloadjs'

const APVReport = () => {
  const { allBatches, selectedCustomer, getCustomer, setSelectedCustomer } =
    useAdmin()
  const [APVList, setAPVList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedBatch, setSelectedBatch] = useState({})
  const [showA4PdfModal, setShowA4PdfModal] = useState(false)
  const [items, setItems] = useState([])
  const { updateOrder } = useGlobal()
  const [pageNumber, setPageNumber] = useState(0)
  const curr = new Date()
  curr.setDate(curr.getDate())
  const date = moment(curr.toISOString().split('T')[0]).format('YYYY-MM-DD')
  const [buttonText, setButtonText] = useState('Save Report')
  const [sendingEmail, setSendingEmail] = useState('Send Email')

  const [attachments, setAttachments] = useState([])

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
  }
  let file = ''
  let url = ''
  //   let attachments = []
  let fbConfig
  if (!firebase.apps.length) {
    fbConfig = firebase.initializeApp(firebaseConfig)
  } else {
    fbConfig = firebase.app() // if already initialized, use that one
  }
  useEffect(() => {
    setAPVList(
      allBatches &&
        allBatches
          .filter((batch) => batch.status === 'Results Ready')
          .filter(
            (batch) =>
              batch.samples &&
              batch.samples.some((sample) => sample.testType.includes('APV'))
          )
    )
  }, [allBatches])

  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + 20
  const currentItems = items && items.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(items && items.length / 20)
  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems
            .filter((sample) => sample.testType.includes('APV'))
            .map((sample, index) => (
              <tr key={index} style={{ height: '14px' }}>
                <td
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '14px',
                    marginLeft: 15,
                    padding: 0,
                    textAlign: 'center',
                  }}
                >
                  {'   '} {sample.ringNo}
                </td>
                <td
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '14px',
                    marginLeft: 15,
                    padding: 0,
                    textAlign: 'center',
                  }}
                >
                  {sample.selectedSpecies}
                </td>
                <td
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '14px',
                    marginLeft: 15,
                    padding: 0,
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      color: sample.apvResult === 'Positive' && 'red',
                    }}
                  >
                    {sample.apvResult}
                  </span>
                </td>
              </tr>
            ))}
      </>
    )
  }

  const savePdf = async () => {
    await htmlToImage
      .toBlob(document.getElementById('pdfDiv2'))
      .then(async (blob) => {
        const imgData = await blob
        const storageRef = await fbConfig.storage().ref()
        const fileRef = storageRef.child(
          `APV/${selectedBatch.batchName}_${Number(pageNumber) + 1}.png`
        )
        await fileRef.put(imgData)
        // downloadjs(
        //   imgData,
        //   `${selectedBatch.batchName}_${Number(pageNumber) + 1}.png`,
        //   'image/png'
        // )
        //    setShowA4PdfModal(false)
      })
    const storageRef = fbConfig.storage().ref()
    const fileRef = storageRef.child(
      `APV/${selectedBatch.batchName}_${Number(pageNumber) + 1}.png`
    )
    const url = await fileRef.getDownloadURL()
    console.log('url', url)
    attachments.push({
      filename: `${selectedBatch.batchName}_${Number(pageNumber) + 1}.png`,
      path: url,
    })
    setButtonText('Saved')
  }
  const saveLocal = async () => {
    htmlToImage
      .toBlob(document.getElementById('pdfDiv2'), {
        backgroundColor: 'white',
      })
      .then(function (blob) {
        downloadjs(
          blob,
          `${selectedBatch.batchName}_${Number(pageNumber) + 1}.png`
        )
      })
  }

  const sendEmail = async () => {
    setButtonText('Save Report')
    try {
      await axios
        .post('/api/sendmail/', {
          to: selectedBatch && selectedBatch.custId,
          text: `Dear ${
            selectedBatch && selectedBatch.customerName
          }, <br/><br/>Please see attached Lab report for APV test for order ${
            selectedBatch && selectedBatch.batchName
          }.<br/><br/>
        Please contact us if you have any questions.<br/><br/>
    <p>Thanks & Regards,
                <br />
                Team Avigenics<br/><br/>
                          <img
                style='width: 150px;' height: 80px;' }}
                src='https://avigenics.co.uk/images/logo-6.png'
              /><br/><br/>
             <p>E-mail:   info@avigenicsusa.com  |   Phone:   (313) 338-9022   |  Web: WWW.AVIGENICSUSA.COM</p>
               process.env.DOMAIN
             }<br/>
            Avigenics Inc, 1562 Belvedere Ave, Okemos, MI 48864. <br/>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>`,

          subject: 'APV Lab Report - ' + selectedBatch.batchName,
          attachments,
          bcc: 'orders@avigenicsusa.com',
        })
        .then((res) => {
          setSendingEmail('Email Sent')
        })
        .catch((err) => {
          alert('There was error')
        })
    } catch (error) {
      setSendingEmail('There was error')
    }
    setAttachments([])
  }

  const handleClick = (event) => {
    const newOffset = (event.selected * 20) % (items && items.length)
    setPageNumber(event.selected)
    setItemOffset(newOffset)
  }

  return (
    <>
      <Modal
        show={showA4PdfModal}
        onHide={() => setShowA4PdfModal(false)}
        size='lg'
      >
        <Modal.Header closeButton>
          <ReactPaginate
            activeClassName={'item active '}
            breakClassName={'item break-me '}
            breakLabel={'...'}
            containerClassName={'pagination'}
            disabledClassName={'disabled-page'}
            marginPagesDisplayed={2}
            nextClassName={'item next '}
            nextLabel={
              <Icon.ArrowRightCircle size={18} width={150} color='white' />
            }
            onPageChange={handleClick}
            pageCount={pageCount}
            pageClassName={'item pagination-page '}
            pageRangeDisplayed={2}
            previousClassName={'item previous'}
            previousLabel={
              <Icon.ArrowLeftCircle size={18} width={150} color='white' />
            }
          />
        </Modal.Header>
        <Modal.Body>
          <div
            id='pdfDiv2'
            style={{
              backgroundColor: '#ffffff',
              alignContent: 'center',
            }}
          >
            <Image src='/images/pbfdlogo.jpeg' />
            <hr />
            <Table borderless>
              <tbody>
                <tr>
                  <td
                    style={{
                      backgroundColor: '#ffffff',
                      alignContent: 'center',

                      fontFamily: 'Bigshot One',
                      fontSize: '14px',
                    }}
                  >
                    Submitted By: <br />
                    {selectedBatch && selectedBatch.customerName},
                    <br />
                    {selectedCustomer && selectedCustomer.address1} <br />
                    {selectedCustomer && selectedCustomer.address2} <br />
                    {selectedCustomer && selectedCustomer.city} <br />
                    {selectedCustomer && selectedCustomer.pincode}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ffffff',

                      fontFamily: 'Bigshot One',
                      fontSize: '14px',
                    }}
                  >
                    Test Performed: APV <br />
                    Methodology : Qualitative PCR <br />
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ffffff',

                      fontFamily: 'Bigshot One',
                      fontSize: '14px',
                    }}
                  >
                    Order No: {selectedBatch && selectedBatch.batchName}
                    <br />
                    Arrived:{' '}
                    {new Date(
                      selectedBatch && selectedBatch.arrDate
                    ).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })}
                    <br />
                    Result:{' '}
                    {new Date(date).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })}
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* <h6>Submitted By: {selectedBatch && selectedBatch.customerName}</h6>
            <h6>Batch ID: {selectedBatch && selectedBatch.batchName}</h6> */}
            <Table bordered>
              <thead>
                <tr
                  style={{
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    height: '14px',
                    //     padding: 20,
                  }}
                >
                  <th align='center'>Ring No</th>
                  <th align='center'>Species</th>
                  <th align='center'>Result</th>
                </tr>
              </thead>
              <tbody
                style={{
                  fontSize: 12,
                  textDecoration: '',
                }}
              >
                <Items currentItems={currentItems} />

                {[
                  ...Array(selectedBatch.samples && 20 - currentItems.length),
                ].map((e, i) => (
                  <tr key={i} style={{ height: '25px' }}>
                    <td style={{ padding: 0 }}></td>
                    <td style={{ padding: 0 }}></td>
                    <td style={{ padding: 0 }}></td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <br />
            <br />
            <span
              style={{
                fontSize: 14,
                textAlign: 'center',
                marginLeft: 80,
              }}
            >
              {' '}
              * Recommonded Clinical Corelation
            </span>
            <hr />
            <h6
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: 'rgba(0, 40, 161, 0.9)',
              }}
            >
              <strong>
                {' '}
                Avigenics Inc, 1562 Belvedere Ave, Okemos, MI 48864.
                <br />
                info@avigenicsusa.com ~ (313) 338-9022 ~
                https://avigenicsusa.com
              </strong>
            </h6>
            <br />
            <br />
          </div>

          <div
            style={{
              backgroundColor: '#ffffff',
              alignContent: 'center',
              textAlign: 'center',
              alignItems: 'space-between',
            }}
          >
            <Button
              variant='secondary'
              onClick={() => setShowA4PdfModal(false)}
            >
              Close
            </Button>
            {'  '}&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              disabled={buttonText === 'Saving Report ...'}
              variant='primary'
              onClick={() => {
                setButtonText('Saving Report ...')
                savePdf()
              }}
            >
              {buttonText}
            </Button>
            {'  '}&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant={
                sendingEmail === 'There was error' ? 'danger' : 'primary'
              }
              disabled={sendingEmail === 'Sending Email ...'}
              onClick={() => {
                setSendingEmail('Sending Email ...')
                sendEmail()
              }}
            >
              {sendingEmail}
            </Button>{' '}
            {'  '}&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant='success'
              onClick={() => {
                saveLocal()
              }}
            >
              Save Local
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Container fluid>
        <h1>APV</h1>
        <Table striped bordered hover>
          <thead>
            <tr
              style={{
                backgroundColor: 'pink',
                fontFamily: 'Bahnschrift',
                fontSize: '16px',
              }}
            >
              <th>Batch ID</th>
              <th>Customer</th>
              <th>Samples</th>
              <th>Items</th>
            </tr>
          </thead>
          <tbody>
            {APVList &&
              APVList.map((batch) => (
                <tr
                  key={batch.id}
                  style={{
                    backgroundColor: 'pink',
                    fontFamily: 'Bahnschrift',
                    fontSize: '14px',
                  }}
                >
                  <td>
                    <Nav.Link
                      onClick={() => {
                        setSelectedCustomer(getCustomer(batch.custId))
                        setSelectedBatch(batch)
                        setItems(batch.samples)
                        setShowModal(true)
                      }}
                    >
                      {batch.batchName}
                    </Nav.Link>
                  </td>
                  <td>{batch.customerName}</td>
                  <td> {batch.samples && batch.samples.length}</td>
                  <td>
                    {/* {batch.items.map((item, index) => (
                      <div key={index}>{item.type},</div>
                    ))} */}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
      <Modal
        show={showModal}
        onHide={() => {
          setAttachments([])
          setButtonText('Save Report')
          setSendingEmail('Send Email')
          setShowModal(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>APV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Ring No</th>
                <th>Species</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {selectedBatch.samples &&
                selectedBatch.samples
                  .filter((sample) => sample.testType.includes('APV'))
                  .map((sample, index) => (
                    <tr key={index}>
                      <td>{sample.ringNo}</td>
                      <td>{sample.selectedSpecies}</td>
                      <td>
                        <Form.Control
                          as='select'
                          defaultValue={sample.apvResult}
                          onChange={(e) => {
                            sample.apvResult = e.target.value
                          }}
                        >
                          <option value=''>Select</option>
                          <option value='Positive'>Positive</option>
                          <option value='Negative'>Negative</option>
                          <option value='Repeat'>Repeat</option>
                          <option value='Cancel'>Cancel</option>
                          <option value='Pending'>Pending</option>
                        </Form.Control>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setAttachments([])
              setButtonText('Save Report')
              setSendingEmail('Send Email')
              setShowModal(false)
            }}
          >
            Close
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              setShowModal(false)
              setShowA4PdfModal(true)
              updateOrder(selectedBatch, 'Admin')
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default APVReport

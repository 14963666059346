import { useEffect } from 'react'

const DNASexing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {/* <!-- Page Title --> */}
      <section className='page_breadcrumb'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <ol className='page_bread'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='current_page'>DNA Sexing</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/dna.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  <div className='title'>AVIGENICS</div>
                  <h2>Bird DNA Sexing</h2>
                </div>
                <div className='bold-text lh-lg'>
                  DNA sexing for birds can be performed with feather or blood
                  samples. We have access to the world's largest avian DNA
                  samples database with over 2000 different species of birds
                  from around the world. As with any DNA test, the quality of
                  the sample submitted for testing is critical. Thus, it is
                  advisable to submit wisely.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Three --> */}

      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-8 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <ul className='mission-list'>
                  <h6 className='text-dark'>
                    <b>
                      Bird DNA Sexing is an extremely advantageous method of sex
                      determination as it offers the following benefits:
                    </b>
                  </h6>
                  <br />
                  <li>
                    <span className='icon'>
                      <img src='images/icons/mission-1.png' alt='' />
                    </span>
                    No surgical intervention required
                  </li>
                  <li>
                    <span className='icon'>
                      <img src='images/icons/mission-2.png' alt='' />
                    </span>
                    Applicable universally to birds of any age at any time of
                    the year
                  </li>
                  <li>
                    <span className='icon'>
                      <img src='images/icons/mission-3.png' alt='' />
                    </span>
                    The birds are not subjected to stress of any sort
                  </li>
                  <li>
                    <span className='icon'>
                      <img src='images/icons/mission-1.png' alt='' />
                    </span>
                    Reliable, scientifically proven
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h1> Lead Time:</h1>
              <h2>
                Once the samples reach us, it takes 7 to 10 Business days for the
                results.
              </h2>
            </div>

            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-4 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/dna1.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='image-column col-lg-4 col-md-12 col-sm-12'>
          <div className='inner-column'>
            <div
              className='image wow fadeInLeft'
              data-wow-delay='0ms'
              data-wow-duration='1500ms'
            ></div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Three --></section> */}
    </div>
  )
}
export default DNASexing

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Home from './components/Home'
import Price from './components/Price'
import Header from './components/Header'
import Login from './components/Login'
import Register from './components/Register'
import MyAccount from './components/MyAccount'
import './App.css'
import Footer from './components/Footer'
import DNASexing from './components/DNASexing'
import ContactUs from './components/ContactUs'
import PBFD from './components/PBFD'
import APV from './components/APV'
import BloodSample from './components/BloodSample'
import FeatherSample from './components/FeatherSample'
import ShellSample from './components/ShellSample'
import TermsOfUse from './components/TermsOfUse'
import TermsConditions from './components/TermsConditions'
import Privacy from './components/Privacy'
import OrderNow from './components/OrderNow'
import Checkout from './components/Checkout'
import FAQ from './components/FAQ'
import AuthProvider from './config/AuthContext'
import OrderProvider from './config/OrderContext'
import GlobalProvider from './config/GlobalContext'
import AdminNew from './components/admin/AdminNew'
import AdminProvider from './config/AdminContext'
import HowTo from './components/HowTo'
import SampleCollection from './components/SampleCollection'

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <AuthProvider>
            {window.location.pathname !== '/Admin' && <Header />}
            <GlobalProvider>
              <OrderProvider>
                <Route path='/OrderNow' exact component={OrderNow} />
                <Route path='/Order' exact component={OrderNow} />
                <AdminProvider>
                  <Route path='/Admin' exact component={AdminNew} />
                </AdminProvider>
              </OrderProvider>
            </GlobalProvider>
            <Route path='/Login' exact component={Login} />
            <Route path='/Register' exact component={Register} />
            <Route path='/MyAccount' exact component={MyAccount} />
          </AuthProvider>
        </Switch>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/HowTo' exact component={HowTo} />
          <Route path='/Price' exact component={Price} />
          <Route path='/DNASexing' exact component={DNASexing} />
          <Route path='/ContactUs' exact component={ContactUs} />
          <Route path='/PBFD' exact component={PBFD} />
          <Route path='/APV' exact component={APV} />
          <Route path='/SampleCollection' exact component={SampleCollection} />
          <Route path='/BloodSample' exact component={BloodSample} />
          <Route path='/FeatherSample' exact component={FeatherSample} />
          <Route path='/ShellSample' exact component={ShellSample} />
          <Route path='/PlaceOrder' exact component={FAQ} />
          <Route path='/Privacy' exact component={Privacy} />
          <Route path='/TermsOfUse' exact component={TermsOfUse} />
          <Route path='/TermsConditions' exact component={TermsConditions} />
          <Route path='/Checkout' exact component={Checkout} />
          <Route path='/FAQ' exact component={FAQ} />
        </Switch>
        {window.location.pathname !== '/Admin' && <Footer />}
      </Router>
    </div>
  )
}

export default App

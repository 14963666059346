import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
import ComponentToShow from './ComponentToShow'

const Arrived = (props) => {
  const { findFiltered, updateArrived, allBatches } = useAdmin()
  const [labBatch, setLabBatch] = useState()

  useEffect(() => {
    findFiltered('Arrived')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBatches])

  return (
    <>
      {' '}
      <ComponentToShow
        prev={'Posted'}
        next={'In Lab'}
        disableButton={props.disableButton}
        setDisableButton={props.setDisableButton}
      />
      <div className='input-group w-100'>
        <Form.Control
          style={{ maxWidth: '200px' }}
          type='text'
          placeholder='Veta Batch ID'
          onChange={(e) => {
            setLabBatch(e.target.value)
          }}
        />
        <Button
          onClick={() => {
            updateArrived(labBatch)
            props.setDisableButton(false)
            alert('Batch ID is set!')
          }}
        >
          Set
        </Button>
      </div>
    </>
  )
}

export default Arrived

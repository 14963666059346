import {
  Card,
  Button,
  Form,
  Container,
  Alert,
  Modal,
  Nav,
} from 'react-bootstrap'
import { useRef, useState } from 'react'
import { useAuth } from '../config/AuthContext'
import { Link, useHistory } from 'react-router-dom'

const Login = (props) => {
  const [error, setError] = useState('')
  const emailRef = useRef()
  const passwordRef = useRef()
  const forgotRef = useRef()
  const { login, forgotPass } = useAuth()
  const [showModal, setShowModal] = useState(true)
  const history = useHistory()
  const [showForgot, setShowForgot] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setError('')
      await login(emailRef.current.value, passwordRef.current.value)
      props.page ? history.push(props.page) : history.replace('/Order')
    } catch (error) {
      console.log('Login Error: ' + error)
      setError(
        'Failed to Login. Plese check your email address and password or contact our support team'
      )
    }
  }
  const forgot = async (e) => {
    e.preventDefault()
    const resp = await forgotPass(forgotRef.current.value)
    resp === 'Success'
      ? setError('We have sent an email with password reset link')
      : setError(resp)

    setShowForgot(false)
    setShowModal(true)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          history.replace(props.page ? props.page : '/')
          setShowModal(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Container
          className='d-flex align-items-center justify-content-center'
          style={{ minHeight: '60vh' }}
        >
          <div className='w-100' style={{ maxWidth: '400px' }}>
            <Card>
              <Card.Body>
                <h2 className='text-center mb-4'>Login</h2>
                {error && (
                  <Alert
                    variant='danger'
                    dismissible
                    onClose={() => setError()}
                  >
                    {error}
                  </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group id='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type='email' required ref={emailRef} />
                  </Form.Group>
                  <Form.Group id='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      autoComplete={'on'}
                      type='password'
                      required
                      ref={passwordRef}
                    />
                  </Form.Group>
                  <Form.Text className='form-text'>
                    <h6>
                      <Nav.Link
                        onClick={() => {
                          setShowForgot(true)
                          setShowModal(false)
                        }}
                      >
                        Forgot password?
                      </Nav.Link>
                    </h6>
                  </Form.Text>
                  <Button className='w-100' type='submit'>
                    Log in
                  </Button>
                </Form>
              </Card.Body>{' '}
              <div className='w-100 text-center mt-2 '>
                You don't have an account?{' '}
                <Link to={'/Register'}> Register </Link> Here
                <br />
              </div>
            </Card>
          </div>
        </Container>
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showForgot}
        onHide={() => {
          setShowForgot(false)
          setShowModal(true)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={forgot}>
            <Form.Group controlId='formBasicEmail2'>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                ref={forgotRef}
                required
              />
              <Form.Text className='text-muted'>
                Please specify your email address.
              </Form.Text>
            </Form.Group>
            <Button
              variant='secondary'
              onClick={() => {
                setShowModal(true)
                setShowForgot(false)
                history.push('/Login')
              }}
            >
              Cancel
            </Button>{' '}
            <Button variant='primary' type='submit' value='Submit'>
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}

export default Login

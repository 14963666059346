const BloodSample = () => {
  return (
    <>
      {/* <!-- Page Title --> */}
      {/* <section className='page_breadcrumb'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <ol className='page_bread'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='current_page'>Blood Sample</li>
              </ol>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End Page Title --> */}

      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/blood.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  <div className='title'>AVIGENICS</div>
                  <h2>Blood Sample</h2>
                </div>
                <div className='bold-text'>
                  Wipe the bird's nail with an alcohol swab and let dry(5-10
                  seconds) and ensure that the toenail clipper is thoroughly
                  cleaned between birds. Clip the nail short enough to allow
                  bleeding (avoid clipping too high since this may be
                  uncomfortable for the bird and difficult to stop bleeding). If
                  blood is not present, gently squeeze the toe until blood
                  appears. Allow a small amount of blood to bleed up and
                  carefully touch the card/collection paper to the blood (only
                  one or two drops is required). Allow the card/paper to dry and
                  place it into the self-sealing bag. For detailed instructions
                  or further information write to{' '}
                  <a href='mailto:info@avigenicsusa.com'>
                    info@avigenicsusa.com
                  </a>{' '}
                  or call us on{' '}
                  <a href='tel:+13133389022'>+ 1 (313) 338-9022</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Three --> */}
    </>
  )
}

export default BloodSample

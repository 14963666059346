import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Alert, Button, Table } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
import { useGlobal } from '../../config/GlobalContext'
import { SMSTemplates } from '../SMSTemplates'
import ModifyOrder from '../ModifyOrder'
import NewOrder from '../NewOrder'
import Certificate from '../Certificate'
import Invoice from './Invoice'
import PhoneInput from 'react-phone-input-2'
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
} from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'

const AdminModals = (props) => {
  const {
    allCustomers,
    selectedCustomer,
    setSelectedCustomer,
    saveCustomer,
    selectedBatch,
    setSelectedBatch,
    updatePayStatus,
    getCustomer,
    updateBatch,
    updateURL,
    loading,
    setLoading,
    duplicateSamples,
    updateINVURL,
    showAllCerts,
    setShowAllCerts,
  } = useAdmin()
  const { calcTotal, forStripe } = useGlobal()
  const [message, setMessage] = useState()
  const [payNotes, setPayNotes] = useState('')
  const [payType, setPayType] = useState()
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [composeSMS, setComposeSMS] = useState()
  const [sms, setSms] = useState()
  const [selectedOrder, setSelectedOrder] = useState()
  const [showModifyOrder, setShowModifyOrder] = useState(false)
  const [showCert, setShowCert] = useState(false)
  const [certToShow, setCertToShow] = useState()
  const [sampleIndex, setSampleIndex] = useState()
  const [showEmail, setShowEmail] = useState(false)
  const [buttonText, setButtonText] = useState('Send Email')
  const [enableEdit, setEnableEdit] = useState(false)
  const [showHiddenColumns, setShowHiddenColumns] = useState(false)
  const [showSave, setShowSave] = useState(false)

  useEffect(() => {
    setSms(SMSTemplates)
    selectedBatch && getCustomer(selectedBatch.custId)
    setValue(selectedCustomer && selectedCustomer.address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  ///////// GEO LOCATION //////////
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
      /* Define search scope here */
    },
    debounce: 300,
  })

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value)
  }

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false)
      setSelectedCustomer({ ...selectedCustomer, address: value })
      // setCustomer({ ...customer, address: description })
      getGeocode({ address: description })
        // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
        // by setting the second parameter to "true"
        .then((results) => {
          const zipCode = getZipCode(results[0], false)
          setSelectedCustomer((selectedCustomer) => ({
            ...selectedCustomer,
            pincode: zipCode,
          }))
          setSelectedCustomer((selectedCustomer) => ({
            ...selectedCustomer,
            address: description,
          }))
        })
      clearSuggestions()

      // Get latitude and longitude via utility functions
    }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      )
    })

  ///////// GEO LOCATION //////////
  const submitHandler = async (e) => {
    e.preventDefault()
    if (e.target[2].value.length <= 0) {
      alert('No Text is Found')
    } else {
      props.setShowModal({ name: '', show: false })
      setSelectedTemplate()
      setComposeSMS({
        mobile: e.target[0].value,
        template: e.target[1].value,
        text: e.target[2].value,
      })
      await axios.post('/api/sendsms/', {
        mobile: e.target[0].value,
        text: e.target[2].value,
      })
    }
  }

  return (
    <>
      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-40w'
        show={props.showModal.name === 'Customer'}
        onHide={() => {
          setSelectedCustomer({})
          props.setShowModal({ name: '', show: false })
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedCustomer && selectedCustomer.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className='mb-3'>
            {message && (
              <Alert
                style={message.style}
                dismissible
                onClose={() => setMessage()}
              >
                {message.message}
              </Alert>
            )}{' '}
            <Form.Label column sm='4'>
              Name:
            </Form.Label>{' '}
            <Col sm='8'>
              <Form.Control
                type='text'
                required
                defaultValue={selectedCustomer && selectedCustomer.name}
                onChange={(e) => {
                  selectedCustomer.name = e.target.value.replace(
                    /\w\S*/g,
                    function (txt) {
                      return (
                        txt.charAt(0).toUpperCase() +
                        txt.substring(1).toLowerCase()
                      )
                    }
                  )
                }}
              />
            </Col>
          </Form.Group>{' '}
          <Form.Group as={Row} className='mb-3'>
            {' '}
            <Form.Label column sm='4'>
              Email:
            </Form.Label>{' '}
            <Col sm='8'>
              <Form.Control
                type='email'
                required
                defaultValue={selectedCustomer && selectedCustomer.email}
                onChange={async (e) => {
                  selectedCustomer.email = e.target.value
                  // setLoading(true)
                  // const dupMail = await allCustomers.filter(
                  //   (customer) => customer.email === selectedCustomer.email
                  // )
                  // if (dupMail.length > 0) {
                  //   setMessage({
                  //     message: 'E-Mail not available',
                  //     style: { backgroundColor: 'red' },
                  //   })
                  // } else {
                  //   setMessage({
                  //     message: 'E-Mail  available',
                  //     style: { backgroundColor: 'lightgreen' },
                  //   })
                  // }
                  // setLoading(false)
                }}
              />
              <Button
                onClick={async () => {
                  setLoading(true)
                  const dupMail = await allCustomers.filter(
                    (customer) => customer.email === selectedCustomer.email
                  )
                  if (dupMail.length > 0) {
                    setMessage({
                      message: 'E-Mail not available',
                      style: { backgroundColor: 'red' },
                    })
                  } else {
                    setMessage({
                      message: 'E-Mail  available',
                      style: { backgroundColor: 'lightgreen' },
                    })
                  }
                  setLoading(false)
                }}
              >
                {loading ? 'Checking...' : 'Check'}
              </Button>
            </Col>
          </Form.Group>{' '}
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column sm='4'>
              Phone:
            </Form.Label>{' '}
            <Col sm='8'>
              <PhoneInput
                controlId='formBasicPhone'
                country={'us'}
                //    defaultValue={selectedCustomer && selectedCustomer.phone}
                value={
                  selectedCustomer && selectedCustomer.phone
                    ? selectedCustomer.phone
                    : 0
                }
                placeholder='Mobile Phone'
                //
                onChange={(num) =>
                  selectedCustomer && (selectedCustomer.phone = num)
                }
              />
            </Col>
          </Form.Group>{' '}
          <Form.Group>
            <div ref={ref}>
              <Form.Control
                as='textarea'
                rows={5}
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder='Address'
              />
              {/* We can use the "status" to decide whether we should display the dropdown or not */}
              {status === 'OK' && <ul>{renderSuggestions()}</ul>}
            </div>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            {' '}
            <Form.Label column sm='4'>
              Zip Code:
            </Form.Label>{' '}
            <Col sm='8'>
              <Form.Control
                type='text'
                value={
                  selectedCustomer && selectedCustomer.pincode
                    ? selectedCustomer.pincode
                    : ''
                }
                onChange={(e) => {
                  setSelectedCustomer({
                    ...selectedCustomer,
                    pincode: e.target.value,
                  })
                }}
              />
            </Col>
          </Form.Group>{' '}
        </Modal.Body>{' '}
        <Form.Group as={Row} className='mb-3 ml-5'>
          <Button
            disabled={message}
            type='submit'
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              setMessage({
                message: 'Saving...!',
                style: { backgroundColor: 'lightseagreen' },
              })
              //    selectedCustomer.phone = phone
              //  console.log('Phone', phone)
              saveCustomer({ ...selectedCustomer, address: value })
              props.setShowModal({ name: '', show: false })
            }}
          >
            Save
          </Button>{' '}
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              props.setShowModal({ name: 'SMS', show: true })
            }}
          >
            Send SMS
          </Button>
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              alert('Do we really need this?')
            }}
          >
            Send Email
          </Button>{' '}
        </Form.Group>
      </Modal>
      <Modal
        width='90%'
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-60wh'
        show={props.showModal.name === 'Invoice Details'}
        onHide={() => {
          setSelectedBatch()
          props.setShowModal({ name: '', show: false })
        }}
      >
        <Modal.Header closeButton />
        <Invoice
          values={selectedBatch}
          onChange={(certURL) => {
            updateINVURL(selectedBatch, certURL)
            setShowCert(false)
          }}
        />
      </Modal>

      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-60wh'
        show={props.showModal.name === 'Update Payment'}
        onHide={() => {
          setSelectedBatch()
          props.setShowModal({ name: '', show: false })
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Form.Group controlId='mobile'>
            <Form.Label>Order ID</Form.Label>
            {selectedBatch && selectedBatch.batchName}
          </Form.Group>
          Price: ${selectedBatch && selectedBatch.orderCost}{' '}
          <Form.Group
            controlId='payType'
            onChange={(e) => setPayType(e.target.value)}
          >
            <Form.Label>Payment Type</Form.Label>
            <Form.Control
              as='select'
              defaultValue={''}
              style={{ maxWidth: '200px' }}
            >
              <option key={'0'} value={''}></option>
              <option key={'1'} value={'Stripe by Phone'}>
                Stripe by Phone
              </option>
              <option key={'2'} value={'Cheque'}>
                Cheque
              </option>
              <option key={'3'} value={'Cash'}>
                Cash
              </option>
              <option key={'4'} value={'Bank Transfer'}>
                Bank Transfer
              </option>
              <option key={'5'} value={'Credit'}>
                Credit
              </option>
              <option key={'6'} value={'Other'}>
                Other
              </option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='text'>
            <Form.Label>Notes if Any</Form.Label>
            <Form.Control
              as='textarea'
              rows={2}
              onChange={(e) => setPayNotes(e.target.value)}
            />
          </Form.Group>
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '200px',
            }}
            onClick={() => {
              updatePayStatus(selectedBatch.id, payNotes, payType)
              props.setShowModal({ name: '', show: false })
            }}
            type='Submit'
          >
            Update Payment
          </Button>
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              forStripe(selectedBatch)
              props.setShowModal({ name: 'Invoice Details', show: true })
            }}
          >
            Invoice
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-80w'
        show={props.showModal.name === 'Order Details'}
        onHide={() => {
          setSelectedCustomer()
          setSelectedBatch()
          props.setShowModal({ name: '', show: false })
        }}
      >
        {selectedBatch && (
          <Modal.Header closeButton>
            <Modal.Title>
              Order Details
              <br /> Order ID: {selectedBatch.batchName} Customer:{' '}
              {selectedBatch.customerName} Total: $
              {selectedBatch.orderCost
                ? selectedBatch.orderCost.toFixed(2)
                : calcTotal(
                    selectedBatch,
                    getCustomer(selectedBatch.custId)
                  ).toFixed(2)}{' '}
              &nbsp;&nbsp; Status: {selectedBatch.status} &nbsp;&nbsp;Payment:{' '}
              {selectedBatch.payStatus === 'Paid' ? (
                <span color='blue'>{selectedBatch.payStatus}</span>
              ) : (
                <span className='blink_me2' style={{ color: 'red' }}>
                  {selectedBatch.payStatus}
                </span>
              )}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <Row>
            <Col sm={4}>
              <Form.Check
                checked={enableEdit}
                type='checkbox'
                label='Enable Edit'
                onChange={(e) => {
                  setEnableEdit(e.target.checked)
                }}
              />
            </Col>
            <Col sm={4}>
              <Form.Check
                checked={showSave}
                type='checkbox'
                label='Show Save'
                onChange={(e) => {
                  setShowSave(e.target.checked)
                }}
              />
            </Col>
            <Col sm={4}>
              <Form.Check
                checked={showHiddenColumns}
                type='checkbox'
                label='Show Hidden Columns'
                onChange={(e) => {
                  setShowHiddenColumns(e.target.checked)
                }}
              />
            </Col>
          </Row>{' '}
          {duplicateSamples &&
            duplicateSamples.length > 0 &&
            duplicateSamples.map((sample, index) => (
              <div style={{ backgroundColor: 'red' }} key={index}>
                <span className='blink_me2'>
                  Duplicate Ring ID {sample.ringNo}
                </span>
                <br />
              </div>
            ))}
          {selectedBatch && (
            <Table responsive size='lg'>
              <thead>
                <tr sm={12}>
                  <th>#</th>
                  <th>Ring No</th>
                  <th>Species</th>
                  {selectedBatch.status !== 'Results Ready' &&
                    selectedBatch.status !== 'Completed' && (
                      <th>Sample Type</th>
                    )}
                  {showHiddenColumns && <th>Test Type</th>}

                  {(selectedBatch.status === 'Results Ready' ||
                    selectedBatch.status === 'Completed') && (
                    <>
                      <th>Results</th>
                      {showHiddenColumns && <th>Certificate</th>}
                      {showHiddenColumns && <th>Result Date</th>}
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {selectedBatch.samples
                  ? selectedBatch.samples.map((sample, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Form.Control
                            style={{ minWidth: '100px' }}
                            type='text'
                            disabled={!enableEdit}
                            required
                            defaultValue={selectedBatch.samples[index].ringNo}
                            onChange={(e) => {
                              selectedBatch.samples[index].ringNo =
                                e.target.value.toUpperCase()
                            }}
                          />
                        </td>
                        <td>
                          <Form.Control
                            style={{ minWidth: '150px' }}
                            type='text'
                            disabled={!enableEdit}
                            required
                            defaultValue={
                              selectedBatch.samples[index].selectedSpecies
                            }
                            onChange={(e) => {
                              selectedBatch.samples[index].selectedSpecies =
                                e.target.value.replace(
                                  /\w\S*/g,
                                  (txt) =>
                                    txt.charAt(0).toUpperCase() +
                                    txt.substring(1).toLowerCase()
                                )
                            }}
                          />
                        </td>
                        {selectedBatch.status !== 'Results Ready' &&
                          selectedBatch.status !== 'Completed' && (
                            <td>
                              <Form.Control
                                style={{ minWidth: '100px' }}
                                as='select'
                                defaultValue={
                                  selectedBatch.samples[index].sampleType
                                }
                                onChange={(e) => {
                                  selectedBatch.samples[index].sampleType =
                                    e.target.value
                                }}
                                placeholder='Select here'
                              >
                                <option key={'0'} value={''}></option>
                                <option key={'1'} value={'Feather'}>
                                  Feather
                                </option>
                                <option key={'2'} value={'Blood'}>
                                  Blood
                                </option>
                                <option key={'3'} value={'EGG SHELL'}>
                                  EGG SHELL
                                </option>
                                <option key={'4'} value={'Both'}>
                                  Both
                                </option>
                                <option key={'5'} value={'Not Received'}>
                                  Not Received
                                </option>
                                <option key={'6'} value={'Cancelled'}>
                                  Cancelled
                                </option>
                              </Form.Control>
                            </td>
                          )}
                        {showHiddenColumns && (
                          <td>
                            {
                              <Form.Control
                                readOnly={selectedBatch.status === 'Completed'}
                                as='select'
                                defaultValue={
                                  selectedBatch.samples[index].testType
                                }
                                onChange={(e) =>
                                  (selectedBatch.samples[index].testType =
                                    e.target.value)
                                }
                                placeholder='Select here'
                              >
                                <option key={'0'} value={''}></option>
                                <option key={'1'} value={'DNA'}>
                                  DNA
                                </option>
                                <option key={'2'} value={'DNA+PBFD'}>
                                  DNA+PBFD
                                </option>
                                <option key={'3'} value={'DNA+APV'}>
                                  DNA+APV
                                </option>
                                <option key={'4'} value={'DNA+PBFD+APV'}>
                                  DNA+PBFD+APV
                                </option>
                                <option key={'5'} value={'PBFD+APV'}>
                                  PBFD+APV
                                </option>
                                <option key={'6'} value={'RATITES'}>
                                  RATITES
                                </option>
                                <option key={'7'} value={'PBFD'}>
                                  PBFD
                                </option>
                                <option key={'8'} value={'APV'}>
                                  APV
                                </option>
                              </Form.Control>
                            }
                          </td>
                        )}
                        {(selectedBatch.status === 'Results Ready' ||
                          selectedBatch.status === 'Completed') && (
                          <>
                            <td>
                              <Form.Control
                                readOnly={selectedBatch.status === 'Completed'}
                                disabled={selectedBatch.status === 'Completed'}
                                as='select'
                                defaultValue={
                                  selectedBatch.samples[index].results
                                }
                                onChange={(e) =>
                                  (selectedBatch.samples[index].results =
                                    e.target.value)
                                }
                                placeholder='Select here'
                              >
                                <option key={'0'} value={''}></option>
                                <option key={'1'} value={'Male'}>
                                  Male
                                </option>
                                <option key={'2'} value={'Female'}>
                                  Female
                                </option>
                                <option key={'3'} value={'Repeat'}>
                                  Repeat
                                </option>
                                <option key={'4'} value={'Pending'}>
                                  Pending
                                </option>
                                <option key={'5'} value={'Not Received'}>
                                  Not Received
                                </option>
                                <option key={'6'} value={'Cancelled'}>
                                  Cancelled
                                </option>
                              </Form.Control>
                            </td>
                            {showHiddenColumns && (
                              <td>
                                <Form.Control
                                  readOnly={
                                    selectedBatch.status === 'Completed'
                                  }
                                  style={{ minWidth: '180px' }}
                                  type='text'
                                  key={index}
                                  defaultValue={sample.certificate}
                                  placeholder='Certificate'
                                  onChange={(e) => {
                                    selectedBatch.samples[index].certificate =
                                      e.target.value
                                    //     selectedBatch.samples[index].certURL = certURL
                                  }}
                                />
                              </td>
                            )}
                            {showHiddenColumns && (
                              <td>
                                <Form.Control
                                  readOnly={
                                    selectedBatch.status === 'Completed'
                                  }
                                  id='Resultdate'
                                  type='date'
                                  name='Resultdate'
                                  defaultValue={sample.Resultdate}
                                  onChange={(e) => {
                                    selectedBatch.samples[index].Resultdate =
                                      e.target.value
                                  }}
                                />
                              </td>
                            )}
                            {showSave && (
                              <td>
                                <Button
                                  style={{
                                    padding: '4px',
                                    borderRadius: '30px',
                                    margin: '3px',
                                    width: '100px',
                                  }}
                                  onClick={() => {
                                    setSampleIndex(index)
                                    setCertToShow({
                                      ...selectedBatch.samples[index],
                                    })
                                    setShowCert(true)
                                  }}
                                >
                                  {selectedBatch.status === 'Results Ready'
                                    ? 'Save'
                                    : 'View'}
                                </Button>
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          )}
          <Button
            disabled={loading}
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={async () => {
              setLoading(true)
              await updateBatch(selectedBatch.id)
              await props.setShowModal({ name: '', show: false })
              setLoading(false)
            }}
          >
            {loading ? 'Saving...' : 'Update'}
          </Button>{' '}
          {selectedBatch && selectedBatch.status === 'Results Ready' && (
            <Button
              style={{
                padding: '4px',
                borderRadius: '30px',
                margin: '3px',
                width: '120px',
                background: 'red',
              }}
              onClick={() => {
                setShowAllCerts(true)
              }}
            >
              Save All Certs
            </Button>
          )}
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              props.setShowModal({ name: 'SMS', show: true })
            }}
          >
            Send SMS
          </Button>{' '}
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              selectedBatch.status === 'Results Ready'
                ? setShowEmail(true)
                : alert('Do we really need this?')
            }}
          >
            Send EMAIL
          </Button>{' '}
          {/* <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={(certURL) => {
              certToShow.certURL = certURL
              updateURL(selectedBatch, certToShow, sampleIndex)
              setShowCert(false)
              alert(certToShow.certURL)
            }}
          >
            Save Certificates
          </Button>{' '} */}
          {selectedCustomer && selectedCustomer.phone && (
            <a
              target={['_blank']}
              href={`https://wa.me/${selectedCustomer.phone.replace(
                '0',
                '61'
              )}`}
            >
              WhatsApp
            </a>
          )}
          <Button
            // disabled={
            //   (selectedBatch && selectedBatch.status === 'In Lab') ||
            //   (selectedBatch && selectedBatch.status === 'Results Ready') ||
            //   (selectedBatch && selectedBatch.status === 'Completed') ||
            //   (selectedBatch && selectedBatch.payStatus === 'Paid')
            // }
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '120px',
            }}
            onClick={() => {
              setSelectedOrder(selectedBatch)
              setShowModifyOrder(true)
            }}
          >
            Modify Order{' '}
          </Button>{' '}
        </Modal.Body>
      </Modal>
      <Modal
        size='lg'
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-80w'
        show={props.showModal.name === 'PBFD Order Details'}
        onHide={() => {
          setSelectedCustomer()
          setSelectedBatch()
          props.setShowModal({ name: '', show: false })
        }}
      >
        {selectedBatch && (
          <Modal.Header closeButton>
            <Modal.Title>
              PBFD Order Details
              <br /> Order ID: {selectedBatch.batchName} Customer:{' '}
              {selectedBatch.customerName} Total: $
              {selectedBatch.orderCost
                ? selectedBatch.orderCost.toFixed(2)
                : calcTotal(
                    selectedBatch,
                    getCustomer(selectedBatch.custId)
                  ).toFixed(2)}{' '}
              &nbsp;&nbsp; Status: {selectedBatch.status} &nbsp;&nbsp;Payment:{' '}
              {selectedBatch.payStatus === 'Paid' ? (
                <span color='blue'>{selectedBatch.payStatus}</span>
              ) : (
                <span className='blink_me2' style={{ color: 'red' }}>
                  {selectedBatch.payStatus}
                </span>
              )}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {duplicateSamples &&
            duplicateSamples.length > 0 &&
            duplicateSamples.map((sample, index) => (
              <div style={{ backgroundColor: 'red' }} key={index}>
                <span className='blink_me2'>
                  Duplicate Ring ID {sample.ringNo}
                </span>
                <br />
              </div>
            ))}
          {selectedBatch && (
            <Table responsive size='lg'>
              <thead>
                <tr sm={12}>
                  <th>#</th>
                  <th>Ring No</th>
                  <th>Species</th>
                  {selectedBatch.status !== 'Results Ready' &&
                    selectedBatch.status !== 'Completed' && <th>Type</th>}

                  {(selectedBatch.status === 'Results Ready' ||
                    selectedBatch.status === 'Completed') && (
                    <>
                      <th>PBFD Result</th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {selectedBatch.samples
                  ? selectedBatch.samples.map((sample, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Form.Control
                            style={{ minWidth: '100px' }}
                            type='text'
                            required
                            defaultValue={selectedBatch.samples[index].ringNo}
                            onChange={(e) => {
                              selectedBatch.samples[index].ringNo =
                                e.target.value.toUpperCase()
                            }}
                          />
                        </td>
                        <td>
                          <Form.Control
                            style={{ minWidth: '150px' }}
                            type='text'
                            required
                            defaultValue={
                              selectedBatch.samples[index].selectedSpecies
                            }
                            onChange={(e) => {
                              selectedBatch.samples[index].selectedSpecies =
                                e.target.value.replace(
                                  /\w\S*/g,
                                  (txt) =>
                                    txt.charAt(0).toUpperCase() +
                                    txt.substring(1).toLowerCase()
                                )
                            }}
                          />
                        </td>

                        {(selectedBatch.status === 'Results Ready' ||
                          selectedBatch.status === 'Completed') && (
                          <>
                            <td>
                              <Form.Control
                                readOnly={selectedBatch.status === 'Completed'}
                                as='select'
                                defaultValue={
                                  selectedBatch.samples[index].pbfdresult
                                }
                                onChange={(e) =>
                                  (selectedBatch.samples[index].pbfdresult =
                                    e.target.value)
                                }
                                placeholder='Select here'
                              >
                                <option key={'0'} value={''}></option>
                                <option key={'1'} value={'Positive'}>
                                  Positive
                                </option>
                                <option key={'2'} value={'Negative'}>
                                  Negative
                                </option>
                                <option key={'3'} value={'Repeat'}>
                                  Repeat
                                </option>
                                <option key={'4'} value={'Pending'}>
                                  Pending
                                </option>
                                <option key={'5'} value={'Not Received'}>
                                  Not Received
                                </option>
                                <option key={'6'} value={'Cancelled'}>
                                  Cancelled
                                </option>
                              </Form.Control>
                            </td>

                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        )}
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          )}
          <Button
            disabled={loading}
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={async () => {
              setLoading(true)
              await updateBatch(selectedBatch.id)
              await props.setShowModal({ name: '', show: false })
              setLoading(false)
            }}
          >
            {loading ? 'Saving...' : 'Update'}
          </Button>
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              props.setShowModal({ name: 'SMS', show: true })
            }}
          >
            Send SMS
          </Button>{' '}
          {/* <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              selectedBatch.status === 'Results Ready'
                ? setShowEmail(true)
                : alert('Do we really need this?')
            }}
          >
            Send EMAIL
          </Button>{' '} */}
          {selectedCustomer && selectedCustomer.phone && (
            <a
              target={['_blank']}
              href={`https://wa.me/${selectedCustomer.phone.replace(
                '0',
                '61'
              )}`}
            >
              WhatsApp
            </a>
          )}
          <Button
            disabled={
              (selectedBatch && selectedBatch.status === 'In Lab') ||
              (selectedBatch && selectedBatch.status === 'Results Ready') ||
              (selectedBatch && selectedBatch.status === 'Completed') ||
              (selectedBatch && selectedBatch.payStatus === 'Paid')
            }
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '120px',
            }}
            onClick={() => {
              setSelectedOrder(selectedBatch)
              setShowModifyOrder(true)
            }}
          >
            Modify Order{' '}
          </Button>{' '}
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              //    getBatch(batch.id)
              //  forStripe(selectedBatch)
              setSelectedCustomer()
              // setSelectedBatch()
              props.setShowModal({
                name: 'PBFD Details',
                show: true,
              })
            }}
          >
            PBFD Certficate
          </Button>
          <Button
            disabled={buttonText !== 'Send Email'}
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={async () => {
              setButtonText('Sending....')
              let attachments = []
              // selectedBatch.samples.forEach((sample) => {
              attachments.push({
                filename: `${selectedBatch.batchName}.pdf`,
                path: selectedBatch.pbfdReport,
              })
              //      console.log(attachments)
              //  }

              const jsxHtml = selectedBatch
              const sasi = jsxHtml.toString().replaceAll('>,<', '><')
              try {
                await axios.post('/api/sendmail/', {
                  to: selectedBatch && selectedBatch.custId,
                  text: `Dear ${selectedBatch.customerName}, <br/><br/>
                  Good day! <br />
                  Hope you are doing well. <br/>
                  We are pleased to inform about the PBFD test results here.<br/><br/>
              <table border responsive size='lg'>
          <thead>
            <tr>
              <th width='200px'>Ring ID</th>
              <th width='200px'>Species</th>
              <th width='200px'>Result</th>
              <th width='200px'>Certificate</th>
            </tr>
          </thead><tbody>${sasi}</tbody>
        </table><br/>
 <br/>
 <strong>NOTE:</strong><br/><br/>

If the DNA Sexing result of a bird is  <strong>'REPEAT'</strong>, it means we could not extract DNA from the sample provided. Please send us fresh samples of the same bird for retesting. You don't need to create new order or don't need to pay for the REPEAT samples. Please post us fresh samples stating REPEAT and mark its Ring ID and its old order number.
<br/><br/>
If the DNA Sexing result of a bird is <strong>'PENDING'</strong>, it means testing of the sample is in progress and its result will be available soon. <br/>

<strong>We offer printed certificates. Post Card Size certificates at $1 each. Additionally, 
                        $3 for postage will be charged per order. Please reach out to us to place the order. If you have already opted for printed certificates while placing the order, you should receive them in 4-5 business days.</strong><br/><br/>
We hope you enjoyed our services.<br/>

We sincerely appreciate your business and hope you come back soon!<br/><br/><br/>
 <br />
           
                      <img
            style='width: 150px;' height: 80px;' }}
            src='https://avigenicsusa.com/images/logo-6.png'
          /><br/><br/>
         <p>E-mail:   info@avigenicsusa.com  |   Phone:   (313) 338-9022 <br/>
            1562 Belvedere Ave,Okemos, MI 48864, USA. <br/>
            Website:  www.avigenicsusa.com</p>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>          The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>`,
                  subject: `Order ID: ${
                    selectedBatch && selectedBatch.batchName
                  }`,
                  attachments,
                  bcc: 'orders@avigenicsusa.com',
                })
              } catch (error) {
                setMessage({
                  message:
                    'Error Sending email to customer. Please contact Sasi.',
                  style: { backgroundColor: 'red' },
                })
              }

              // setMessage({
              //   message: 'Email sent to customer!',
              //   style: { backgroundColor: 'lightseagreen' },
              // })
              // setButtonText('Send Email')
              // setShowEmail(false)
            }}
          >
            {buttonText}
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-80w'
        show={props.showModal.name === 'APV Order Details'}
        onHide={() => {
          setSelectedCustomer()
          setSelectedBatch()
          props.setShowModal({ name: '', show: false })
        }}
      >
        {selectedBatch && (
          <Modal.Header closeButton>
            <Modal.Title>
              APV Order Details
              <br /> Order ID: {selectedBatch.batchName} Customer:{' '}
              {selectedBatch.customerName} Total: $
              {selectedBatch.orderCost
                ? selectedBatch.orderCost.toFixed(2)
                : calcTotal(
                    selectedBatch,
                    getCustomer(selectedBatch.custId)
                  ).toFixed(2)}{' '}
              &nbsp;&nbsp; Status: {selectedBatch.status} &nbsp;&nbsp;Payment:{' '}
              {selectedBatch.payStatus === 'Paid' ? (
                <span color='blue'>{selectedBatch.payStatus}</span>
              ) : (
                <span className='blink_me2' style={{ color: 'red' }}>
                  {selectedBatch.payStatus}
                </span>
              )}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {duplicateSamples &&
            duplicateSamples.length > 0 &&
            duplicateSamples.map((sample, index) => (
              <div style={{ backgroundColor: 'red' }} key={index}>
                <span className='blink_me2'>
                  Duplicate Ring ID {sample.ringNo}
                </span>
                <br />
              </div>
            ))}
          {selectedBatch && (
            <Table responsive size='lg'>
              <thead>
                <tr sm={12}>
                  <th>#</th>
                  <th>Ring No</th>
                  <th>Species</th>
                  {selectedBatch.status !== 'Results Ready' &&
                    selectedBatch.status !== 'Completed' && <th>Type</th>}

                  {(selectedBatch.status === 'Results Ready' ||
                    selectedBatch.status === 'Completed') && (
                    <>
                      <th> APV Result</th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {selectedBatch.samples
                  ? selectedBatch.samples.map((sample, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Form.Control
                            style={{ minWidth: '100px' }}
                            type='text'
                            required
                            defaultValue={selectedBatch.samples[index].ringNo}
                            onChange={(e) => {
                              selectedBatch.samples[index].ringNo =
                                e.target.value.toUpperCase()
                            }}
                          />
                        </td>
                        <td>
                          <Form.Control
                            style={{ minWidth: '150px' }}
                            type='text'
                            required
                            defaultValue={
                              selectedBatch.samples[index].selectedSpecies
                            }
                            onChange={(e) => {
                              selectedBatch.samples[index].selectedSpecies =
                                e.target.value.replace(
                                  /\w\S*/g,
                                  (txt) =>
                                    txt.charAt(0).toUpperCase() +
                                    txt.substring(1).toLowerCase()
                                )
                            }}
                          />
                        </td>

                        {(selectedBatch.status === 'Results Ready' ||
                          selectedBatch.status === 'Completed') && (
                          <>
                            <td>
                              <Form.Control
                                readOnly={selectedBatch.status === 'Completed'}
                                as='select'
                                defaultValue={
                                  selectedBatch.samples[index].ApvResult
                                }
                                onChange={(e) =>
                                  (selectedBatch.samples[index].ApvResult =
                                    e.target.value)
                                }
                                placeholder='Select here'
                              >
                                <option key={'0'} value={''}></option>
                                <option key={'1'} value={'Positive'}>
                                  Positive
                                </option>
                                <option key={'2'} value={'Negative'}>
                                  Negative
                                </option>
                                <option key={'3'} value={'Repeat'}>
                                  Repeat
                                </option>
                                <option key={'4'} value={'Pending'}>
                                  Pending
                                </option>
                                <option key={'5'} value={'Not Received'}>
                                  Not Received
                                </option>
                                <option key={'6'} value={'Cancelled'}>
                                  Cancelled
                                </option>
                              </Form.Control>
                            </td>

                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        )}
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          )}
          <Button
            disabled={loading}
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={async () => {
              setLoading(true)
              await updateBatch(selectedBatch.id)
              await props.setShowModal({ name: '', show: false })
              setLoading(false)
            }}
          >
            {loading ? 'Saving...' : 'Update'}
          </Button>
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              props.setShowModal({ name: 'SMS', show: true })
            }}
          >
            Send SMS
          </Button>{' '}
          <Button
            disabled={buttonText !== 'Send Email'}
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={async () => {
              setButtonText('Sending....')
              let attachments = []
              // selectedBatch.samples.forEach((sample) => {
              attachments.push({
                filename: `${selectedBatch.batchName}.png`,
                path: selectedBatch.APVReport,
              })
              //  }

              const jsxHtml = selectedBatch

              const sasi = jsxHtml.toString().replaceAll('>,<', '><')
              try {
                await axios.post('/api/sendmail/', {
                  to: selectedBatch && selectedBatch.custId,
                  text: `Dear ${selectedBatch.customerName}, <br/><br/>
                  Good day! <br />
                  Hope you are doing well. <br/>
                  We are  pleased to inform about the APV test results here.<br/><br/>
              <table border responsive size='lg'>
          <thead>
            <tr>
              <th width='200px'>Ring ID</th>
              <th width='200px'>Species</th>
              <th width='200px'>Result</th>
              <th width='200px'>Certificate</th>
            </tr>
          </thead><tbody>${sasi}</tbody>
        </table><br/>
We offer printed certificates. Post card size for $1 each and $3 postage cost will be added per order. Please call office to place the order. Print outs will be sent by post.<br/><br/>
We hope you enjoyed our services.<br/>
We sincerely appreciate your business and hope you come back soon!<br/><br/><br/>
<p>Thanks & Regards,
            <br />
           
                      <img
            style='width: 150px;' height: 80px;' }}
            src='https://avigenics/images/logo-6.png'
          /><br/><p>E-mail:   info@avigenicsusa.com  |   Phone:   (313) 338-9022 <br/>
            1562 Belvedere Ave,Okemos, MI 48864, USA. <br/>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>  </p>`,
                  subject: `Order ID: ${
                    selectedBatch && selectedBatch.batchName
                  }`,
                  attachments,
                  bcc: 'orders@avigenicsusa.com',
                })
              } catch (error) {
                setMessage({
                  message:
                    'Error Sending email to customer. Please contact Sasi.',
                  style: { backgroundColor: 'red' },
                })
              }

              // setMessage({
              //   message: 'Email sent to customer!',
              //   style: { backgroundColor: 'lightseagreen' },
              // })
              // setButtonText('Send Email')
              // setShowEmail(false)
            }}
          >
            {buttonText}
          </Button>{' '}
          {selectedCustomer && selectedCustomer.phone && (
            <a
              target={['_blank']}
              href={`https://wa.me/${selectedCustomer.phone.replace(
                '0',
                '61'
              )}`}
            >
              WhatsApp
            </a>
          )}
          <Button
            disabled={
              (selectedBatch && selectedBatch.status === 'In Lab') ||
              (selectedBatch && selectedBatch.status === 'Results Ready') ||
              (selectedBatch && selectedBatch.status === 'Completed') ||
              (selectedBatch && selectedBatch.payStatus === 'Paid')
            }
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '120px',
            }}
            onClick={() => {
              setSelectedOrder(selectedBatch)
              //       console.log(selectedBatch)
              setShowModifyOrder(true)
            }}
          >
            Modify Order{' '}
          </Button>{' '}
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              //    getBatch(batch.id)
              //  forStripe(selectedBatch)
              setSelectedCustomer()
              // setSelectedBatch()
              props.setShowModal({
                name: 'APV Details',
                show: true,
              })
            }}
          >
            APV Certficate
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-60wh'
        show={props.showModal.name === 'SMS'}
        onHide={() => {
          props.setShowModal({ name: '', show: false })
          setSelectedTemplate()
        }}
      >
        <Modal.Header closeButton />
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='mobile'>
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type='text'
              defaultValue={selectedCustomer && selectedCustomer.phone}
              onChange={(e) =>
                setComposeSMS({ ...composeSMS, mobile: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group
            controlId='selectedSMS'
            onChange={(e) =>
              setComposeSMS({ ...composeSMS, template: e.target.value })
            }
          >
            <Form.Label>Select Template</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => {
                const a = sms.filter((templ) => templ.name === e.target.value)
                setSelectedTemplate(a)
              }}
            >
              {SMSTemplates &&
                SMSTemplates.map((template, index) => (
                  <option value={template.name} key={index}>
                    {template.name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='text'>
            <Form.Label>SMS Details</Form.Label>
            <Form.Control
              as='textarea'
              rows={5}
              defaultValue={selectedTemplate && selectedTemplate[0].text}
              onChange={(e) =>
                setComposeSMS({ ...composeSMS, text: e.target.value })
              }
            />
          </Form.Group>

          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            type='Submit'
          >
            Send SMS
          </Button>
        </Form>
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showModifyOrder}
        onHide={() => {
          setShowModifyOrder(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>{' '}
        </Modal.Header>
        <Modal.Body>
          <ModifyOrder
            order={selectedOrder}
            customer={selectedCustomer}
            custId={selectedCustomer && selectedCustomer.email}
            setShowModifyOrder={setShowModifyOrder}
            setShowModal={props.setShowModal}
            page={'Admin'}
          />
        </Modal.Body>
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        show={props.showModal.name === 'Modify Order'}
        onHide={() => {
          props.setShowModifyOrder(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>{' '}
        </Modal.Header>
        <Modal.Body>
          <ModifyOrder
            order={selectedOrder}
            customer={selectedCustomer}
            custId={selectedCustomer && selectedCustomer.email}
            setShowModifyOrder={setShowModifyOrder}
            page={'Admin'}
          />
        </Modal.Body>
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        show={props.showModal.name === 'New Order'}
        onHide={() => props.setShowModal({ name: '', show: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>{' '}
        </Modal.Header>
        <Modal.Body>
          <NewOrder
            customer={selectedCustomer}
            custId={selectedCustomer && selectedCustomer.email}
            setShowModal={props.setShowModal}
            page={'Admin'}
          />
        </Modal.Body>
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-60wh'
        show={showCert}
        onHide={() => setShowCert(false)}
      >
        <Modal.Header closeButton />
        <Certificate
          values={
            certToShow && {
              ...certToShow,
              page: 'Admin',
              orderNo: selectedBatch.batchName,
              status: selectedBatch.status,
              sampleIndex,
            }
          }
          onChange={(certURL) => {
            certToShow.certURL = certURL
            updateURL(selectedBatch, certToShow, sampleIndex)
            setShowCert(false)
          }}
        />
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-60wh'
        show={showAllCerts}
        onHide={() => setShowAllCerts(false)}
      >
        <Modal.Header closeButton />

        {selectedBatch &&
          selectedBatch.samples &&
          selectedBatch.samples.map((sample, index) => (
            <Certificate
              key={index}
              values={
                sample && {
                  ...sample,
                  owner: selectedBatch.customerName,
                  page: 'Admin',
                  orderNo: selectedBatch.batchName,
                  status: selectedBatch.status,
                  sampleIndex: index,
                }
              }
              onChange={(certURL) => {
                sample.certURL = certURL
                updateURL(selectedBatch, sample, index)
              }}
            />
          ))}
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-60wh'
        show={showEmail}
        onHide={() => {
          setShowEmail(false)
        }}
      >
        <Modal.Header closeButton />
        <Modal.Title>
          Order ID:
          {selectedBatch && selectedBatch.batchName}
        </Modal.Title>
        <Table responsive size='lg'>
          <thead>
            <tr>
              <th>Ring ID</th>
              <th>Species</th>
              <th>Result</th>
              <th>Certificate</th>
            </tr>
          </thead>
          {selectedBatch &&
            selectedBatch.samples.map((sample, index) => (
              <tbody key={index}>
                <tr>
                  <td>{sample.ringNo && sample.ringNo.toUpperCase()}</td>
                  <td>{sample.selectedSpecies}</td>
                  <td>{sample.results}</td>
                  <td>
                    <a href={sample.certURL}>{sample.certificate}</a>
                  </td>
                </tr>{' '}
              </tbody>
            ))}
        </Table>

        <Button
          disabled={buttonText !== 'Send Email'}
          style={{
            padding: '4px',
            borderRadius: '30px',
            margin: '3px',
            width: '100px',
          }}
          onClick={async () => {
            setButtonText('Sending....')
            let attachments = []
            selectedBatch.samples.forEach((sample) => {
              attachments.push({
                filename: `${selectedBatch.batchName}_${sample.ringNo}.png`,
                path: sample.certURL,
              })
            })
            const jsxHtml =
              selectedBatch &&
              selectedBatch.samples.map(
                (sample, idx) =>
                  `<tr key=${idx}>
                        <td>${sample.ringNo}</td>
                        <td>${sample.selectedSpecies}</td>
                        <td>${sample.results}</td>
                        <td>
                          ${sample.certificate}
                        </td>
                      </tr>`
              )
            const sasi = jsxHtml.toString().replaceAll('>,<', '><')
            try {
              await axios.post('/api/sendmail/', {
                to: selectedBatch && selectedBatch.custId,
                text: `Dear ${selectedBatch.customerName}, <br/><br/>
                Good day! <br />
                  Hope you are doing well. <br/>
                  We are  pleased to inform about the test results here.<br/><br/>
              <table border responsive size='lg'>
          <thead>
            <tr>
              <th width='200px'>Ring ID</th>
              <th width='200px'>Species</th>
              <th width='200px'>Result</th>
              <th width='200px'>Certificate</th>
            </tr>
          </thead><tbody>${sasi}</tbody>
        </table><br/>
        <br/>
 <strong>NOTE:</strong><br/><br/>

If the DNA Sexing result of a bird is  <strong>'REPEAT'</strong>, it means we could not extract DNA from the sample provided. Please send us fresh samples of the same bird for retesting. You don't need to create new order or don't need to pay for the REPEAT samples. Please post us fresh samples stating REPEAT and mark its Ring ID and its old order number.
<br/><br/>
If the DNA Sexing result of a bird is <strong>'PENDING'</strong>, it means testing of the sample is in progress and its result will be available soon. <br/>


We offer printed certificates. Post card size for $1 each and $3 postage cost will be added per order. Please call office to place the order. Print outs will be sent by post.<br/><br/>
We hope you enjoyed our services.<br/>
We sincerely appreciate your business and hope you come back soon!<br/><br/><br/>
 <br />
           
                      <img
            style='width: 150px;' height: 80px;' }}
            src='https://avigenicsusa.com/images/logo-6.png'
          /><br/><br/>
         <p>E-mail:   info@avigenicsusa.com  |   Phone:   (313) 338-9022 <br/>
            1562 Belvedere Ave,Okemos, MI 48864, USA. <br/>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p> </p>`,
                subject: `Order ID: ${
                  selectedBatch && selectedBatch.batchName
                }`,
                attachments,
                bcc: 'orders@avigenicsusa.com',
              })
            } catch (error) {
              setMessage({
                message:
                  'Error Sending email to customer. Please contact Sasi.',
                style: { backgroundColor: 'red' },
              })
            }

            setMessage({
              message: 'Email sent to customer!',
              style: { backgroundColor: 'lightseagreen' },
            })
            setButtonText('Send Email')
            setShowEmail(false)
          }}
        >
          {buttonText}
        </Button>
      </Modal>
    </>
  )
}

export default AdminModals

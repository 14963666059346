import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const Privacy = () => {
  const [showPrivacy, setShowPrivacy] = useState(true)
  const history = useHistory()

  return (
    <>
      <div>
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={showPrivacy}
          onHide={() => {
            setShowPrivacy(false)
            history.goBack()
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Privacy Policy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              We at Avigenics INC understand that your privacy is important to
              you and that you care about how your personal data is used and
              shared online. We respect and value the privacy of everyone who
              visits this website avigenics.eu ("our Site") and will only
              collect and use personal data in ways that are described here, and
              in a manner that is consistent with our obligations and your
              rights under the law. Please read this Privacy Policy carefully
              and ensure that you understand it. Your acceptance of our Privacy
              Policy is deemed to occur upon your first use of our Site. If you
              do not accept and agree with this Privacy Policy, you must stop
              using our Site immediately.
            </p>
            <Modal.Title>1. Definitions and Interpretation</Modal.Title>
            <p>
              In this Policy, the following terms shall have the following
              meanings: "Cookie" means a small text file placed on your computer
              or device by our Site when you visit certain parts of our Site
              and/or when you use certain features of our Site. Details of the
              Cookies used by our Site are set out in section 12, below;
              "Personal data" means any and all data that relates to an
              identifiable person who can be directly or indirectly identified
              from that data. In this case, it means personal data that you give
              to us via our Site. This definition shall, where applicable,
              incorporate the definitions provided in the General Data
              Protection Regulation 2016/679 ("GDPR"); and "We/Us/Our" means
              Avigenics INC a company whose registered address is 1562 BELVEDERE
              AVE, Okemos, MI 48864, USA.
            </p>
            <Modal.Title>2. What Does This Policy Cover?</Modal.Title>{' '}
            <p>
              This Privacy Policy applies only to your use of our Site. Our Site
              may contain links to other websites. Please note that we provide
              such links for your convenience and for informational purposes
              only. We do not endorse or approve any of the products, services
              or opinions on such other websites. We have no control over how
              your data is collected, stored or used by other websites and we
              advise you to check the privacy policies of any such websites
              before providing any data to them.
            </p>
            <Modal.Title>3. Your Rights</Modal.Title>{' '}
            <p>
              3.1 As a data subject, you have the following rights under the
              GDPR, which this Policy and our use of personal data have been
              designed to uphold: <br />
              3.1.1 The right to be informed about our collection and use of
              personal data;
              <br /> 3.1.2 The right of access to the personal data we hold
              about you (see section 12);
              <br /> 3.1.3 The right to rectification if any personal data we
              hold about you is inaccurate or incomplete (please contact us
              using the details in section 14); <br />
              3.1.4 The right to be forgotten - i.e. the right to ask us to
              delete any personal data we hold about you (we only hold your
              personal data for a limited time, as explained in section 6 but if
              you would like us to delete it sooner, please contact us using the
              details in section 14); <br />
              3.1.5 The right to restrict (i.e. prevent) the processing of your
              personal data; <br />
              3.1.6 The right to data portability (obtaining a copy of your
              personal data to re-use with another service or organisation);
              <br />
              3.1.7 The right to object to us using your personal data for
              particular purposes; and
              <br /> 3.1.8 Rights with respect to automated decision making and
              profiling. <br />
              3.2 If you have any cause for complaint about our use of your
              personal data, please contact us using the details provided in
              section 14 and we will do our best to solve the problem for you.
              If we are unable to help, you also have the right to lodge a
              complaint with the USA's supervisory authority, the Information
              Commissioner's Office. <br />
              3.3 For further information about your rights, please contact the
              Information Commissioner's Office or your local Citizens' Advice
              Bureau.
            </p>
            <Modal.Title>4. What Data Do We Collect?</Modal.Title>{' '}
            <p>
              Depending upon your use of our Site, we may collect some or all of
              the following personal and non-personal data (please also see
              section 12 on our use of Cookies and similar technologies): <br />
              4.1 name; <br />
              4.2 address;
              <br /> 4.3 contact information such as email addresses and
              telephone numbers; <br />
              4.4 delivery address, if you place an order with us; <br />
              4.5 demographic information such as post code, preferences, and
              interests; <br />
              4.6 IP address; <br />
              4.7 web browser type and version; and <br />
              4.8 operating system. Please note our Site may ask you for payment
              details, such as billing information or credit or debit card
              information, however all payments made via our Site will go
              through a third party payment processor such as PayPal and as
              such, we will have no access to this data. A separate contractual
              relationship will be created between you and the payment processor
              on their terms and conditions and we cannot be held liable for any
              errors, actions, omissions or incorrect charges that may be made
              by them.
            </p>
            <Modal.Title>5. How Do We Use Your Data?</Modal.Title>{' '}
            <p>
              5.1 All personal data is processed and stored securely, for no
              longer than is necessary in light of the reason(s) for which it
              was first collected. We will comply with our obligations and
              safeguard your rights under the GDPR at all times. For more
              details on security see section 7, below.
              <br />
              5.2 Our use of your personal data will always have a lawful basis,
              either because it is necessary for our performance of a contract
              with you (e.g. by placing an order with us), because you have
              consented to our use of your personal data (e.g. by subscribing to
              SMS and emails), or because it is in our legitimate interests.
              Specifically, we may use your data for the following purposes:
              <br />
              5.2.1 Providing and managing your access to our Site;
              <br />
              5.2.2 Personalising and tailoring your experience on our Site;
              <br />
              5.2.3 Supplying our goods and services to you (please note that we
              require your personal data in order to enter into a contract with
              you);
              <br />
              5.2.4 Replying to emails from you;
              <br />
              5.2.5 Supplying you with SMS and emails that you have opted into
              (you may unsubscribe using the link at the bottom of each email
              and SMS or you may opt-out at any time by emailing us directly);
              <br />
              5.2.6 Analysing your use of our Site to enable us to continually
              improve our Site and your user experience.
              <br />
              5.3 With your permission and/or where permitted by law, we may
              also use your data for marketing purposes which may include
              contacting you by email and SMS with information, news and offers
              on our services. We will not, however, send you any unsolicited
              marketing or spam and will take all reasonable steps to ensure
              that we fully protect your rights and comply with our obligations
              under GDPR and the Privacy and Electronic Communications (EC
              Directive) Regulations 2003.
              <br />
              5.4 Third parties whose content appears on our Site may use third
              party Cookies, as detailed below in section 12. Please refer to
              section 12 for more information on controlling Cookies. Please
              note that we do not control the activities of such third parties,
              nor the data they collect and use, and we advise you to check the
              privacy policies of any such third parties.
              <br />
              5.5 You have the right to withdraw your consent to us using your
              personal data at any time, and to request that we delete it.
              <br />
              5.6 We do not keep your personal data for any longer than is
              necessary in light of the reason(s) for which it was first
              collected.
            </p>
            <Modal.Title>6. How Do We Store Your Data?</Modal.Title>{' '}
            <p>
              6.1 We only keep your personal data for as long as we need to in
              order to use it as described above in section 5, and/or for as
              long as we have your permission to keep it.
              <br />
              6.2 Data security is very important to us, and to protect your
              data we have taken suitable measures to safeguard and secure data
              collected through our Site.
            </p>
            <Modal.Title>7. Do We Share Your Data?</Modal.Title>{' '}
            <p>
              7.1 We may sometimes contract with other third parties to supply
              goods and services to you on our behalf. These may include,
              payment processors, search engine facilities and sub-contractors.
              In some cases, these third parties may require access to some or
              all of your data. Where any of your data is required for such a
              purpose, we will take all reasonable steps to ensure that your
              data will be handled safely, securely, and in accordance with your
              rights, our obligations, and the obligations of the third party
              under the law.
              <br />
              7.2 We may compile statistics about the use of our Site including
              data on traffic, usage patterns, user numbers, sales, and other
              information. All such data will be anonymised and will not include
              any personally identifying data, or any anonymised data that can
              be combined with other data and used to identify you. We may from
              time to time share such data with third parties such as
              prospective investors, affiliates, partners, and advertisers. Data
              will only be shared and used within the bounds of the law.
              <br />
              7.3 We may sometimes use third party data processors that are
              located outside of the European Economic Area ("the EEA") (The EEA
              consists of all EU member states, plus Norway, Iceland, and
              Liechtenstein). Where we transfer any personal data outside the
              EEA, we will take all reasonable steps to ensure that your data is
              treated as safely and securely as it would be within the USA and
              under the GDPR.
              <br />
              7.4 In certain circumstances, we may be legally required to share
              certain data held by us, which may include your personal data, for
              example, where we are involved in legal proceedings, where we are
              complying with legal requirements, a court order, or a
              governmental authority.
            </p>
            <Modal.Title>
              8. What Happens If Our Business Changes Hands?
            </Modal.Title>
            <p>
              8.1 We may, from time to time, expand or reduce our business and
              this may involve the sale and/or the transfer of control of all or
              part of our business. Any personal data that you have provided
              will, where it is relevant to any part of our business that is
              being transferred, be transferred along with that part and the new
              owner or newly controlling party will, under the terms of this
              Privacy Policy, be permitted to use that data only for the same
              purposes for which it was originally collected by us.
              <br />
              8.2 In the event that any of your data is to be transferred in
              such a manner, you will not be contacted in advance and informed
              of the changes.
            </p>
            <Modal.Title>9. How Can You Control Your Data?</Modal.Title>
            <p>
              9.1 In addition to your rights under the GDPR, set out in section
              4, when you submit personal data to us via email or via our Site,
              you may be given options to restrict our use of your data. In
              particular, we aim to give you strong controls on our use of your
              data for direct marketing purposes (including the ability to
              opt-out of receiving SMS and emails from us which you may do by
              unsubscribing using the links provided in our SMS and emails, at
              the point of providing your details and by contacting us).
              <br />
              9.2 You may also wish to sign up to one or more of the preference
              services operating in the USA: The Telephone Preference Service
              ("the TPS"), the Corporate Telephone Preference Service ("the
              CTPS"), and the Mailing Preference Service ("the MPS"). These may
              help to prevent you receiving unsolicited marketing. Please note,
              however, that these services will not prevent you from receiving
              marketing communications that you have consented to receiving.
            </p>
            <Modal.Title>10. Your Right to Withhold Information</Modal.Title>
            <p>
              10.1 You may access certain areas of our Site without providing
              any data at all. However, to use all features and functions
              available on our Site you may be required to submit or allow for
              the collection of certain data.
              <br />
              10.2 You may restrict our use of Cookies. You can choose to enable
              or disable Cookies in your internet browser. Most internet
              browsers also enable you to choose whether you wish to disable all
              cookies or only third-party Cookies. By default, most internet
              browsers accept Cookies but this can be changed. For further
              details, please consult the help menu in your internet browser or
              the documentation that came with your device. You can choose to
              delete Cookies on your computer or device at any time, however you
              may lose any information that enables you to access our Site more
              quickly and efficiently.
            </p>
            <Modal.Title>11. How Can You Access Your Data?</Modal.Title>
            <p>
              You have the right to ask for a copy of any of your personal data
              held by us (where such data is held). Please contact us for more
              details using the contact details below in section 12.
            </p>
            <Modal.Title>12. Our Use of Cookies</Modal.Title>
            <p>
              12.1 Our Site may place and access certain first party Cookies on
              your computer or device. First party Cookies are those placed
              directly by us and are used only by us. We use Cookies to
              facilitate and improve your experience of our Site and to provide
              and improve our services. All Cookies used by and on our Site are
              used in accordance with current Cookie Law.
              <br />
              12.2 Before Cookies are placed on your computer or device, you
              will be shown a pop-up requesting your consent to set those
              Cookies. By giving your consent to the placing of Cookies, you are
              enabling us to provide the best possible experience and service to
              you. You may, if you wish, deny consent to the placing of Cookies;
              however certain features of our Site may not function fully or as
              intended.
              <br />
              12.3 Certain features of our Site depend on Cookies to function.
              Cookie Law deems these Cookies to be "strictly necessary". Your
              consent will not be sought to place these Cookies, but it is still
              important that you are aware of them. You may still block these
              Cookies by changing your internet browser's settings as detailed
              below, but please be aware that our Site may not work properly if
              you do so. We have taken great care to ensure that your privacy is
              not at risk by allowing them.
              <br />
              12.4 Our Site uses Google Analytics. Website analytics refers to a
              set of tools used to collect and analyse anonymous usage
              information, enabling us to better understand how our Site is
              used. This, in turn, enables us to improve our Site and the
              services offered through it. You do not have to allow us to use
              these Cookies, however whilst our use of them does not pose any
              risk to your privacy or your safe use of our Site, it does enable
              us to continually improve our Site, making it a better and more
              useful experience for you. Google's privacy policy is available
              at: https://www.google.com/policies/privacy/
              <br />
              12.5 In addition to the controls that we provide, you can choose
              to enable or disable Cookies in your internet browser. By default,
              most internet browsers accept Cookies but this can be changed. For
              further details, please consult the help menu in your internet
              browser or the documentation that came with your device.
              <br />
              12.6 You can choose to delete Cookies on your computer or device
              at any time, however you may lose any information that enables you
              to access our Site more quickly and efficiently.
              <br />
              12.7 It is recommended that you keep your internet browser and
              operating system up-to-date and that you consult the help and
              guidance provided by the developer of your internet browser and
              manufacturer of your computer or device if you are unsure about
              adjusting your privacy settings.
            </p>
            <Modal.Title>13. Contacting Us</Modal.Title>
            <p>
              If you have any questions about our Site or this Privacy Policy,
              please contact us by email at info@avigenicsusa.com or by
              telephone on (313) 338-9022 or by post at 1562 Belvedere Ave,
              Okemos, MI 48864. Please ensure that your query is clear,
              particularly if it is a request for information about the data we
              hold about you (as under section 11, above).
            </p>
            <Modal.Title>14. Changes to Our Privacy Policy</Modal.Title>
            <p>
              We may change this Privacy Policy from time to time (for example,
              if the law changes). Any changes will be immediately posted on our
              Site and you will be deemed to have accepted the terms of the
              Privacy Policy on your first use of our Site following the
              alterations. We recommend that you check this page regularly to
              keep up-to-date.
            </p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>{' '}
      </div>
    </>
  )
}

export default Privacy

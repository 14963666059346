const OrderSummary = (props) => {
  const batch = props.batch

  return (
    <div align='left'>
      Order Summary: Total Samples:{' '}
      {batch && batch.samples && batch.samples.length} and{' '}
      {batch && batch.kitsNeeded && batch.kitsNeeded.type && 'Sample Kits, '}
      {batch &&
        batch.certsNeeded &&
        batch.certsNeeded.type &&
        batch.certsNeeded.type}{' '}
      Total Cost: ${batch && batch.orderCost ? batch.orderCost : 'Some Error'}
      <br />
      {batch && batch.kitsSentOn && 'Sample Kits Posted by us on: '}{' '}
      {batch &&
        batch.kitsSentOn &&
        new Date(batch.kitsSentOn).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })}
      {batch && batch.arrDate && 'Your samples arrived to us on: '}{' '}
      {batch &&
        batch.arrDate &&
        new Date(batch.arrDate).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })}
      {batch && batch.certsSentOn && 'Certificates Posted by us on: '}{' '}
      {batch &&
        batch.certsSentOn &&
        new Date(batch.kitsSentOn).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })}
    </div>
  )
}

export default OrderSummary

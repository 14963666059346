const Footer = () => {
  return (
    <>
      {' '}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <footer className='main-footer style-three'>
        <div
          className='pattern-layer'
          style={{
            backgroundImage: 'url(../images/background/pattern-3.png)',
          }}
        ></div>
        <div
          className='pattern-layer-two'
          style={{
            backgroundImage: 'url(../images/background/pattern-4.png)',
          }}
        ></div>
        <div
          className='pattern-layer-three'
          style={{
            backgroundImage: 'url(../images/background/pattern-5.png)',
          }}
        ></div>
        <div className='auto-container'>
          {/* <!--Widgets Section--> */}
          <div className='widgets-section'>
            <div className='row clearfix'>
              {/* <!-- Column --> */}
              <div className='big-column col-lg-6 col-md-12 col-sm-12'>
                <div className='row clearfix'>
                  {/* <!-- Footer Column --> */}
                  <div className='footer-column col-lg-7 col-md-6 col-sm-12'>
                    <div className='footer-widget logo-widget'>
                      <div className='logo'>
                        <a href='/'>
                          <img src='../images/avi-logo-white.png' alt='' />
                        </a>
                      </div>
                      <div className='text'>
                        {' '}
                        Avigenics is a leading organization committed to
                        offering affordable, reliable, DNA testing services for
                        wide range of birds.
                      </div>
                      {/* <!-- Social Box --> */}
                      {/* <ul className='social-box'>
                        <li>
                          <a
                            href='https://www.facebook.com/Avigenics-108548751370316/'
                            className='fa fa-facebook-f'
                          >
                            {''}
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://avigenicsusa.com'
                            className='fa fa-linkedin'
                          >
                            {''}
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://avigenicsusa.com'
                            className='fa fa-twitter'
                          >
                            {''}
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://avigenicsusa.com'
                            className='fa fa-instagram'
                          >
                            {''}
                          </a>
                        </li>
                        <li>
                          <div
                            className='trustpilot-widget'
                            data-locale='en-US'
                            data-template-id='56278e9abfbbba0bdcd568bc'
                            data-businessunit-id='60ec94ae2326ce000102863a'
                            data-style-height='52px'
                            data-style-width='100%'
                          >
                            <a
                              href='https://uk.trustpilot.com/review/avigenicsusa.com'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Trustpilot
                            </a>
                          </div>
                        </li>
                      </ul> */}
                    </div>
                  </div>

                  {/* <!-- Footer Column --> */}
                  <div className='footer-column col-lg-5 col-md-6 col-sm-12'>
                    <div className='footer-widget links-widget'>
                      <h4>Services</h4>
                      <ul className='list-link'>
                        <li>
                          <a href='/DNASexing'>DNA Sexing</a>
                        </li>
                        <li>
                          <a href='/PBFD'>PBFD</a>
                        </li>
                        <li>
                          <a href='/APV'>APV</a>
                        </li>
                        {/* <li>
                          <a href='/BloodSample'>Blood Samples</a>
                        </li>
                        <li>
                          <a href='/FeatherSample'>Feather Samples</a>
                        </li>
                        <li>
                          <a href='/ShellSample'>Egg Shell Samples</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Column --> */}
              <div className='big-column col-lg-6 col-md-12 col-sm-12'>
                <div className='row clearfix'>
                  {/* <!--Footer Column--> */}
                  <div className='footer-column col-lg-5 col-md-6 col-sm-12'>
                    <div className='footer-widget links-widget'>
                      <h4>Contact info</h4>
                      <ul className='list-style-two'>
                        <li>
                          <span className='icon flaticon-phone-call'></span>
                          <a href='tel:+13133389022'>+ 1 (313) 338-9022</a>
                        </li>{' '}
                        <li>
                          <span className='icon fa fa-whatsapp'></span>
                          <a href='https://wa.me/15175991517'>
                            +1 (517)599-1517{' '}
                          </a>
                        </li>
                        <li>
                          <span className='icon flaticon-email'></span>
                          <a href='mailto:info@avigenicsusa.com'>
                            info@avigenicsusa.com
                          </a>
                        </li>
                        <li>
                          <span className='icon flaticon-maps-and-flags'></span>
                          1562 Belvedere Ave, <br />
                          Okemos, MI 48864 <br />U S A.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- Footer Column --> */}
                  <div className='footer-column col-lg-5 col-md-6 col-sm-12'>
                    <div className='footer-widget links-widget'>
                      <h4>Terms of Use</h4>
                      <ul className='list-style-two'>
                        <li>
                          <span className='icon flaticon-safe'></span>
                          <a href='/Privacy'>Privacy Policy</a>
                        </li>
                        <li>
                          <span className='icon flaticon-check-1'></span>
                          <a href='/TermsOfUse'>Terms of Use</a>
                        </li>
                        <li>
                          <span className='icon flaticon-notepad'></span>
                          <a href='/TermsConditions'>Terms &amp; Conditions</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Footer Bottom --> */}
          <div className='footer-bottom'>
            <div className='copyright'>
              Copyright © 2020 Avigenics INC.
              {/* <br /> Powered by{' '}
              <span className='fa fa-cc-stripe'>{''}</span> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer

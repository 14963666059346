// External Imports
import { useState } from 'react'
import { Accordion, Card, Button, ListGroup, Modal, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useGlobal } from '../config/GlobalContext'

//Context Imports
import { useOrder } from '../config/OrderContext'

//Component Imports
import Certificate from './Certificate'
import OrderSummary from './OrderSummary'
import StripePay from './StripePay'

const CompletedOrders = () => {
  const [selectedOrder, setSelectedOrder] = useState()
  const [showCert, setShowCert] = useState(false)
  const [sampleIndex, setSampleIndex] = useState()
  const [certToShow, setCertToShow] = useState()
  const { allBatches, callStripe, currentUser, setCallStripe, payDetails } =
    useOrder()
  const { forStripe } = useGlobal()

  const allOrders = allBatches
  const orderToShow =
    allOrders && allOrders.filter((batch) => batch.status === 'Completed')

  const orderToShowHandler = (id) => {
    setSelectedOrder(orderToShow.filter((batch) => batch.id === id)[0])
  }

  return (
    <>
      <div className='auto-container'>
        <div className='row clearfix'>
          <div className='content-column col-lg-6 col-md-12 col-sm-12'>
            <div className='inner-column'>
              <div className='sec-title'>
                <div className='title'>Completed Orders</div>
              </div>
              <Accordion defaultActiveKey='0'>
                {orderToShow && orderToShow.length > 0 ? (
                  orderToShow.map((batch, index) => (
                    <Card key={index}>
                      <Card.Header style={{ padding: '0px' }}>
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          onClick={() => orderToShowHandler(batch.id)}
                          eventKey={`'${index}'`}
                        >
                          {batch.batchName}
                        </Accordion.Toggle>
                        {batch.status}
                        {' ~ '}
                        {batch.payStatus === 'Not Paid' ? (
                          <>
                            {'  '} Order Not Paid {'  '}
                            <Button
                              style={{
                                padding: '4px',
                                borderRadius: '30px',
                                margin: '3px',
                                width: '100px',
                              }}
                              onClick={() => {
                                forStripe(batch)
                                setCallStripe(true)
                              }}
                            >
                              Pay Now{' '}
                            </Button>
                          </>
                        ) : (
                          '  Order Paid'
                        )}
                      </Card.Header>
                      <Accordion.Collapse eventKey={`'${index}'`}>
                        <Card.Body>
                          {' '}
                          <ListGroup size='sm'>
                            {selectedOrder &&
                            selectedOrder.payStatus === 'Paid' ? (
                              selectedOrder.samples.map((sample, index) => (
                                <ListGroup.Item key={index}>
                                  {index + 1} &nbsp;&nbsp;&nbsp;{' '}
                                  <Nav.Item
                                    as={Link}
                                    to={'##cert##'}
                                    onClick={() => {
                                      setCertToShow(sample)
                                      setSampleIndex(index)
                                      setShowCert(true)
                                    }}
                                  >
                                    {sample.ringNo}
                                  </Nav.Item>
                                  &nbsp;&nbsp;&nbsp; {sample.selectedSpecies}
                                  &nbsp;&nbsp;&nbsp; {sample.results}
                                  &nbsp;&nbsp;&nbsp; {sample.certificate}
                                </ListGroup.Item>
                              ))
                            ) : (
                              <h6>Please pay to see</h6>
                            )}
                          </ListGroup>
                          <OrderSummary batch={batch} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))
                ) : (
                  <div style={{ color: 'red' }}>No Orders to show</div>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id={'cert'}
        dialogClassName='modal-60wh'
        show={showCert}
        onHide={() => setShowCert(false)}
      >
        <Modal.Header closeButton />
        <Certificate
          values={
            certToShow && {
              ...certToShow,
              owner: selectedOrder.ownerName
                ? selectedOrder.ownerName
                : selectedOrder.customerName,
              orderNo: selectedOrder.batchName,
              page: 'Order',
              sampleIndex,
            }
          }
        />
      </Modal>
      {callStripe && <StripePay payDetails={payDetails} />}
    </>
  )
}

export default CompletedOrders

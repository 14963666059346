import * as htmlToImage from 'html-to-image'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import { useEffect } from 'react'
import { useAdmin } from '../config/AdminContext'

const Certificate = (props) => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
  }

  const { loading } = useAdmin()

  let fbConfig
  if (!firebase.apps.length) {
    fbConfig = firebase.initializeApp(firebaseConfig)
  } else {
    fbConfig = firebase.app() // if already initialized, use that one
  }
  useEffect(() => {
    uploadCert()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.ringNo])

  let file = ''
  const uploadCert = async () => {
    htmlToImage
      .toBlob(
        document.getElementById(
          `CERTOrig${props.values && props.values.sampleIndex}`
        )
      )
      .then(async function (blob) {
        file = blob
        const storageRef = fbConfig.storage().ref()
        const fileRef = storageRef.child(
          `certs/${props.values.owner}/${props.values.orderNo}/${
            props.values.orderNo
          }_${props.values.sampleIndex + 1}_${props.values.ringNo}.png`
        )
        props.values.page !== 'Order' && (await fileRef.put(file))
        const imgURL =
          props.values.page !== 'Order' && (await fileRef.getDownloadURL())
        props.values.page === 'Admin' &&
          props.values.status === 'Results Ready' &&
          props.onChange(imgURL)
      })
  }

  const certSaveAs = () => {
    window.saveAs(
      file,
      `${props.values.orderNo}_${props.values.sampleIndex + 1}_${
        props.values.ringNo
      }.png`
    )
  }
  return (
    <>
      {loading && <div className='loader'>Loading...</div>}
      <div id={`CERTOrig${props.values && props.values.sampleIndex}`}>
        <div
          style={{
            backgroundImage:
              props && props.values && props.values.results === 'Female'
                ? 'url(/images/FEMALEA5.png)'
                : props &&
                  props.values &&
                  props.values.results === 'Male' &&
                  'url(/images/MALEA5.png)',
            backgroundSize: '600px 425px',
            minHeight: '425px',
            overflow: 'hidden',
          }}
        >
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {props && props.values && props.values.results === 'Pending' ? (
            <div
              style={{
                marginLeft: '100px',
                fontFamily: 'Bahnschrift',
                fontWeight: 'bold',
              }}
            >
              <h3>
                Ring ID: {props && props.values && props.values.ringNo}
                <br />
                Still being processed in lab. <br />
                Results will be available soon
              </h3>
            </div>
          ) : props && props.values && props.values.results === 'Repeat' ? (
            <div
              style={{
                marginLeft: '100px',
                fontFamily: 'Bahnschrift',
                fontWeight: 'bold',
              }}
            >
              {' '}
              <h3>
                Ring ID: {props && props.values && props.values.ringNo}
                <br />
                We could not extract DNA for this sample. <br />
                Please send a fresh sample.
              </h3>
            </div>
          ) : props && props.values && props.values.results === 'Cancel' ? (
            <div
              style={{
                marginLeft: '100px',
                fontFamily: 'Bahnschrift',
                fontWeight: 'bold',
              }}
            >
              {' '}
              <h3>
                Ring ID: {props && props.values && props.values.ringNo}
                <br />
                We did not received this sample or <br />
                You have asked us to cancel.
              </h3>
            </div>
          ) : (
            <table
              style={{
                marginLeft: '100px',
                fontFamily: 'Bahnschrift',
                fontWeight: 'bold',
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      color: '#0028a1',
                      paddingLeft: '20px',
                    }}
                  >
                    Ring ID
                  </td>
                  <td
                    style={{
                      marginLeft: '10px',
                      paddingLeft: '20px',
                      minWidth: '300px',
                    }}
                  >
                    {'  '}&nbsp;&nbsp;
                    {props && props.values && props.values.ringNo}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: '#0028a1',
                      paddingLeft: '20px',
                    }}
                  >
                    Species
                  </td>
                  <td
                    style={{
                      marginLeft: '10px',
                      paddingLeft: '20px',
                      minWidth: '300px',
                    }}
                  >
                    {'  '}&nbsp;&nbsp;
                    {props && props.values && props.values.selectedSpecies}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: '#0028a1',
                      paddingLeft: '20px',
                    }}
                  >
                    Owner
                  </td>
                  <td style={{ marginLeft: '10px', paddingLeft: '20px' }}>
                    {'  '}&nbsp;&nbsp;
                    {props && props.values && props.values.ownerName}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: '#0028a1',
                      paddingLeft: '20px',
                    }}
                  >
                    Date
                  </td>
                  <td style={{ marginLeft: '10px', paddingLeft: '20px' }}>
                    {'  '}&nbsp;&nbsp;
                    {new Date(
                      props && props.values && props.values.Resultdate
                    ).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: '#0028a1',
                      paddingLeft: '20px',
                    }}
                  >
                    CertificateID
                  </td>
                  <td
                    style={{
                      marginLeft: '10px',
                      paddingLeft: '20px',
                      minWidth: '200px',
                    }}
                  >
                    {'  '}&nbsp;&nbsp;
                    {props && props.values && props.values.certificate}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color:
                        props &&
                        props.values &&
                        props.values.results.includes('POSITIVE')
                          ? 'red'
                          : '#0028a1',
                      paddingLeft: '20px',
                    }}
                  >
                    Result
                  </td>
                  <td
                    style={{
                      marginLeft: '10px',
                      paddingLeft: '20px',
                      color:
                        props &&
                        props.values &&
                        props.values.results.includes('POSITIVE')
                          ? 'red'
                          : '#0028a1',
                    }}
                  >
                    {'  '}&nbsp;&nbsp;
                    {props && props.values && props.values.results}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div
            style={{
              marginLeft: '8px',
              color: '#0028a1',
              fontFamily: 'Bigshot One',
              textAlign: 'center',
            }}
          >
            {/* <table
              style={{
                marginLeft: '100px',
                fontFamily: 'Bahnschrift',
                fontWeight: 'bold',
              }}
            >
              <tbody>
                <tr>
                  <td>QR Code</td>
                  <td>
                    <h5
                      style={{
                        fontFamily: 'Bigshot One',
                        fontSize: '20px',
                        // fontWeight: 'bold',
                      }}
                    >
                      AVIGENICS Pty LIMITED
                    </h5>
                    <h6
                      style={{
                        fontFamily: 'Bigshot One',
                        fontSize: '13px',
                        // fontWeight: 'bold',
                      }}
                    >
                 
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table> */}
            <h5
              style={{
                fontFamily: 'Bigshot One',
                fontSize: '20px',
                // fontWeight: 'bold',
              }}
            >
              AVIGENICS INC
            </h5>
            <h6
              style={{
                fontFamily: 'Bigshot One',
                fontSize: '13px',
                // fontWeight: 'bold',
              }}
            >
              Avigenics Inc, <br />
              1562 BELVEDERE AVE <br />
              Okemos, MI 48864 <br /> &nbsp; U S A <br />
              {'  '}info@avigenicsusa.com{'  '} https://avigenicsusa.com
            </h6>
            <h5
              style={{
                fontFamily: 'Bigshot One',
                fontSize: '13px',
                // fontWeight: 'bold',
              }}
            >
              + 1 (313) 338-9022
            </h5>
          </div>
        </div>
      </div>

      <button onClick={() => certSaveAs()}>Save</button>
    </>
  )
}

export default Certificate

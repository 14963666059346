import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const TermsOfUse = () => {
  const [show, setShow] = useState(true)
  const history = useHistory()

  return (
    <>
      <div>
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={show}
          onHide={() => {
            setShow(false)
            history.goBack()
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Terms of Use</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Modal.Title>Website / App Terms of Use</Modal.Title>
            <p>
              These Website Terms of Use, together with any and all other
              documents referred to in them, set out the terms of use under
              which you may use this website, avigenics.eu (“our Site”). Please
              read these Website Terms of Use carefully and ensure that you
              understand them. Your agreement to comply with and be bound by
              these Website Terms of Use is deemed to occur upon your first use
              of our Site. If you do not agree to comply with and be bound by
              these Website Terms of Use, you must stop using our Site
              immediately.
            </p>
            <Modal.Title>1.Definitions and Interpretation</Modal.Title>
            <p>
              1.1 In these Website Terms of Use, unless the context otherwise
              requires, the following expressions have the following meanings:
              <br />
              “Content” means any and all text, images, audio, video, scripts,
              code, software, databases and any other form of information
              capable of being stored on a computer that appears on, or forms
              part of, our Site; and
              <br />
              We/Us/Our” means Avigenics INC, a company whose registered address
              is 1562 Belvedere Ave,Okemos, MI 48864, USA.
            </p>
            <Modal.Title>2.Access to Our Site</Modal.Title>{' '}
            <p>
              2.1 Access to our Site is free of charge.
              <br />
              2.2 It is your responsibility to make any and all arrangements
              necessary in order to access our Site.
              <br />
              2.3 Access to our Site is provided “as is” and on an “as
              available” basis. We may alter, suspend or discontinue our Site
              (or any part of it) at any time and without notice. We will not be
              liable to you in any way if our Site (or any part of it) is
              unavailable at any time or for any period.
            </p>
            <Modal.Title>3. Intellectual Property Rights</Modal.Title>{' '}
            <p>
              3.1 All Content included on our Site and the copyright and other
              intellectual property rights subsisting in that Content, unless
              specifically labelled otherwise, belongs to or has been licensed
              by us. All Content is protected by applicable United Kingdom and
              international intellectual property laws and treaties.
              <br />
              3.2 Subject to clauses 3.3, you may not reproduce, copy,
              distribute, sell, rent, sub-licence, store, or in any other manner
              re-use Content from our Site unless given express written
              permission to do so by us.
              <br />
              3.3 You may:
              <br />
              3.3.1 Access, view and use our Site in a web browser (including
              any web browsing capability built into other types of software or
              app);
              <br />
              3.3.2 Download our Site (or any part of it) for caching;
              <br />
              3.3.3 Print pages from our Site;
              <br />
              3.3.4 Download extracts from pages on our Site; and
              <br />
              3.3.5 Save pages from our Site for later and/or offline viewing.
              <br />
              3.4 Our status as the owner and author of the Content on our Site
              (or that of identified licensors, as appropriate) must always be
              acknowledged.
              <br />
              3.5 You may not use any Content saved or downloaded from our Site
              for commercial purposes without first obtaining a licence from us
              (or our licensors, as appropriate) to do so. This does not
              prohibit the normal access, viewing and use of our Site for
              general information purposes whether by business users or
              consumers.
            </p>
            <Modal.Title>4. Links to Our Site</Modal.Title>{' '}
            <p>
              4.1 You may link to our Site provided that:
              <br />
              4.1.1 You do so in a fair and legal manner;
              <br />
              4.1.2 You do not do so in a manner that suggests any form of
              association, endorsement or approval on our part where none
              exists;
              <br />
              4.1.3 You do not use any logos or trade marks displayed on our
              Site without our express written permission; and
              <br />
              4.1.4 You do not do so in a way that is calculated to damage our
              reputation or to take unfair advantage of it.
              <br />
              4.2 Framing or embedding of our Site on other websites is not
              permitted without our express written permission.
              <br />
              4.3 You may not link to our Site from any other site the main
              content of which contains material that:
              <br />
              4.3.1 is obscene, deliberately offensive, hateful or otherwise
              inflammatory;
              <br />
              4.3.2 promotes violence;
              <br />
              4.3.3 promotes or assists in any form of unlawful activity;
              <br />
              4.3.4 discriminates against, or is in any way defamatory of, any
              person, group or class of persons, race, sex, religion,
              nationality, disability, sexual orientation, or age;
              <br />
              4.3.5 is intended or is otherwise likely to threaten, harass,
              annoy, alarm, inconvenience, upset, or embarrass another person;
              <br />
              4.3.6 is calculated or is otherwise likely to deceive another
              person;
              <br />
              4.3.7 is intended or is otherwise likely to infringe (or to
              threaten to infringe) another person’s privacy;
              <br />
              4.3.8 misleadingly impersonates any person or otherwise
              misrepresents the identity or affiliation of a particular person
              in a way that is calculated to deceive (obvious parodies are not
              included in this definition provided that they do not fall within
              any of the other provisions of this clause 4.3);
              <br />
              4.3.9 implies any form of affiliation with us where none exists;
              <br />
              4.3.10 infringes, or assists in the infringement of, the
              intellectual property rights (including, but not limited to,
              copyright, trademarks and database rights) of any other party; or
              <br />
              4.3.11 is made in breach of any legal duty owed to a third party
              including, but not limited to, contractual duties and duties of
              confidence.
              <br />
              4.4 The content restrictions in clause 4.3 do not apply to content
              submitted to sites by other users provided that the primary
              purpose of the site accords with the provisions of clause 4.3. You
              are not, for example, prohibited from posting links on
              general-purpose social networking sites merely because another
              user may post such content. You are, however, prohibited from
              posting links on websites which focus on or encourage the
              submission of such content from users.
            </p>
            <Modal.Title>5. Links to Other Sites</Modal.Title>{' '}
            <p>
              Links to other sites may be included on our Site. Unless expressly
              stated, these sites are not under our control. We neither assume
              nor accept responsibility or liability for the content of third
              party sites. The inclusion of a link to another site on our Site
              is for information only and does not imply any endorsement of the
              sites themselves or of those in control of them.
            </p>
            <Modal.Title>6. Disclaimers</Modal.Title>{' '}
            <p>
              6.1 Nothing on our Site constitutes advice on which you should
              rely. It is provided for general information purposes only.
              <br />
              6.2 Insofar as is permitted by law, we make no representation,
              warranty, or guarantee that our Site will meet your requirements,
              that it will not infringe the rights of third parties, that it
              will be compatible with all software and hardware, or that it will
              be secure.
              <br />
              6.3 We make reasonable efforts to ensure that the Content on our
              Site is complete, accurate, and up-to-date. We do not, however,
              make any representations, warranties or guarantees (whether
              express or implied) that the Content is complete, accurate, or
              up-to-date.
            </p>
            <Modal.Title>7. Our Liability</Modal.Title>{' '}
            <p>
              7.1 To the fullest extent permissible by law, we accept no
              liability to any user for any loss or damage, whether foreseeable
              or otherwise, in contract, tort (including negligence), for breach
              of statutory duty, or otherwise, arising out of or in connection
              with the use of (or inability to use) our Site or the use of or
              reliance upon any Content included on our Site.
              <br />
              7.2 To the fullest extent permissible by law, we exclude all
              representations, warranties, and guarantees (whether express or
              implied) that may apply to our Site or any Content included on our
              Site.
              <br />
              7.3 If you are a business user, we accept no liability for loss of
              profits, sales, business or revenue; loss of business opportunity,
              goodwill or reputation; loss of anticipated savings; business
              interruption; or for any indirect or consequential loss or damage.
              <br />
              7.4 We exercise all reasonable skill and care to ensure that our
              Site is free from viruses and other malware. We accept no
              liability for any loss or damage resulting from a virus or other
              malware, a distributed denial of service attack, or other harmful
              material or event that may adversely affect your hardware,
              software, data or other material that occurs as a result of your
              use of our Site (including the downloading of any Content from it)
              or any other site referred to on our Site.
              <br />
              7.5 We neither assume nor accept responsibility or liability
              arising out of any disruption or non-availability of our Site
              resulting from external causes including, but not limited to,
              internet service provider equipment failure, host equipment
              failure, communications network failure, natural events, acts of
              war, or legal restrictions and censorship.
              <br />
              7.6 Nothing in these Website Terms of Use excludes or restricts
              our liability for fraud or fraudulent misrepresentation, for death
              or personal injury resulting from negligence, or for any other
              forms of liability which cannot be excluded or restricted by law.
              If you are a consumer visiting our Site, for full details of your
              legal rights, including those relating to digital content, please
              contact your local Citizens’ Advice Bureau or Trading Standards
              Office.
            </p>
            <Modal.Title>8.Viruses, Malware and Security</Modal.Title>
            <p>
              8.1 We exercise all reasonable skill and care to ensure that our
              Site is secure and free from viruses and other malware.
              <br />
              8.2 You are responsible for protecting your hardware, software,
              data and other material from viruses, malware, and other internet
              security risks.
              <br />
              8.3 You must not deliberately introduce viruses or other malware,
              or any other material which is malicious or technologically
              harmful either to or via our Site.
              <br />
              8.4 You must not attempt to gain unauthorised access to any part
              of our Site, the server on which our Site is stored, or any other
              server, computer, or database connected to our Site.
              <br />
              8.5 You must not attack our Site by means of a denial of service
              attack, a distributed denial of service attack, or by any other
              means.
              <br />
              8.6 By breaching the provisions of clauses 8.3 to 8.5, you may be
              committing a criminal offence under the Computer Misuse Act 1990.
              Any and all such breaches will be reported to the relevant law
              enforcement authorities and we will cooperate fully with those
              authorities by disclosing your identity to them. Your right to use
              our Site will cease immediately in the event of such a breach.
            </p>
            <Modal.Title>9. Acceptable Usage Policy</Modal.Title>
            <p>
              9.1 You may only use our Site in a manner that is lawful.
              Specifically:
              <br />
              9.1.1 you must ensure that you comply fully with any and all
              local, national or international laws and/or regulations;
              <br />
              9.1.2 you must not use our Site in any way, or for any purpose,
              that is unlawful or fraudulent;
              <br />
              9.1.3 you must not use our Site to knowingly send, upload, or in
              any other way transmit data that contains any form of virus or
              other malware, or any other code designed to adversely affect
              computer hardware, software, or data of any kind; and
              <br />
              9.1.4 you must not use our Site in any way, or for any purpose,
              that is intended to harm any person or persons in any way.
              <br />
              9.2 We reserve the right to suspend or terminate your access to
              our Site if you materially breach the provisions of this clause 9
              or any of the other provisions of these Website Terms of Use.
              Specifically, we may take one or more of the following actions:
              <br />
              9.2.1 suspend, whether temporarily or permanently, your right to
              access our Site;
              <br />
              9.2.2 issue you with a written warning;
              <br />
              9.2.3 take legal proceedings against you for reimbursement of any
              and all relevant costs on an indemnity basis resulting from your
              breach;
              <br />
              9.2.4 take further legal action against you as appropriate;
              <br />
              9.2.5 disclose such information to law enforcement authorities as
              required or as we deem reasonably necessary; and/or
              <br />
              9.2.6 any other actions which we deem reasonably appropriate (and
              lawful).
              <br />
              9.3 We exclude any and all liability arising out of any actions
              (including, but not limited to those set out above) that we may
              take in response to breaches of these Website Terms of Use.
            </p>
            <Modal.Title>10. Privacy and Cookies</Modal.Title>
            <p>
              Use of our Site is also governed by our Privacy Policy, available
              from ( Privacy Policy ). Our Privacy Policy is incorporated into
              these Website Terms of Use by this reference.
            </p>
            <Modal.Title>11. Changes to these Website Terms of Use</Modal.Title>
            <p>
              <br />
              11.1 We may alter these Website Terms of Use at any time. Any such
              changes will become binding on you upon your first use of our Site
              after the changes have been implemented. You are therefore advised
              to check this page from time to time.
              <br />
              11.2 In the event of any conflict between the current version of
              these Website Terms of Use and any previous version(s), the
              provisions current and in effect shall prevail unless it is
              expressly stated otherwise.
            </p>
            <Modal.Title>12. Contacting Us</Modal.Title>
            <p>
              To contact us, please email us at info@avigenicsusa.com, by
              telephone on (313) 338-9022, or by post at 1562 BELVEDERE
              AVE,Okemos, MI 48864, USA.
            </p>
            <Modal.Title>13. Communications from Us</Modal.Title>
            <p>
              13.1 If we have your contact details, we may from time to time
              send you important notices by email. Such notices may relate to
              matters including, but not limited to, service changes and changes
              to these Website Terms of Use.
              <br />
              13.2 We will never send you marketing messages of any kind without
              your express consent. If you do give such consent, you may opt out
              at any time. Any and all marketing messages sent by us include an
              unsubscribe link. If you opt out of receiving emails from us at
              any time, it may take up to 7 business days for us to comply with
              your request. During that time, you may continue to receive
              messages from us. Further information is set out in our Privacy
              Policy.
              <br />
              13.3 For questions or complaints about communications from us
              (including, but not limited to marketing emails), please contact
              us using the details set out in clause 12.
            </p>
            <Modal.Title>14. Law and Jurisdiction</Modal.Title>
            <p>
              14.1 These Website Terms of Use, and the relationship between you
              and us (whether contractual or otherwise) shall be governed by,
              and construed in accordance with the laws of USA .
              <br />
              14.2 If you are a consumer, you will benefit from any mandatory
              provisions of the law in your country of residence. Nothing in
              clause 14.1 above takes away or reduces your rights as a consumer
              to rely on those provisions.
              <br />
              14.3 If you are a consumer, any dispute, controversy, proceedings
              or claim between you and us relating to these Website Terms of
              Use, or the relationship between you and us (whether contractual
              or otherwise) shall be subject to the jurisdiction of the courts
              of USA as determined by your residency.
              <br />
              14.4 If you are a business, any disputes concerning these Website
              Terms of Use, the relationship between you and us, or any matters
              arising therefrom or associated therewith (whether contractual or
              otherwise) shall be subject to the exclusive jurisdiction of the
              courts of USA.
            </p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>{' '}
      </div>
    </>
  )
}

export default TermsOfUse

import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const ContactUs = () => {
  const history = useHistory()
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [buttonText, setButtonText] = useState('Submit')
  const [username, setUsername] = useState()
  const [email, setEmail] = useState()
  const [subject, setSubject] = useState()
  const [matter, setMatter] = useState()
  const [phone, setPhone] = useState()

  const sendMail = async (e) => {
    e.preventDefault()
    setButtonText('Sending...')
    const resp = await axios.post('/api/sendmail/', {
      to: 'info@avigenicsusa.com',
      text: `      <div class='col-lg-6'>
        <div>
          <p>
          AVIGENICS - Enquiry:<br/>
          Name: ${username}<br/>
          Phone: ${phone}<br/>
          EMail: ${email}<br/>
          Subject: ${subject}<br/>
          Message: ${matter}<br/>
          </p>

      </div>`,
      // text: 'WHY DO YOU NEED ONE',
      subject: 'Enquiry on Avigenics USA',
      replyTo: `${email}, info@avigenicsusa.com`,
    })
    setButtonText('Submit')

    if (resp && resp.data && resp.data.status === 'success') {
      toast(
        'Your message sent successfully.\n Someone will get back to you soon.',
        { type: 'success' }
      )
      history.replace('/')
    } else {
      toast(
        'Oops! There was some problem.\n Please contact our support staff by calling + 1 (313) 338-9022 or email to info@avigenicsusa.com.',
        { type: 'error' }
      )
      history.replace('/')
    }
  }
  return (
    <>
      {/* <!-- Page Title --> */}
      <section className='page_breadcrumb'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <ol className='page_bread'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='current_page'>Contact Us</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      {/* <!-- Contact Info Section --> */}
      <section className='contact-info-section'>
        <div className='auto-container'>
          {/* <!-- Sec Title --> */}
          <div className='sec-title centered'>
            <div className='title'>Please get in touch</div>
            <br />
            <br />
            <form className='contact-form' onSubmit={sendMail}>
              <div className='row clearfix'>
                <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                  <span className='icon flaticon-user-2'></span>
                  <input
                    type='text'
                    name='user_name'
                    placeholder='Your Name'
                    required
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                  <span className='icon flaticon-phone-call'></span>
                  <input
                    type='number'
                    name='contact_number'
                    placeholder='Your Phone Number'
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                  <span className='icon flaticon-big-envelope'></span>
                  <input
                    type='email'
                    name='user_email'
                    placeholder='Email'
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                  <span className='icon flaticon-notepad'></span>
                  <input
                    type='text'
                    name='subject'
                    placeholder='Subject'
                    required
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>

                <div className='col-lg-12 col-md-12 col-sm-12 form-group'>
                  <span className='icon flaticon-message'></span>
                  <textarea
                    name='message'
                    placeholder='Message'
                    onChange={(e) => setMatter(e.target.value)}
                  ></textarea>
                </div>
                <ReCAPTCHA
                  sitekey={'6Lc2p7AaAAAAAIxgYPpp-QNotke27VF5aQzZFhqR'}
                  onChange={() => setButtonEnabled(true)}
                />
                <div className='col-lg-12 col-md-12 col-sm-12 text-center form-group'>
                  <button
                    className='theme-btn btn-style-three'
                    type='submit'
                    name='submit-form'
                    disabled={!buttonEnabled}
                  >
                    {buttonEnabled ? (
                      <span className='txt'>{buttonText} </span>
                    ) : (
                      <span className='txt'>I am a Robot </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
            <br />
            <h2 className='title'>Contact information</h2>
            <div className='text'></div>
          </div>
          <div className='inner-container'>
            <div className='row clearfix'>
              {/* <!-- Column --> */}
              <div className='column col-lg-6 col-md-6 col-sm-12'>
                <div className='info-box'>
                  <div className='box-inner'>
                    <div
                      style={{ color: '#0028a1' }}
                      className='icon flaticon-clock'
                    ></div>
                    Mon-Fri <br /> 9:00 am to 5:00 pm
                  </div>
                </div>
              </div>
              {/* <!-- Column --> */}
              <div className='column col-lg-6 col-md-6 col-sm-12'>
                <div className='info-box'>
                  <div className='box-inner'>
                    <div
                      style={{ color: '#0028a1' }}
                      className='icon flaticon-pin'
                    ></div>
                    1562 Belvedere Ave, <br />
                    Okemos, MI 48864 <br />
                    USA.
                  </div>{' '}
                </div>
              </div>{' '}
              <div className='column col-lg-6 col-md-6 col-sm-12'>
                <div className='info-box'>
                  <div className='box-inner'>
                    <div
                      style={{ color: 'green' }}
                      className='icon fa fa-whatsapp'
                    ></div>
                    <a href='https://wa.me/15175991517'> +1 (517)599-1517 </a>
                  </div>{' '}
                </div>
              </div>
              {/* <!-- Column --> */}
              <div className='column col-lg-6 col-md-6 col-sm-12'>
                <div className='info-box'>
                  <div className='box-inner'>
                    <div
                      style={{ color: '#0028a1' }}
                      className='icon flaticon-phone-call'
                    ></div>
                    <a href='tel:+13133389022'>+ 1 (313) 338-9022</a>
                    <br />
                    <a href='mailto:info@avigenicsusa.com'>
                      info@avigenicsusa.com
                    </a>
                    <br />
                  </div>
                </div>
              </div>
              {/* <!-- Column --> */}
              {/* <div className='column col-lg-6 col-md-6 col-sm-12'>
                <ul className='social-box'>
                  <span>
                    Our Socials Links{' '}
                    <i>
                      Print this page to PDF for the complete set of vectors.
                    </i>
                  </span>
                  <li className='facebook'>
                    <a className='fa fa-facebook-f' href='#'></a>
                  </li>
                  <li className='twitter'>
                    <a className='fa fa-twitter' href='#'></a>
                  </li>
                  <li className='linkedin'>
                    <a href='#' className='fa fa-google-plus'></a>
                  </li>
                  <li className='pinterest'>
                    <a href='#' className='fa fa-pinterest-p'></a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          {/* <!-- Map Boxed --> */}
          <div className='map-boxed'>
            {/* <!-- Map Outer --> */}
            <div className='map-outer'>
              {/* <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.621112905841!2d78.44427291444916!3d17.429961388053123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90cc74d6ea5b%3A0x6c3ef3ed4acaca31!2sYaaron%20Media!5e0!3m2!1sen!2sin!4v1612529022570!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> --> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUs

import { useState } from 'react'
import { Button, Table, Nav, Col, Row, Form } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
import AdminModals from './AdminModals'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import axios from 'axios'
import { useOrder } from '../../config/OrderContext'

const ComponentToShow = (props) => {
  const {
    filteredBatches,
    loading,
    clickedButton,
    getCustomer,
    statusUpdate,
    getBatch,
    setSelectedBatch,
    setArrivedDate,
    findDuplicateSamples,
    updateResultDate,
    size,
    setSize,
    updateOrderCost,
  } = useAdmin()
  const { deleteOrder } = useOrder()
  const [showModal, setShowModal] = useState({ name: '', show: false })
  const [enableArrived, setEnableArrived] = useState(false)
  const curr = new Date()
  curr.setDate(curr.getDate())
  const date = curr.toISOString().split('T')[0]

  const sendEmail = async (id, name, email, samples) => {
    const jsxHtml =
      samples &&
      samples.length > 0 &&
      samples.map(
        (sample, idx) =>
          `<tr key=${idx}>
                        <td>${sample.ringNo}</td>
                        <td>${sample.selectedSpecies}</td>
                        <td>${sample.testType}</td>
                        <td>${sample.ownerName ? sample.ownerName : name}</td>
                        <td>${sample.sampleType}</td>
                      </tr>`
      )

    const sasi = jsxHtml && jsxHtml.toString().replaceAll('>,<', '><')

    if (window.confirm('Do you want to send arrived email?')) {
      try {
        await axios.post('/api/sendmail/', {
          to: email,
          text: `Dear ${name}, <br/><br/>Your Samples have arrived to us today.<br/>
        It takes 7 to 10 Business days to process your samples.<br/>
        
        Sample Details:
        <br/>
        <table border responsive size='lg'>
          <thead>
            <tr>
              <th width='200px'>Ring ID</th>
              <th width='200px'>Species</th>
              <th width='200px'>Test Type</th> 
              <th width='200px'>Owner</th>
              <th width='200px'>Sample Type</th>            
            
            </tr>
          </thead><tbody>${sasi}</tbody>
        </table><br/>
         Please contact us if you have any questions.<br/><br/>
<p>Thanks & Regards,
            <br />
            Team Avigenics<br/><br/>
                      <img
            style='width: 150px;' height: 80px;' }}
            src='https://avigenicsusa.com/images/logo-6.png'
          /><br/><br/>
E-mail:   info@avigenicsusa.com  |   Phone:   (313) 338-9022 <br/>
            Avigenics Inc, 1562 Belvedere Ave,Okemos, MI 48864, USA. <br/>
            <a href='https://avigenicsusa.com/'>www.avigenicsusa.com</a><br/>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>`,
          subject: `Order ID: ${id}`,
          bcc: 'orders@avigenicsusa.com',
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  const exportToCSV = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    let flattenArray = []

    filteredBatches.forEach((batch) => {
      flattenArray.push(flattenSamples(batch))
    })
    const ws = XLSX.utils.json_to_sheet(flattenArray.flat())
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, `${clickedButton} on ${date} ${fileExtension}`)
  }

  function flattenSamples(batch) {
    const owner = batch.customerName
    return batch.samples.map((data) => {
      !data.sampleType &&
        clickedButton === 'Arrived' &&
        alert('Please complete Sample Type FIRST')
      return {
        'Batch No': batch.labBatch,
        'Ring No': data.ringNo ? data.ringNo.toUpperCase() : '',
        'Species Name': data.selectedSpecies ? data.selectedSpecies : '',
        'Sample Type': data.sampleType ? data.sampleType : '',
        Owner: owner,
        'Order ID': batch.batchName,
        'Arrived Date': batch.arrDate,
        LabId: '',
        'Result ': data.results ? data.results : '',
        'Result Date': data.Resultdate ? data.Resultdate : '',
        'Test Type': data.testType ? data.testType : '',
      }
    })
  }

  const handleArrived = async (batch) => {
    await setArrivedDate(batch)
    // batch.items.map(async (item) => {
    //   if (item.type === 'pbfd') {
    //     await axios.post('/api/sendsms/', {
    //       mobile: '+447801355883',
    //       text: `Avigenics PBFD order: ${batch.batchName} from ${batch.customerName}`,
    //     })
    //     await axios.post('/api/sendsms/', {
    //       mobile: '+919343222555',
    //       text: `Avigenics PBFD order: ${batch.batchName} from ${batch.customerName}`,
    //     })
    //   } else {
    //     if (item.type === 'apv') {
    //       await axios.post('/api/sendsms/', {
    //         mobile: '+447801355883',
    //         text: `Avigenics APV order: ${batch.batchName} from ${batch.customerName}`,
    //       })
    //       await axios.post('/api/sendsms/', {
    //         mobile: '+919343222555',
    //         text: `Avigenics APV order: ${batch.batchName} from ${batch.customerName}`,
    //       })
    //     }
    //   }
    // })
    await sendEmail(
      batch.batchName,
      batch.customerName,
      batch.custId,
      batch.samples
    )
  }

  return (
    <>
      <Row>
        <Col align='center' sm={6}>
          {' '}
          <h4
            className='blink_me2'
            style={{ backgroundColor: 'green', color: 'white' }}
          >
            Order Status: {clickedButton}
          </h4>
        </Col>
        <Col align='center' sm={2}>
          {' '}
          <Button
            disabled={props.disableButton}
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '140px',
            }}
            onClick={() => exportToCSV()}
          >
            {props.disableButton ? 'Please set Lab ID' : 'Export to Excel'}
          </Button>
        </Col>
      </Row>
      {loading && <h3>Loading.... </h3>}
      {clickedButton === 'In Lab' && (
        <Form.Check
          checked={enableArrived}
          type='checkbox'
          label='Enable Arrived Button'
          onChange={(e) => {
            setEnableArrived(e.target.checked)
          }}
        />
      )}
      <Table
        responsive
        size='sm'
        striped
        hover
        style={{ tableLayout: 'fixed' }}
      >
        <thead>
          <tr align='center'>
            <th>Customer Name</th>
            <th>Order id</th>
            <th>Samples</th>
            {/* <th>Items</th> */}
            <th>Price</th>
            <th>Arrived On</th>
            <th>Payment</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredBatches &&
            filteredBatches.map((batch) => (
              <tr
                key={batch.id}
                style={{
                  backgroundColor:
                    (batch.status === 'Completed' && '#00cc00') ||
                    (batch.status === 'Results Ready' && 'lightgreen') ||
                    (batch.status === 'In Progress' && '#CCCC00') ||
                    (batch.status === 'Posted' && '#CCCCCC') ||
                    (batch.status === 'In Lab' && '#9ef') ||
                    (batch.status === 'Arrived' && 'pink') ||
                    (batch.status === 'Delete' && 'salmon'),
                  fontStyle: batch.orderType === 'Manual' && 'italic',
                  textDecoration: batch.orderType === 'Manual' && 'underline',
                }}
              >
                <td>
                  <Nav.Link
                    onClick={() => {
                      getCustomer(batch.custId)
                      setShowModal({ name: 'Customer', show: true })
                    }}
                  >
                    {batch.customerName}
                  </Nav.Link>
                </td>
                <td>
                  <Nav.Link
                    onClick={async () => {
                      await setSelectedBatch(batch)
                      await setShowModal({
                        name: 'Order Details',
                        show: true,
                      })
                      await findDuplicateSamples(batch)
                    }}
                  >
                    {batch.batchName}
                  </Nav.Link>
                </td>
                <td align='center'>
                  {batch.samples.length}
                  {batch.kitsNeeded && '*K '}
                  {batch.certsNeeded && '*C'}
                </td>

                {/* <td align='center'>
                  {batch.items
                    ? batch.items.map((item) => item.type + ', ')
                    : batch.kitsNeeded && batch.kitsNeeded.type
                    ? 'dna,kits'
                    : 'dna'}
                </td> */}
                <td align='center'>
                  $
                  {batch.orderCost ? (
                    Math.round(batch.orderCost * 100) / 100
                  ) : (
                    <span
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => updateOrderCost(batch)}
                    >
                      {' '}
                      Update
                    </span>
                  )}
                </td>
                {batch.status === 'Posted' ? (
                  <td>
                    {batch.courierCompany && batch.courierCompany}
                    <br />
                    {batch.courierRef ? batch.courierRef : 'Admin Clicked Post'}
                  </td>
                ) : (
                  <td align='center'>{batch.arrDate && batch.arrDate}</td>
                )}
                <td
                  align='center'
                  style={{
                    color: batch.payStatus === 'Not Paid' ? 'red' : 'blue',
                  }}
                >
                  {batch.payStatus === 'Not Paid' ? (
                    <Nav.Link
                      style={{ color: 'red' }}
                      onClick={() => {
                        getBatch(batch.id)
                        setShowModal({ name: 'Update Payment', show: true })
                      }}
                    >
                      {batch.payStatus}
                    </Nav.Link>
                  ) : (
                    <Nav.Link
                      style={{ color: 'blue' }}
                      onClick={() => {
                        getBatch(batch.id)
                        setShowModal({ name: 'Update Payment', show: true })
                      }}
                    >
                      {batch.payType ? batch.payType : 'Online'}
                    </Nav.Link>
                  )}
                </td>
                <td align='center'>{batch.status}</td>
                <td align='right'>
                  {props.prev !== '' && props.prev !== 'Force Delete' ? (
                    <Button
                      disabled={props.prev === 'Arrived' && !enableArrived}
                      style={{
                        padding: '4px',
                        borderRadius: '30px',
                        margin: '3px',
                        width: '100px',
                      }}
                      onClick={() => {
                        statusUpdate(batch.id, props.prev)
                      }}
                    >
                      {props.prev}
                    </Button>
                  ) : (
                    props.prev === 'Force Delete' && (
                      <Button
                        style={{
                          padding: '4px',
                          borderRadius: '30px',
                          margin: '3px',
                          width: '100px',
                        }}
                        onClick={() => {
                          batch.payStatus === 'Paid'
                            ? alert('You cannot delete as the order is paid')
                            : deleteOrder(batch.id)
                        }}
                      >
                        {' '}
                        Force Delete
                      </Button>
                    )
                  )}
                </td>
                <td align='left'>
                  {props.next !== '' && (
                    <Button
                      disabled={props.disableButton}
                      style={{
                        padding: '4px',
                        borderRadius: '30px',
                        margin: '3px',
                        width: '100px',
                      }}
                      onClick={() => {
                        if (props.next === 'Arrived') {
                          handleArrived(batch)
                        } else {
                          if (props.next === 'Results Ready') {
                            updateResultDate(batch)
                          }
                        }
                        statusUpdate(batch.id, props.next)
                      }}
                    >
                      {props.next}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Button onClick={() => setSize(size + 100)}>100 More...</Button>
      {'  '}
      <Button onClick={() => setSize(10000)}>Everything</Button>
      {'  '}
      {showModal.show && (
        <AdminModals showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  )
}

export default ComponentToShow

import { useEffect } from 'react'

const APV = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      {/* <!-- Page Title --> */}
      <section className='page_breadcrumb'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <ol className='page_bread'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='current_page'>Avian Polyoma Virus</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/dna.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  <div className='title'>AVIGENICS</div>
                  <h2>Avian Polyoma Virus (APV)</h2>
                </div>
                <div className='bold-text lh-lg'>
                  The Avian Polyoma Virus is one of the most significant viral
                  pathogens in parrots that causes substantial economic losses
                  for aviculturists. Signs and symptoms of APV are very similar
                  to and often confused with PBFD. With a high mortality rate,
                  acute APV causes sudden death in chicks younger than 8-12
                  weeks and can kill chicks in the breeding season. With very
                  little options available for treatment, prevention is the only
                  method to protect the aviary from Avian Polyoma Virus.
                </div>
              </div>
            </div>
          </div>
          <h5>
            *** For more accuracy of the results we recommend to send both Blood
            and Feather Samples for this test ***
          </h5>
        </div>
      </section>

      {/* <!-- End Services Section Three --> */}

      {/* <!-- Mission Section --> */}

      {/* <!-- End Services Section Three --></section> */}
    </div>
  )
}
export default APV

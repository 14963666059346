import React, { useContext, useEffect, useState } from 'react'
import { auth } from './FireBase'
import { useIdleTimer } from 'react-idle-timer'

const AuthContext = React.createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
    const [currentUser, setCurrentUser] = useState()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  const signup = (email, password, displayName) => {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (result) =>
        result.user.updateProfile({
          displayName,
        })
      )
  }

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password)
  }

  const forgotPass = async (email) => {
    try {
      await auth.sendPasswordResetEmail(email)
      return 'Success'
    } catch (error) {
      if (error.code === 'auth/user-not-found') return 'User Not Found'
      return 'Some Error'
    }
  }

  const logout = () => {
    return auth.signOut()
  }
  const value = { currentUser, signup, login, logout, forgotPass }

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: logout,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 1000,
  })

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

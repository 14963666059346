import { useState } from 'react'
import { Button, Col, Nav, Table, Modal } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
import AdminModals from './AdminModals'
import PDFCertificate from './PDFCertificate'
// import CCPrint from './CCPrint'

const CCCerts = () => {
  const [aaa, setAAA] = useState({})
  const [showPrint, setShowPrint] = useState(false)

  const {
    getCustomer,
    setSelectedBatch,

    certsWanted,
    flattenSamples,
  } = useAdmin()
  const [showModal, setShowModal] = useState({ name: '', show: false })
  const ccCertsWanted = certsWanted.filter(
    (batch) =>
      batch.certsNeeded &&
      batch.certsNeeded.type === 'CreditCard Certificates' &&
      batch.status === 'Results Ready' &&
      batch.certsNeeded.sent !== 'Sent'
  )

  const toPrintPDF = () => {
    let flattenArray = []
    ccCertsWanted.forEach((batch) => {
      flattenArray.push(flattenSamples(batch))
    })

    setAAA(flattenArray.flat())
    setShowPrint(true)
  }

  return (
    <>
      <Button onClick={() => toPrintPDF()}>PDF</Button>
      <Col sm={12}>
        <Table responsive size='sm'>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>E-Mail</th>
              <th>Order id</th>
              <th>Samples</th>
              <th>Certs Type</th>
              <th>Sent?</th>
            </tr>
          </thead>
          <tbody>
            {ccCertsWanted &&
              ccCertsWanted.map((batch) => (
                <tr
                  key={batch.id}
                  style={{
                    backgroundColor:
                      (batch.status === 'Completed' && '#00cc00') ||
                      (batch.status === 'Results Ready' && 'lightgreen') ||
                      (batch.status === 'In Progress' && '#CCCC00') ||
                      (batch.status === 'Posted' && '#CCCCCC') ||
                      (batch.status === 'In Lab' && '#9ef') ||
                      (batch.status === 'Arrived' && 'pink') ||
                      (batch.status === 'Delete' && 'salmon'),
                    fontStyle: batch.orderType === 'Manual' && 'italic',
                    textDecoration: batch.orderType === 'Manual' && 'underline',
                  }}
                >
                  {batch.certsNeeded &&
                    !batch.certsNeeded.sent &&
                    batch.status === 'Results Ready' && (
                      <>
                        {' '}
                        <td>
                          <Nav.Link
                            onClick={() => {
                              getCustomer(batch.custId)
                              setShowModal({ name: 'Customer', show: true })
                            }}
                          >
                            {batch.customerName}
                          </Nav.Link>
                        </td>
                        <td>{batch.custId}</td>
                        <td>
                          <Nav.Link
                            onClick={() => {
                              setSelectedBatch(batch)
                              setShowModal({
                                name: 'Order Details',
                                show: true,
                              })
                            }}
                          >
                            {batch.batchName}
                          </Nav.Link>
                        </td>
                        <td>{batch.samples.length}</td>
                        <td>{batch.certsNeeded.type}</td>
                        <td>
                          <span style={{ color: 'red' }}>Not Sent</span>
                        </td>
                      </>
                    )}
                </tr>
              ))}
          </tbody>
        </Table>
      </Col>
      {showModal.show && (
        <AdminModals showModal={showModal} setShowModal={setShowModal} />
      )}
      {showPrint && (
        <Modal
          dialogClassName='modal-890pxw'
          show={showPrint}
          onHide={() => {
            setShowPrint(false)
          }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <PDFCertificate values={aaa} />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default CCCerts

import { useEffect } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
const SearchFilters = () => {
  const {
    loading,
    allBatches,
    totalSamples,
    findFiltered,
    setFilteredBatches,
    totalOrders,
    handlePaidUnpaid,
    handleDateFilter,
    toDate,
    setToDate,
    setFromDate,
    clickedButton,
  } = useAdmin()

  useEffect(() => {
    findFiltered('All')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      {loading ? (
        <h1>Loading......</h1>
      ) : (
        <div className='text-center'>
          {loading ? (
            <>Loading....</>
          ) : (
            <>
              <Row className='justify-content-center'>
                <Col sm={3}>
                  Total Orders = {totalOrders} Total Samples ={' '}
                  {loading ? 'Loading...' : totalSamples}
                </Col>
                <Col sm={3}>
                  <Form.Group style={{ marginLeft: '50px' }}>
                    <Form.Check
                      inline
                      label='All'
                      name='group1'
                      type='radio'
                      id='type1'
                      key='1'
                      defaultChecked={'All'}
                      onChange={() => {
                        handlePaidUnpaid('all')
                      }}
                    />
                    <Form.Check
                      inline
                      label='Paid'
                      name='group1'
                      type='radio'
                      id='type2'
                      key='2'
                      onChange={() => {
                        handlePaidUnpaid('Paid')
                      }}
                    />{' '}
                    <Form.Check
                      inline
                      label='Not Paid'
                      name='group1'
                      type='radio'
                      id='type3'
                      key='3'
                      onChange={() => {
                        handlePaidUnpaid('Not Paid')
                      }}
                    />{' '}
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          <Row className='justify-content-center'>
            {/* <Form.Label column sm={1}>
              From
            </Form.Label> */}
            <Col sm={2}>
              <Form.Control
                type='date'
                onChange={(e) => {
                  setFromDate(e.target.value)
                }}
              />
            </Col>
            {/* <Form.Label column sm={1}>
              To
            </Form.Label> */}
            <Col sm={2}>
              <Form.Control
                type='date'
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value)
                }}
              />
            </Col>
            <Button
              className='my-button'
              style={{
                padding: '4px',
                borderRadius: '30px',
                margin: '3px',
                width: '100px',
              }}
              onClick={handleDateFilter}
            >
              Go
            </Button>
          </Row>
          {clickedButton && clickedButton !== 'All Customers' && (
            <Row className='justify-content-center'>
              <Col sm={2}>
                <Form.Control
                  style={{ maxWidth: '300px' }}
                  className='mb-2 mt-1'
                  type='text'
                  placeholder='Customer Name'
                  onChange={(e) => {
                    setFilteredBatches(
                      allBatches.filter(
                        (batch) =>
                          batch.customerName &&
                          batch.customerName
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                      )
                    )
                  }}
                />
              </Col>
              <Col sm={2}>
                <Form.Control
                  style={{ maxWidth: '300px' }}
                  className='mb-2 mt-1'
                  type='text'
                  placeholder='Customer Email'
                  onChange={(e) => {
                    setFilteredBatches(
                      allBatches.filter((batch) =>
                        batch.custId
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    )
                  }}
                />
              </Col>
              <Col sm={2}>
                <Form.Control
                  style={{ maxWidth: '300px' }}
                  className='mb-2 mt-1'
                  type='text'
                  placeholder='Order Number'
                  onChange={(e) => {
                    setFilteredBatches(
                      allBatches.filter((batch) =>
                        batch.batchName
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    )
                  }}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchFilters

import { Container, Row, Col } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
import './Admin.css'
import SearchFilters from './SearchFilters'
import LeftNav from './LeftNav'
import AllOrders from './AllOrders'
import AllCustomers from './AllCustomers'
import DashBoard from './DashBoard'
import InProgress from './InProgress'
import Deleted from './Deleted'
import Posted from './Posted'
import Arrived from './Arrived'
import InLab from './InLab'
import ResultsReady from './ResultsReady'
import Completed from './Completed'
import { useAuth } from '../../config/AuthContext'
import Login from '../Login'
import KitsWanted from './KitsWanted'
import CertsWanted from './CertsWanted'
import { useState } from 'react'
//import PDFCertificate from './PDFCertificate'
import { useOrder } from '../../config/OrderContext'
import CCCerts from './CCCerts'
import UpdatePrices from './UpdatePrices'
import PbfdOrders from './PBFDReport'
import APVOrders from './APVReport'

const AdminNew = () => {
  const { clickedButton } = useAdmin()
  const { currentUser } = useAuth()
  const { customer } = useOrder()
  const [disableButton, setDisableButton] = useState(true)
  // const [showNav, setShowNav] = useState(true)

  return (
    <>
      {!currentUser ? (
        <Login page={'/Admin'} />
      ) : (currentUser && currentUser.email === 'cgouthamk@gmail.com') ||
        (customer && customer.role === 'Admin') ||
        currentUser.email === 'avigenics@gmail.com' ||
        currentUser.email === 'eezeetopup@gmail.com' ? (
        <>
          <Container fluid style={{ marginTop: '40px' }}>
            <Row>
              <Col>
                <h1 className='text-center'>Avigenics Admin</h1>
              </Col>
              {/* <Col>
                <Button
                  className='my-button'
                  style={{ width: '300px' }}
                  onClick={() => setShowNav(!showNav)}
                >
                  {showNav ? 'Hide Navigation' : 'Show Navigation'}
                </Button>
              </Col> */}
              {clickedButton !== 'All Customers' && <SearchFilters />}
            </Row>
          </Container>
          <Container fluid style={{ marginTop: '5px' }}>
            <Row>
              {/* {showNav && (
                <Col sm={2}>
                  <LeftNav />
                </Col>
              )} */}
              <Col sm={2}>
                <LeftNav />
              </Col>{' '}
              <Col sm={10}>
                {!clickedButton && <DashBoard />}
                {clickedButton === 'All Customers' && <AllCustomers />}
                {clickedButton === 'All Orders' && <AllOrders />}
                {clickedButton === 'In Progress' && <InProgress />}
                {clickedButton === 'Delete' && <Deleted />}
                {clickedButton === 'Posted' && <Posted />}
                {clickedButton === 'Arrived' && (
                  <Arrived
                    disableButton={disableButton}
                    setDisableButton={setDisableButton}
                  />
                )}
                {clickedButton === 'In Lab' && <InLab />}
                {clickedButton === 'Results Ready' && <ResultsReady />}
                {clickedButton === 'Completed' && <Completed />}
                {clickedButton === 'Kits Wanted' && <KitsWanted />}
                {clickedButton === 'Certs Wanted' && <CertsWanted />}
                {clickedButton === 'Credit Card' && <CCCerts />}
                {clickedButton === 'Update Prices' && <UpdatePrices />}
                {clickedButton === 'PBFD Orders' && <PbfdOrders />}
                {clickedButton === 'APV Orders' && <APVOrders />}
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <Container fluid style={{ marginTop: '40px' }}>
          <Row>
            <h1 className='text-center'>
              Only Administrators are allowed here
            </h1>
          </Row>
        </Container>
      )}
    </>
  )
}

export default AdminNew

/* eslint-disable react-hooks/exhaustive-deps */

// External Imports
import { useState, useEffect } from 'react'
import { Button, Modal, Alert, Container, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

// Context Imports
import { useGlobal } from '../config/GlobalContext'
import { useOrder } from '../config/OrderContext'

// Components Imports
import Login from './Login'
import CompletedOrders from './CompletedOrders'
import DeletedOrders from './DeletedOrders'
import OrdersInProgress from './OrdersInProgress'
import OrdersTobePosted from './OrdersTobePosted'
import NewOrder from './NewOrder'
import ShowTips from './ShowTips'
import CurrentPrices from './CurrentPrices'

const OrderNow = () => {
  // Global Variables and Functions
  const { customer, currentUser, getAllOrders, getCustomer } = useOrder()
  const { setButtonDisable, buttonDisable } = useGlobal()

  // Local Variables
  const [message, setMessage] = useState('')
  const [showNewOrder, setShowNewOrder] = useState(false)
  const [clickedButton, setClickedButton] = useState()
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [showBulk, setShowBulk] = useState(false)
  const [imgLoaded, setImgLoaded] = useState(false)
  const history = useHistory()

  useEffect(() => {
    getCustomer()
    currentUser ? getAllOrders() : history.push('/Login')
    setTimeout(() => {
      setImgLoaded(true)
    }, 1500)
  }, [])

  return (
    <>
      {!currentUser ? (
        <Login page={'/OrderNow'} />
      ) : (
        <>
          {!imgLoaded ? (
            <Container fluid>
              <Spinner
                animation='border'
                variant='primary'
                size='100px'
                style={{
                  marginTop: '100px',
                  width: '2rem',
                  height: '2rem',
                  marginLeft: '50%',
                }}
              />
            </Container>
          ) : (
            <>
              <section
                className='mission-section clearfix'
                style={{ padding: '2px', margin: '5px' }}
              >
                <div className='auto-container'>
                  <div className='row clearfix'>
                    <div
                      className='content-column col-lg-6 col-md-12 col-sm-12'
                      style={{ margin: '0px', marginTop: '40px' }}
                    >
                      <div className='inner-column'>
                        <div className='sec-title'>
                          <h2 className='title'>Create new Order</h2>
                        </div>
                        <div className='bold-text'>
                          <Button
                            style={{
                              padding: '4px',
                              margin: '3px',
                            }}
                            variant='success'
                            disabled={buttonDisable}
                            onClick={() => {
                              setShowNewOrder(true)
                              setButtonDisable(true)
                              setClickedButton('dna')
                            }}
                          >
                            {' '}
                            DNA Sexing{' '}
                          </Button>{' '}
                          <Button
                            style={{
                              padding: '4px',
                              margin: '3px',
                            }}
                            variant='success'
                            disabled={buttonDisable}
                            onClick={() => {
                              setShowNewOrder(true)
                              setButtonDisable(true)
                              setClickedButton('pbfd')
                              alert(
                                'For PBFD or APV tests, we recommend you to send both Blood and feather samples for more accurate results'
                              )
                            }}
                          >
                            {' '}
                            PBFD Testing{' '}
                          </Button>{' '}
                          <Button
                            style={{
                              padding: '4px',
                              margin: '3px',
                            }}
                            variant='success'
                            disabled={buttonDisable}
                            onClick={() => {
                              setShowNewOrder(true)
                              setButtonDisable(true)
                              setClickedButton('apv')
                              alert(
                                'For PBFD or APV tests, we recommend you to send both Blood and feather samples for more accurate results'
                              )
                            }}
                          >
                            {' '}
                            APV Testing{' '}
                          </Button>
                          <br />
                          <h6>
                            If you find difficulty in placing order online,
                            please send the details to us and we can create
                            order for you.
                            <br />
                            Otherwise, please contact our office on{' '}
                            <a href='tel:+13133389022'>+ 1 (313) 338-9022</a>
                          </h6>
                          {customer &&
                            (!customer.phone || !customer.pincode) && (
                              <Alert variant='danger'>
                                <span className='blink_me'>
                                  Your profile is incomplete.{' '}
                                </span>
                                Please update{' '}
                                <Link to={'/MyAccount'}>here</Link>
                              </Alert>
                            )}
                        </div>
                      </div>
                    </div>
                    <CurrentPrices />
                  </div>
                </div>
              </section>

              <section
                className='mission-section clearfix'
                style={{ padding: '2px', margin: '5px' }}
              >
                <div className='auto-container'>
                  <div className='row clearfix'>
                    <strong>
                      {customer &&
                        customer.name &&
                        `${customer.name}, Your orders are here:`}
                    </strong>
                    <br />
                  </div>
                </div>
              </section>
              <section
                className='mission-section clearfix'
                style={{ padding: '2px', margin: '5px' }}
              >
                <div className='auto-container'>
                  <div className='row clearfix'>
                    <OrdersTobePosted />
                    <ShowTips />
                  </div>
                </div>
              </section>
              <section
                className='mission-section clearfix'
                style={{ padding: '2px', margin: '5px' }}
              >
                <OrdersInProgress />
                <CompletedOrders />
                <DeletedOrders />
              </section>
              <section
                className='mission-section clearfix'
                style={{ padding: '2px', margin: '5px' }}
              >
                {' '}
                <h6>
                  Please contact our Support team if you need any further
                  details about your order
                </h6>
              </section>
            </>
          )}
        </>
      )}
      <Modal
        backdrop='static'
        keyboard={false}
        show={showNewOrder}
        onHide={() => {
          getCustomer()
          setConfirmDialog(true)
          setMessage(
            'If you close, changes will not be saved. Click on submit button to save the order'
          )
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Details </Modal.Title>{' '}
        </Modal.Header>
        <Modal.Body>
          <NewOrder
            custId={currentUser && currentUser.email}
            clickedButton={clickedButton}
            setShowNewOrder={setShowNewOrder}
            page={'Order'}
          />
        </Modal.Body>
      </Modal>{' '}
      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-80w'
        style={{ marginTop: '100px' }}
        show={
          message ===
          'If you close, changes will not be saved. Click on submit button to save the order'
        }
        onHide={() => setMessage('')}
      >
        <Modal.Body>
          {' '}
          <Alert variant='warning' onClose={() => setMessage('')}>
            If you select Close button, your changes will not be saved. Click on
            Cancel to return to order page.
            {confirmDialog && (
              <>
                {' '}
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                <Button
                  style={{
                    padding: '1px',
                    borderRadius: '30px',
                    margin: '1px',
                    width: '60px',
                  }}
                  variant='danger'
                  onClick={() => {
                    setMessage()
                    setShowNewOrder(false)
                    setButtonDisable(false)
                    setConfirmDialog(false)
                  }}
                >
                  Close
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  style={{
                    padding: '1px',
                    borderRadius: '30px',
                    margin: '1px',
                    width: '60px',
                  }}
                  onClick={() => {
                    setMessage()
                    setConfirmDialog(false)
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </Alert>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showBulk}
        onHide={() => {
          setShowBulk(false)
        }}
      ></Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showBulk}
        onHide={() => {
          setShowBulk(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>You can purchage DNA tests in bundles</Modal.Title>{' '}
        </Modal.Header>
        <Modal.Body>
          <h6>
            Test bundles can only be purchased by phone at this moment.
            <br />
            Sample kits will be posted for free. Please select "Please send me
            sample collection Kit" on the order page as usual.
            <br />
          </h6>
          <h6 className='blink_me2'>
            Phone Number to call: + 1 (313) 338-9022{' '}
          </h6>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OrderNow

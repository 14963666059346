import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

const Checkout = () => {
  const [message, setMessage] = useState()
  const history = useHistory()

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    if (query.get('success')) {
      setMessage(
        'Your payment was successful! You will receive an email confirmation.'
      )
    }

    if (query.get('cancelled')) {
      setMessage(
        'Payment cancelled -- Please try again later. If you need assistance, please contact our office.'
      )
    }
  }, [])

  return (
    <>
      {message && (
        <>
          {message ===
          'Your payment was successful! You will receive an email confirmation.'
            ? toast.success(message)
            : toast.error(message)}
          {history.replace('/')}
        </>
      )}
    </>
  )
}

export default Checkout

import { useState } from 'react'
import { Accordion, Card, Button, ListGroup } from 'react-bootstrap'
import { useOrder } from '../config/OrderContext'

const DeletedOrders = () => {
  const [selectedOrder, setSelectedOrder] = useState()
  const { allBatches } = useOrder()
  const allOrders = allBatches
  const orderToShow =
    allOrders && allOrders.filter((batch) => batch.status === 'Delete')

  const orderToShowHandler = (id) => {
    setSelectedOrder(orderToShow.filter((batch) => batch.id === id)[0])
  }

  return (
    <>
      <div className='auto-container'>
        <div className='row clearfix'>
          <div className='content-column col-lg-6 col-md-12 col-sm-12'>
            <div className='inner-column'>
              <div className='sec-title'>
                <div className='title'>Incomplete/Cancelled Orders</div>
              </div>
              <Accordion defaultActiveKey='0'>
                {orderToShow && orderToShow.length > 0 ? (
                  orderToShow.map((batch, index) => (
                    <Card key={index}>
                      <Card.Header style={{ padding: '0px' }}>
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          onClick={() => orderToShowHandler(batch.id)}
                          eventKey={`'${index}'`}
                        >
                          {batch.batchName}
                        </Accordion.Toggle>
                        {' ~ '}
                        {batch.payStatus === 'Not Paid'
                          ? '  Order Not Paid  '
                          : '  Order Paid'}
                      </Card.Header>
                      <Accordion.Collapse eventKey={`'${index}'`}>
                        <Card.Body>
                          {' '}
                          <ListGroup size='sm'>
                            {selectedOrder &&
                              selectedOrder.samples.map((sample, index) => (
                                <ListGroup.Item key={index}>
                                  {index + 1} &nbsp;&nbsp;&nbsp;{' '}
                                  &nbsp;&nbsp;&nbsp; {sample.ringNo}
                                  &nbsp;&nbsp;&nbsp; {sample.selectedSpecies}
                                </ListGroup.Item>
                              ))}
                          </ListGroup>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))
                ) : (
                  <div style={{ color: 'red' }}>No Orders to show</div>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeletedOrders

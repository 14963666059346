/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { toast } from 'react-toastify'
import { useGlobal } from './GlobalContext'
import { useAuth } from './AuthContext'

const AdminContext = React.createContext()

export const useAdmin = () => {
  return useContext(AdminContext)
}

const AdminProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const Batches = firebase.firestore().collection('Batches')
  const Customers = firebase.firestore().collection('Customers')
  const [allBatches, setAllBatches] = useState()
  const [allCustomers, setAllCustomers] = useState()
  const [filteredCustomers, setFilteredCustomers] = useState()
  const [size, setSize] = useState(50)
  const [totalSamples, setTotalSamples] = useState()
  const [filteredBatches, setFilteredBatches] = useState()
  const [filterEnabled, setFilterEnabled] = useState(false)
  const [totalOrders, setTotalOrders] = useState(0)
  const curr = new Date()
  curr.setDate(curr.getDate())
  const date = curr.toISOString().split('T')[0]
  const today = date
  const [toDate, setToDate] = useState(today)
  const [fromDate, setFromDate] = useState()
  const [clickedButton, setClickedButton] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [selectedBatch, setSelectedBatch] = useState()
  const [duplicateCustomers, setDuplicateCustomers] = useState()
  const [duplicateOrders, setDuplicateOrders] = useState()
  const [kitsWanted, setKitsWanted] = useState()
  const [certsWanted, setCertsWanted] = useState()
  const randomstring = require('randomstring')
  const [duplicateSamples, setDuplicateSamples] = useState()
  const [error, setError] = useState()
  const [showAllCerts, setShowAllCerts] = useState(false)
  // const [nameToDelete, setNameToDelete] = useState()
  const { currentUser } = useAuth()

  const { findOrderCost } = useGlobal()
  let total = 0
  const lastSavedBy = currentUser ? currentUser.email : 'I dont Know'
  // const [currentUser, setCurrentUser] = useState()

  useEffect(() => {
    getAllOrders()
    getCustomers()
  }, [])
  const getAllOrders = async () => {
    if (size !== 500) {
      await Batches.orderBy('batchName', 'desc')
        .limit(5000)
        .onSnapshot(
          async (querySnapshot) => {
            let rcvdBatches = []
            total = 0
            let nameToDel = []
            querySnapshot.forEach((doc) => {
              rcvdBatches.push({ id: doc.id, ...doc.data() })
              total +=
                doc.data().samples &&
                doc.data().samples.length > 0 &&
                doc.data().samples.length + 0
              doc.data().name && nameToDel.push({ id: doc.id, ...doc.data() })
            })
            await setAllBatches(rcvdBatches)
            // clickedButton !== 'All Orders' && setFilteredBatches(rcvdBatches)
            await setTotalSamples(total)
            await setTotalOrders(rcvdBatches.length)
            // await setNameToDelete(nameToDel)
            findDuplicateOrders(rcvdBatches)
            setKitsWanted(
              rcvdBatches.filter(
                (batch) => batch.kitsNeeded && batch.kitsNeeded.type
              )
            )
            setCertsWanted(
              rcvdBatches.filter(
                (batch) => batch.certsNeeded && batch.certsNeeded.type
              )
            )
            await setLoading(false)
          },
          (err) => {
            setError(err.message)
            // console.log('ERROR SNAP: ', err)
          }
        )
    } else {
      await Batches.orderBy('batchName', 'desc').onSnapshot(
        (querySnapshot) => {
          let rcvdBatches = []
          total = 0
          querySnapshot.forEach((doc) => {
            rcvdBatches.push({ id: doc.id, ...doc.data() })
            total += doc.data().samples.length + 0
          })
          setAllBatches(rcvdBatches)
          // setFilteredBatches(rcvdBatches)
          setTotalSamples(total)
          setTotalOrders(rcvdBatches.length)
        },
        (err) => {
          setError(err.message)
          // console.log('ERROR SNAP: ', err)
        }
      )
    }
  }
  // const delay = (ms) => new Promise((res) => setTimeout(res, ms))
  const getCustomers = async () => {
    await Customers.orderBy('name').onSnapshot(
      (querySnapshot) => {
        let rcvdCustomers = []
        querySnapshot.forEach((doc) => {
          rcvdCustomers.push({ id: doc.id, ...doc.data() })
        })
        setAllCustomers(rcvdCustomers)
        setFilteredCustomers(rcvdCustomers)
        findDuplicateCustomers(rcvdCustomers)
      },
      (err) => {
        setError(err.message)
        // console.log('ERROR SNAP: ', err)
      }
    )
    setLoading(false)
  }

  const deleteBatch = async (batchId) => {
    if (window.confirm(`Are you sure, you want to delete this order?`)) {
      try {
        await Batches.doc(batchId).delete()
        toast('The selected Order is deleted!')
      } catch (error) {
        toast(
          'There was an error! Please try again or contact our support staff'
        )
      }
    }
  }
  const findFiltered = async (filter) => {
    setLoading(true)
    total = 0
    let tempBatches = {}
    if (filter !== 'All') {
      tempBatches = await allBatches.filter((batch) => batch.status === filter)
      setFilterEnabled(true)
    } else {
      tempBatches = await allBatches
      setFilterEnabled(true)
    }
    setFilteredBatches(tempBatches)
    tempBatches &&
      tempBatches.map((batch) => (total += batch.samples.length + 0))
    setTotalSamples(total)
    setTotalOrders(tempBatches && tempBatches.length)
    setLoading(false)
  }
  const updateWithCustName = async (id, name1) => {
    await Batches.doc(id).update({ customerName: name1 }, { merge: true })
  }
  const updateWithOrderCost = async (id, orderCost) => {
    await Batches.doc(id).update({ orderCost }, { merge: true })
  }
  const handleDateFilter = () => {
    if (fromDate === undefined || toDate === undefined) {
      alert('Please select Date before running report')
    } else {
      const batchFrom = fromDate.toString().replaceAll('-', '') + '0000'
      const batchTo = toDate.toString().replaceAll('-', '') + '2359'
      const tempBatches = filteredBatches.filter(
        (batch) => batch.batchName >= batchFrom && batch.batchName <= batchTo
      )
      setFilteredBatches(tempBatches)
      setFilterEnabled(true)

      let total = 0
      tempBatches.map((batch) => (total += batch.samples.length + 0))
      setTotalOrders(tempBatches.length)
      setTotalSamples(total)
    }
  }
  const handlePaidUnpaid = (status) => {
    let paidUnpaidBatch = {}
    if (clickedButton !== 'All Orders') {
      paidUnpaidBatch =
        status !== 'all'
          ? allBatches.filter(
              (batch) =>
                batch.payStatus === status && batch.status === clickedButton
            )
          : allBatches.filter((batch) => batch.status === clickedButton)
    } else {
      paidUnpaidBatch =
        status !== 'all'
          ? allBatches.filter((batch) => batch.payStatus === status)
          : allBatches
    }

    setFilteredBatches(paidUnpaidBatch)
    let total = 0
    paidUnpaidBatch.map((batch) => (total += batch.samples.length + 0))
    setTotalOrders(paidUnpaidBatch.length)
    setTotalSamples(total)
  }

  const getCustomer = (email) => {
    const customer =
      allCustomers &&
      allCustomers.filter((customer) => customer.email === email)[0]
    setSelectedCustomer(customer)
    return customer
  }

  const getBatch = (id) => {
    setSelectedBatch(
      allBatches && allBatches.filter((batch) => batch.id === id)[0]
    )
  }

  const updateOrderCost = (batch) => {
    let cost = 0
    const customer = getCustomer(batch.custId)
    cost = findOrderCost(batch, customer)
    updateWithOrderCost(batch.id, cost)
    setSelectedBatch((prev) => ({ ...prev, orderCost: cost }))
  }
  const saveCustomer = async (customer) => {
    // const dupEmail = allCustomers.filter(
    //   (cust) => cust.email === customer.email
    // )
    // alert(JSON.stringify(dupEmail))
    if (customer.id) {
      await Customers.doc(customer.id).update({ ...customer }, { merge: true })
      allBatches.forEach(async (batch) => {
        batch.custId === customer.email &&
          (await Batches.doc(batch.id).update(
            {
              customerName: customer.name,
              lastSavedBy,
            },
            { merge: true }
          ))
      })
    } else {
      await Customers.add({ ...customer, lastSavedBy })
    }
  }

  const statusUpdate = async (id, status) => {
    try {
      await Batches.doc(id).update({ status, lastSavedBy }, { merge: true })
    } catch (error) {
      console.log(error)
    }
  }

  const updatePayStatus = async (id, payNotes, payType) => {
    let payStatus = ''
    payType !== 'Not Paid' ? (payStatus = 'Paid') : (payStatus = 'Not Paid')
    try {
      await Batches.doc(id).update(
        { payStatus, payNotes, payType, lastSavedBy },
        { merge: true }
      )
    } catch (error) {
      alert(
        'Something went wrong. Contact developer with error message for more info'
      )
    }
  }

  const findDuplicateCustomers = (allCustomers) => {
    const lookup =
      allCustomers &&
      allCustomers.reduce((a, e) => {
        a[e.email] = ++a[e.email] || 0
        return a
      }, {})

    setDuplicateCustomers(
      allCustomers && allCustomers.filter((e) => lookup[e.email])
    )
  }

  const findDuplicateOrders = (allBatches) => {
    const lookup =
      allBatches &&
      allBatches.reduce((a, e) => {
        a[e.batchName] = ++a[e.batchName] || 0
        return a
      }, {})

    setDuplicateOrders(
      allBatches && allBatches.filter((e) => lookup[e.batchName])
    )
  }
  const kitsSent = async (id, kitsNeeded) => {
    try {
      await Batches.doc(id).update(
        {
          kitsNeeded: { ...kitsNeeded, sent: 'Sent' },
          kitsSentOn: today,
          lastSavedBy,
        },
        { merge: true }
      )
    } catch (error) {
      console.log(error)
    }
  }
  const certsSent = async (id, certsNeeded) => {
    try {
      await Batches.doc(id).update(
        {
          certsNeeded: { ...certsNeeded, sent: 'Sent' },
          certsSentOn: today,
          lastSavedBy,
        },
        { merge: true }
      )
    } catch (error) {
      console.log(error)
    }
  }
  const setArrivedDate = async (batch) => {
    try {
      await Batches.doc(batch.id).update(
        { arrDate: today, lastSavedBy },
        { merge: true }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const updateBatch = async (id) => {
    if (selectedBatch && selectedBatch.status === 'Results Ready') {
      try {
        await Batches.doc(id).update(
          {
            ...selectedBatch,
            resultsUpdated: lastSavedBy,
          },
          { merge: true }
        )
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        await Batches.doc(id).update(
          {
            ...selectedBatch,
            lastSavedBy,
          },
          { merge: true }
        )
      } catch (error) {
        console.log(error)
      }
    }
  }
  const updateURL = async (batch, cert, sampleIndex) => {
    batch.samples[sampleIndex] = cert
    await Batches.doc(batch.id).update(
      { ...batch, certsSaved: lastSavedBy },
      { merge: true }
    )

    if (sampleIndex + 1 === batch.samples.length) {
      alert(
        `${
          sampleIndex + 1
        } Certificates Saved! Now you can close the Certificate Screen`
      )
      setShowAllCerts(false)
      setLoading(false)
    }
  }
  const updateArrived = (labBatch) => {
    try {
      filteredBatches.forEach(async (batch) => {
        batch.samples.forEach((sample) => {
          const generatedCert = `${labBatch}-${
            randomstring.generate({
              length: 3,
              charset: 'numeric',
            }) +
            randomstring
              .generate({
                length: 1,
                charset: 'alphabetic',
              })
              .toUpperCase()
          }-${
            randomstring
              .generate({
                length: 2,
                charset: 'alphabetic',
              })
              .toUpperCase() +
            randomstring.generate({
              length: 3,
              charset: 'numeric',
            })
          }`
          sample.certificate = generatedCert
        })
        await Batches.doc(batch.id).update({ ...batch, labBatch })
      })
    } catch (error) {
      console.log(error)
    }
  }
  const updateResultDate = async (batch) => {
    try {
      batch.samples.forEach((sample) => {
        sample.Resultdate = toDate
      })
      await Batches.doc(batch.id).update({
        ...batch,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const findDuplicateSamples = (batch) => {
    const samples = batch.samples
    const lookup =
      samples &&
      samples.reduce((a, e) => {
        a[e.ringNo + e.selectedSpecies] = ++a[e.ringNo + e.selectedSpecies] || 0
        return a
      }, {})
    setDuplicateSamples(
      samples && samples.filter((e) => lookup[e.ringNo + e.selectedSpecies])
    )
  }

  const value = {
    allBatches,
    allCustomers,
    clickedButton,
    deleteBatch,
    filterEnabled,
    filteredBatches,
    filteredCustomers,
    findFiltered,
    fromDate,
    getAllOrders,
    getBatch,
    getCustomer,
    handleDateFilter,
    handlePaidUnpaid,
    loading,
    saveCustomer,
    selectedCustomer,
    setClickedButton,
    setFilterEnabled,
    setFilteredBatches,
    setFilteredCustomers,
    setFromDate,
    setLoading,
    setSelectedCustomer,
    setSize,
    setToDate,
    setTotalOrders,
    setTotalSamples,
    size,
    statusUpdate,
    toDate,
    totalOrders,
    totalSamples,
    updateWithCustName,
    updatePayStatus,
    selectedBatch,
    setSelectedBatch,
    findDuplicateCustomers,
    findDuplicateOrders,
    duplicateOrders,
    setDuplicateOrders,
    duplicateCustomers,
    setDuplicateCustomers,
    kitsSent,
    kitsWanted,
    certsSent,
    certsWanted,
    setArrivedDate,
    updateBatch,
    updateURL,
    updateArrived,
    duplicateSamples,
    findDuplicateSamples,
    updateResultDate,
    updateWithOrderCost,
    updateOrderCost,
    error,
    showAllCerts,
    setShowAllCerts,
  }
  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}

export default AdminProvider

import { useEffect } from 'react'
import { useAdmin } from '../../config/AdminContext'
import ComponentToShow from './ComponentToShow'
const Posted = () => {
  const { findFiltered, allBatches } = useAdmin()
  useEffect(() => {
    findFiltered('Posted')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBatches])

  return <ComponentToShow prev={'In Progress'} next={'Arrived'} />
}

export default Posted

const FeatherSample = () => {
  return (
    <>
      {/* <!-- Page Title --> */}
      {/* <section className='page_breadcrumb'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <ol className='page_bread'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='current_page'>Feather Sample</li>
              </ol>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End Page Title --> */}

      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/feather.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  {/* <div className='title'>AVIGENICS</div> */}
                  <h2>Feather Sample</h2>
                </div>
                <div className='bold-text'>
                  Select medium sized feathers for plucking. The feather must be
                  physically plucked (molted feathers cannot be used for this
                  test). Very small feathers are not recommended. Pluck 4-6
                  medium sized feathers from the chest area of the bird and
                  place them in a self sealing bag. Ensure the feather tip,
                  which is attached to the skin, is present (white bulb). For
                  detailed instructions or further information write to{' '}
                  <a href='mailto:info@avigenicsusa.com'>
                    info@avigenicsusa.com
                  </a>{' '}
                  or call us on{' '}
                  <a href='tel:+13133389022'>+ 1 (313) 338-9022</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Three --> */}
    </>
  )
}

export default FeatherSample

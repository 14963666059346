const HowTo = () => {
  return (
    <div>
      <div className='page-wrapper'>
        {/* <!-- Page Title --> */}
        <section className='page_breadcrumb'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <ol className='page_bread'>
                  <li>
                    <a href='/'>Home</a>
                  </li>
                  <li className='current_page'>How to Send Samples</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Page Title --> */}

        {/* <!-- Mission Section --> */}
        <section className='mission-section clearfix'>
          <div className='auto-container'>
            <div className='row clearfix'>
              {/* <!-- Image Column --> */}
              <div className='image-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  <div
                    className='image wow fadeInLeft'
                    data-wow-delay='0ms'
                    data-wow-duration='1500ms'
                  >
                    <img
                      src='images/resource/services3.jpg'
                      alt=''
                      style={{
                        borderRadius: '15px',
                        boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* <!-- Content Column --> */}
              <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <div className='title'>WHO WE ARE</div>
                    <h2>AVIGENICS</h2>
                  </div>
                  <div className='bold-text'>
                    Avigenics is the UK's leading avian laboratory and is
                    excited to announce the opening of a new branch in
                   USA . We are a bunch of young professional experts in
                    bird sexing with the use of feather or blood samples. In our
                    specialised laboratories, we offer DNA, PBFD and APV
                    testing. Avigenics is committed to the highest level of
                    service in the avian DNA testing industry due to the
                    accuracy & unparalleled trust-worthiness and high-quality
                    technology used.{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Services Section Three --> */}

        {/* <!-- Mission Section --> */}
        <section className='mission-section clearfix'>
          <div className='auto-container'>
            <div className='row clearfix'>
              {/* <!-- Content Column --> */}
              <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <h2>Quality without compromise</h2>
                  </div>
                  <div className='bold-text'>
                    As a leading DNA testing firm, Avigenics guarantees the
                    highest level of quality service in testing. Our team
                    consists of highly skilled, young DNA professionals who are
                    focussed on offering a friendly customer service. We perform
                    quality driven DNA tests based on scientifically tested
                    methods.{' '}
                  </div>
                  <ul className='mission-list'>
                    <h6 className='text-dark'>
                      <b>
                        In order to define your bird’s sex, you can send us any
                        of the following:
                      </b>
                    </h6>
                    <br />
                    <li>
                      <span className='icon'>
                        <img src='images/icons/mission-1.png' alt='' />
                      </span>
                      Blood Samples
                    </li>
                    <li>
                      <span className='icon'>
                        <img src='images/icons/mission-2.png' alt='' />
                      </span>
                      Feather Samples
                    </li>
                    <li>
                      <span className='icon'>
                        <img src='images/icons/mission-3.png' alt='' />
                      </span>
                      Eggshell Samples
                    </li>
                  </ul>
                </div>
              </div>

              {/* <!-- Image Column --> */}
              <div className='image-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  <div
                    className='image wow fadeInLeft'
                    data-wow-delay='0ms'
                    data-wow-duration='1500ms'
                  >
                    <img src='images/resource/mission.png' alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Services Section Three --> */}

        {/* <!-- Steps Section --> */}
        <section
          className='steps-section'
          style={{ backgroundImage: 'url(images/background/pattern-14.png)' }}
        >
          <div className='auto-container'>
            {/* <!-- Sec Title --> */}
            <div className='sec-title centered'>
              {/* <!-- <div className="title">HOW WE DO IT</div> --> */}
              <h2>HOW WE DO IT</h2>
            </div>

            <div className='row clearfix'>
              {/* <!-- Step Block --> */}
              <div className='step-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='icon-box'>
                    <div className='number'>
                      <img
                        alt=''
                        src='../images/background/feather1.png'
                        width='60%'
                      />
                    </div>
                  </div>
                  <h6>Sample Collection</h6>
                </div>
              </div>

              {/* <!-- Step Block --> */}
              <div className='step-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='icon-box'>
                    <div className='number'>
                      <img
                        alt=''
                        src='../images/background/title-icon1.png'
                        width='60%'
                      />
                    </div>
                  </div>
                  <h6>Extract DNA</h6>
                </div>
              </div>

              {/* <!-- Step Block --> */}
              <div className='step-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='icon-box'>
                    <div className='number'>
                      <img
                        src='../images/background/tut.png'
                        width='80%'
                        alt=''
                      />
                    </div>
                  </div>
                  <h6>Lab Analysis</h6>
                </div>
              </div>

              {/* <!-- Step Block --> */}
              <div className='step-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='icon-box'>
                    <div className='number'>
                      <img
                        src='../images/background/report.png'
                        width='50%'
                        alt=''
                      />
                    </div>
                  </div>
                  <h6>DNA Report</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Steps Section --> */}

        {/* <!-- Testimonial Section Two --> */}
        <section className='testimonial-section-two'>
          <div
            className='pattern-layer'
            style={{ backgroundImage: 'url(images/background/pattern-10.png)' }}
          ></div>
          <div className='auto-container'>
            {/* <!-- Sec Title --> */}
            <div className='sec-title centered'>
              <div className='title'>Testimonials</div>
              <h2>
                What others say about us? <br />
              </h2>
              <div className='text'>Coming soon...</div>
            </div>
          </div>
        </section>
        {/* <!-- End Testimonial Section Two --> */}
      </div>
      {/* <!--End pagewrapper--> */}

      {/* <!-- Scroll To Top --> */}
      <div
        className='back-to-top scroll-to-target show-back-to-top'
        data-target='html'
      >
        TOP
      </div>
    </div>
  )
}

export default HowTo

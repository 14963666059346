import { useEffect } from 'react'

const PBFD = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {/* <!-- Page Title --> */}
      <section className='page_breadcrumb'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <ol className='page_bread'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li className='current_page'>PBFD</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/pbfd.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  <div className='title'>AVIGENICS</div>
                  <h2>Psittacine Beak Feather Disease (PBFD) Testing</h2>
                </div>
                <div className='bold-text'>
                  {/* Avigenics offers genetic testing for Psittacine Circovirus:
                  Type 1 and Type 2. We also offer Elisa testing to determine if
                  the bird has developed an immunity to the virus that causes
                  PBFD.{' '} */}
                </div>
                <div className='bold-text'>
                  Psittacine Beak Feather Disease (PBFD) causes fatal infections
                  in young birds,primarily. Older birds can overcome the disease
                  with few long-lasting effects. These surviving birds will
                  become carriers that will shed the disease at a later date.{' '}
                </div>
                <div className='bold-text'>
                  The virus that causes PBFD can also affect the liver, brain,
                  and immune system of the affected bird causing reduced
                  resistance to infections.{' '}
                </div>
                <div className='bold-text'>
                  PBFD diagnosis is done by analysing the skin of feather and
                  shaft, or PCR testing of blood and feather samples. Also,
                  testing for PBFD-specific antibodies can reveal important
                  information about a possible infection.
                </div>
              </div>
            </div>
          </div>{' '}
          <h5>
            *** For more accuracy of the results we recommend to send both Blood
            and Feather Samples for this test ***
          </h5>
        </div>
      </section>
      {/* <!-- End Services Section Three --> */}

      {/* <!-- Mission Section --> */}

      {/* <!-- End Services Section Three --> */}
    </>
  )
}

export default PBFD

import axios from 'axios'
import 'firebase/firestore'

const SendMail = async (
  custName,
  custEmail,
  order,
  subject,
  templateNo,
  samples,
  total,
  config
) => {
  const orderNo = order && order.batchName ? order.batchName : order
  const samplesList =
    (await samples) &&
    samples.length > 0 &&
    samples.map((sample) => {
      return `<li>${sample.ringNo} - ${sample.selectedSpecies} - Test: ${sample.testType} </li>`
    })

  const postage = config && config.privateCourier ? config.privateCourier : 3
  const jsxHtml =
    samples &&
    samples.length > 0 &&
    samples.map(
      (sample, idx) =>
        `<tr key=${idx}>
                        <td>${sample.ringNo}</td>
                        <td>${sample.selectedSpecies}</td>
                        <td>${sample.testType}</td>
                        <td>${
                          sample.ownerName ? sample.ownerName : custName
                        }</td>
                      </tr>`
    )

  const sasi = jsxHtml && jsxHtml.toString().replaceAll('>,<', '><')
  const teamMessageNewOrder = ` <div class='col-lg-6'>
        <div>
          
          <p>Hi Team,</p>
          <p>You have new order from ${custName} whose email is ${custEmail}. total samples: ${total} ${samplesList}</p>
          <br/>
          Order Cost: $${order && order.orderCost}<br/>
          <p>
            Please take a moment to review 
            <a href='https://avigenicsusa.com/Admin'>Admin Area </a><br/>
            Contact the user if needed and also check if the payment is made.<br/>
          </p>

                 </div>
      </div>`

  const teamMessageUpdateOrder = ` <div class='col-lg-6'>
        <div>
          
          <p>Hi Team,</p>
          <p>Order from ${custName} whose email is ${custEmail} just updated. total samples: ${total} ${samplesList}</p>
          <br/>
          Order Cost: $${order && order.orderCost}<br/>
          <p>
            Please take a moment to review 
            <a href='https://avigenicsusa.com/Admin'>Admin Area </a><br/>
            Contact the user if needed and also check if the payment is made.<br/>
          </p>

                 </div>
      </div>`
  const footerMessage = `          <p>
            Thanks & Regards,
            <br />
            Team Avigenics<br/><br/>
                      <img
            style='width: 150px;' height: 80px;' }}
            src='https://avigenicsusa.com/images/logo-6.png'
          />
          </p>
          <p>E-mail:   info@avigenicsusa.com  |   Phone:   (313) 338-9022 <br/>
            1562 Belvedere Ave,Okemos, MI 48864, USA. <br/>
            <a href='https://avigenicsusa.com/'>www.avigenicsusa.com</a><br/>
            The contents of this e-mail (including any attachments) may be the proprietary and confidential
          </p>`
  const thanksForOrder = `          <p>Thank you for your Order. <br/>Please write ring ID and species name on label. <br/>Once you have posted the samples, please click on Post and enter courier details.<br/>

          <h5>*** <br/>Please note: If you need sample collection kits, please request in Order page. <br/>
          Postal charges of $${postage} will be added. <br/>
          You may need to update your postal address in <a href='https://avigenicsusa.com/MyAccount'> My Account </a>section.<br/> ***</h5>
            Please take a moment to review our
            <a href='https://avigenicsusa.com/FAQ'>Frequently Asked Questions </a>section.<br/>
            You can see order details by clicking order number in Pending/orders in process secion at: <a href='https://avigenicsusa.com/OrderNow'> Order Now </a>section.<br/>
          </p>`
  const teamMessageDeletedOrder = ` <div class='col-lg-6'>
        <div>
          <p>Hi Team,</p>
          <p>Order from ${custName} whose email is ${custEmail} has just deleted.</p>
          <p>
            Please take a moment to review 
            <a href='https://avigenicsusa.com/Admin'>Admin Area </a><br/>
            Contact the user if needed and also check if the payment is made.<br/>
          </p>
        </div>
      </div>`
  const teamMessageKitsNeeded = ` <div class='col-lg-6'>
        <div>
          <p>Hi Team,</p>
          <p>Order from ${custName} whose email is ${custEmail} requires sample kits to send.</p>
          <p>
            Please take a moment to review 
            <a href='https://avigenicsusa.com/Admin'>Admin</a><br/>
            Contact the user if needed and also check if the payment is made.<br/>
          </p>
        </div>
      </div>`
  const textTemplate = [
    `      <div class='col-lg-6'>
        <div>
          
          <p>Dear ${custName ? custName : custEmail},</p>
        ${thanksForOrder && thanksForOrder}
        <p> Summary of order: <br/>
        Order No: ${orderNo}<br/> 
        Order Includes: <br/>
        Total samples: ${total}<br/>
        <br/>
        ${
          order && order.kitsNeeded
            ? `Sample Kits @ $${postage} postal charges`
            : ''
        }<br/>
        ${
          order && order.certsNeeded
            ? `DNA Certificates and @ $${postage} postal charges`
            : ''
        }
<br/>
Order Cost: $${order && order.orderCost}<br/>
        <table border responsive size='lg'>
          <thead>
            <tr>
              <th width='200px'>Ring ID</th>
              <th width='200px'>Species</th>
              <th width='200px'>Test Type</th> 
              <th width='200px'>Owner</th>            
            </tr>
          </thead><tbody>${sasi}</tbody>
        </table><br/>
     
        </p>
          ${footerMessage}
        </div>
        </div>`,
    teamMessageNewOrder,
    `      <div class='col-lg-6'>
        <div>
          
          <p>Dear ${custName ? custName : custEmail},</p>
          <p>Your Order is updated. <br/>
           
        Summary of order: <br/>
        Order No: ${orderNo}<br/> 
        Order Includes: <br/>
        Total samples: ${total}<br/>
        <br/>
        ${
          order && order.kitsNeeded
            ? `Sample Kits @ $${postage} postal charges`
            : ''
        }<br/>
        ${
          order && order.certsNeeded
            ? `DNA Certificates and @ $${postage} postal charges`
            : ''
        }
<br/>

        Order Cost: $${order && order.orderCost}<br/>
        <table border responsive size='lg'>
          <thead>
            <tr>
              <th width='200px'>Ring ID</th>
              <th width='200px'>Species</th>
              <th width='200px'>Test Type</th>       
              <th width='200px'>Owner</th>            
            </tr>
          </thead><tbody>${sasi}</tbody>
        </table><br/>
             </p>
          ${footerMessage}
        </div>
      </div>`,
    teamMessageUpdateOrder,
    `      <div class='col-lg-6'>
        <div>
          
          <p>Dear ${custName ? custName : custEmail},</p>
          <p>Your Order is deleted. <br/>
        </p>
          ${footerMessage}
        </div>
      </div>`,
    teamMessageDeletedOrder,
    teamMessageKitsNeeded,
  ]
  const toEmail = [
    custEmail,
    'orders@avigenicsusa.com',
    custEmail,
    'orders@avigenicsusa.com',
    custEmail,
    'orders@avigenicsusa.com',
    'orders@avigenicsusa.com',
  ]
  const mailSubject = [
    subject,
    'You have new Order: ',
    subject,
    'Updated Order: ',
    subject,
    'Deleted Order: ',
    'Sample Kits Needed for: ',
  ]
  await axios.post('/api/sendmail/', {
    to: toEmail[templateNo],
    text: textTemplate[templateNo],
    subject: mailSubject[templateNo] + ' ' + orderNo,
  })
}

export default SendMail

import { useState } from 'react'
import { Accordion, Card, Button, ListGroup } from 'react-bootstrap'
import { useGlobal } from '../config/GlobalContext'
import { useOrder } from '../config/OrderContext'
import OrderSummary from './OrderSummary'
import StripePay from './StripePay'

const OrdersInProgress = () => {
  const [selectedOrder, setSelectedOrder] = useState()
  const { allBatches, callStripe, setCallStripe } = useOrder()
  const { forStripe } = useGlobal()
  const allOrders = allBatches
  const orderToShow =
    allOrders &&
    allOrders.filter(
      (batch) =>
        batch.status !== 'Completed' &&
        batch.status !== 'Delete' &&
        batch.status !== 'In Progress'
    )

  const orderToShowHandler = (id) => {
    setSelectedOrder(orderToShow.filter((batch) => batch.id === id)[0])
  }

  return (
    <>
      <div className='auto-container'>
        <div className='row clearfix'>
          <div className='content-column col-lg-6 col-md-12 col-sm-12'>
            <div className='inner-column'>
              <div className='sec-title'>
                <div className='title'>Orders In Progress</div>
              </div>
              <Accordion defaultActiveKey='0'>
                {orderToShow && orderToShow.length > 0 ? (
                  orderToShow.map((batch, index) => (
                    <Card key={index}>
                      <Card.Header style={{ padding: '0px' }}>
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          onClick={() => orderToShowHandler(batch.id)}
                          eventKey={`'${index}'`}
                        >
                          {batch.batchName}
                        </Accordion.Toggle>
                        {batch.status}
                        {' ~ '}
                        {batch.payStatus === 'Not Paid' ? (
                          <>
                            {'  '} Order Not Paid {'  '}
                            <Button
                              style={{
                                padding: '4px',
                                borderRadius: '30px',
                                margin: '3px',
                                width: '100px',
                              }}
                              onClick={() => {
                                forStripe(batch)
                                setCallStripe(true)
                              }}
                            >
                              Pay Now{' '}
                            </Button>{' '}
                            <br />
                            Samples: {batch.samples &&
                              batch.samples.length}{' '}
                            Total: ${batch && batch.orderCost}
                          </>
                        ) : (
                          <>
                            Order Paid
                            <br />
                            Samples: {batch.samples &&
                              batch.samples.length}{' '}
                            Total: ${batch && batch.orderCost}
                          </>
                        )}
                      </Card.Header>
                      <Accordion.Collapse eventKey={`'${index}'`}>
                        <Card.Body>
                          {' '}
                          <ListGroup size='sm'>
                            {selectedOrder &&
                              selectedOrder.samples &&
                              selectedOrder.samples.length > 0 &&
                              selectedOrder.samples.map((sample, index) => (
                                <ListGroup.Item key={index}>
                                  {index + 1} &nbsp;&nbsp;&nbsp; {sample.ringNo}
                                  &nbsp;&nbsp;&nbsp; {
                                    sample.selectedSpecies
                                  }{' '}
                                  {selectedOrder.payStatus === 'Not Paid' &&
                                  selectedOrder.status === 'Results Ready'
                                    ? 'Please Pay to see results'
                                    : selectedOrder.status ===
                                        'Results Ready' && (
                                        <> ~ Results: {sample.results}</>
                                      )}
                                  &nbsp;&nbsp;&nbsp; Test:
                                  {sample.testType}
                                </ListGroup.Item>
                              ))}
                          </ListGroup>
                          <OrderSummary batch={batch} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))
                ) : (
                  <div style={{ color: 'red' }}>No Orders to show</div>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      {callStripe && <StripePay />}
    </>
  )
}

export default OrdersInProgress

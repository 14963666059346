import React, { useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'universal-cookie'

const Home = () => {
  const [show, setShow] = useState(true)
  const cookies = new Cookies()
  const [showMore, setShowMore] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
    cookies.get('consent') && setShow(false)
    const script = document.createElement('script')
    script.src = 'js/script.js'
    script.async = false
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <>
        {/* <Alert
          dismissible
          show={!show}
          variant='success'
          onClose={() => {
            cookies.set('consent', 'accepted', {
              path: '/',
              expires: new Date(Date.now() + 180000000),
            })
            setShow(false)
          }}
        >
          <Alert.Heading>Cookies!</Alert.Heading>
          <p>
            Cookies help us deliver our services. We also use third party
            cookies for analytics and payment gateway services. By using our
            services, you agree to our use of cookies.
          </p>
          <div className='d-flex justify-content-end'>
            <Button
              onClick={() => {
                setShow(false)
                cookies.set('consent', 'accepted', {
                  path: '/',
                  expires: new Date(Date.now() + 180000000),
                })
              }}
              variant='success'
            >
              OK
            </Button>
          </div>
        </Alert> */}
      </>

      <div className='page-wrapper'>
        {/* <!-- Banner Section Five --> */}
        <section className='banner-section-five col-12' align='right'>
          <div className='single-item-carousel owl-carousel owl-theme'>
            {/* <!-- Slide --> */}
            <div className='slide'>
              <div className='auto-container'>
                <div className='row clearfix'>
                  {/* <!-- Image Column --> */}
                  <div className='image-column '>
                    <div className='inner-column'>
                      <div className='image'>
                        <img src='../images/box/slider1.jpg' alt='slider' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Slide --> */}
            <div className='slide'>
              <div className='auto-container'>
                <div className='row clearfix'>
                  {/* <!-- Image Column --> */}
                  <div className='image-column col-lg-12 col-md-12 col-sm-12'>
                    <div className='inner-column'>
                      <div className='image'>
                        <img src='../images/box/slider2.jpg' alt='slider' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Slide --> */}
            <div className='slide'>
              <div className='auto-container'>
                <div className='row clearfix'>
                  {/* <!-- Image Column --> */}
                  <div className='image-column col-lg-12 col-md-12 col-sm-12'>
                    <div className='inner-column'>
                      <div className='image'>
                        <img src='../images/box/slider3.jpg' alt='slider' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Slide --> */}
            <div className='slide'>
              <div className='auto-container'>
                <div className='row clearfix'>
                  {/* <!-- Image Column --> */}
                  <div className='image-column col-lg-12 col-md-12 col-sm-12'>
                    <div className='inner-column'>
                      <div className='image'>
                        <img src='../images/box/slider4.jpg' alt='slider' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Banner Section --> */}

        {/* <!-- About Section / Style Two --> */}
        <section className='about-section style-two'>
          <div
            className='circle-layer'
            style={{
              backgroundImage: 'url(../images/icons/about-circle1.png)',
            }}
          ></div>
          <div className='auto-container'>
            <div className='row clearfix'>
              {/* <!-- Image Column --> */}
              <div className='image-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  <div
                    className='image wow fadeInLeft'
                    data-wow-delay='0ms'
                    data-wow-duration='1500ms'
                  >
                    {/* <div className='image titlt' data-tilt data-tilt-max='1'> */}
                    <img
                      src='../images/resource/scientist.jpg'
                      alt=''
                      style={{
                        boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.75)',
                        borderRadius: '50px',
                        border: '2px solid #0028A4',
                        clipPath:
                          'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)',
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* <!-- Content Column --> */}
              <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <div className='title'>Who We Are</div>
                    <h2>AVIGENICS</h2>
                  </div>
                  {/* <!-- <div className="bold-text">Over 12 years Merix helping companies reach their financial and branding goals.</div> --> */}
                  <div className='text'>
                    Avigenics is the UK's leading avian laboratory and is
                    excited to announce the opening of a new branch in USA. We
                    are a bunch of young professional experts in bird sexing
                    with the use of feather or blood samples. In our specialised
                    laboratories, we offer DNA, PBFD and APV testing. Avigenics
                    is committed to the highest level of service in the avian
                    DNA testing industry due to the accuracy & unparalleled
                    trust-worthiness and high-quality technology used.
                  </div>
                  <h4
                    onClick={() => {
                      window.scrollTo(0, 1000)
                      setShowMore(!showMore)
                    }}
                    className='theme-btn btn-style-five'
                  >
                    <span className='txt'>
                      Read {!showMore ? 'More' : 'Less'}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section / Style Two --> */}

        {/* <!-- News Section Two --> */}

        {showMore && (
          <section className='mission-section clearfix'>
            <div className='auto-container'>
              <div className='row clearfix'>
                {/* <!-- Content Column --> */}
                <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                  <div className='inner-column'>
                    {/* <!-- Sec Title --> */}
                    <div className='sec-title'>
                      <h2>Quality without compromise</h2>
                    </div>
                    <div className='bold-text'>
                      As a leading DNA testing firm, Avigenics guarantees the
                      highest level of quality service in testing. Our team
                      consists of highly skilled, young DNA professionals who
                      are focused on offering a friendly customer service. We
                      perform quality driven DNA tests based on scientifically
                      tested methods.
                    </div>
                    <ul className='mission-list'>
                      <h6 className='text-dark'>
                        <b>
                          In order to find your bird’s gender, please send us
                          either:
                        </b>
                      </h6>
                      <br />
                      <li>
                        <span className='icon'>
                          <img src='images/icons/mission-1.png' alt='' />
                        </span>
                        Blood Samples
                      </li>
                      <li>
                        <span className='icon'>
                          <img src='images/icons/mission-2.png' alt='' />
                        </span>
                        Feather Samples
                      </li>
                      {/* <li>
                        <span className='icon'>
                          <img src='images/icons/mission-3.png' alt='' />
                        </span>
                        Eggshell Samples
                      </li> */}
                    </ul>
                  </div>
                </div>

                {/* <!-- Image Column --> */}
                <div className='image-column col-lg-6 col-md-12 col-sm-12'>
                  <div className='inner-column'>
                    <div
                      className='image wow fadeInLeft'
                      data-wow-delay='0ms'
                      data-wow-duration='1500ms'
                    >
                      <img src='images/resource/mission.png' alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <section className='news-section-two'>
          <div
            className='pattern-layer'
            style={{ backgroundImage: 'url(images/background/pattern-11.png)' }}
          ></div>
          <div className='auto-container'>
            {/* <!-- Sec Title --> */}
            <div className='sec-title centered'>
              <div className='title'>AVIGENICS</div>
              <h2>Our Services</h2>
              <div className='text'></div>
            </div>

            <div className='row clearfix'>
              {/* <!-- News Block Two --> */}
              <div
                className='news-block-two col-lg-4 col-md-6
				col-sm-12'
              >
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='image'>
                    <img src='../images/resource/services1.jpg' alt='' />
                  </div>
                  <div className='lower-content'>
                    <h5>
                      <a href='/DNASexing'>DNA Sexing</a>
                    </h5>
                    <div className='text'>
                      DNA sexing for birds can be performed with feather or
                      blood samples. We have access to the world's largest avian
                      DNA samples database with over 2000 different species of
                      birds from around the world.
                      <br />
                      <br />
                    </div>
                    {/* <!-- Social Boxed -->  */}
                    <div className='btn-box text-center'>
                      <Nav.Link as={Link} to={'/DNASexing'}>
                        <span className='theme-btn btn-style-five'>
                          Full Details
                        </span>
                      </Nav.Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- News Block Two --> */}
              <div
                className='news-block-two col-lg-4 col-md-6
				col-sm-12'
              >
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='image'>
                    <img src='../images/resource/services2.jpg' alt='' />
                  </div>
                  <div className='lower-content'>
                    <h5>
                      <a href='/PBFD'>PBFD Testing</a>
                    </h5>
                    <div className='text'>
                      Avigenics offers genetic testing for Psittacine Beak
                      Feather Disease: Type 1 and Type 2. We also offer Elisa
                      testing to determine if the bird has developed an immunity
                      to the virus that causes PBFD. <br />
                      <br />
                    </div>
                    {/* <!-- Social Boxed -->  */}
                    <div className='btn-box text-center'>
                      <Nav.Link as={Link} to={'/PBFD'}>
                        <span className='theme-btn btn-style-five'>
                          Full Details
                        </span>
                      </Nav.Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- News Block Two --> */}
              <div
                className='news-block-two col-lg-4 col-md-6
				col-sm-12'
              >
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='image'>
                    <img src='../images/resource/services3.jpg' alt='' />
                  </div>
                  <div className='lower-content'>
                    <h5>
                      <a href='/APV'>Avian Polyoma Virus</a>
                    </h5>
                    <div className='text'>
                      The Avian Polyoma Virus is one of the most significant
                      viral pathogens in parrots that causes substantial
                      economic losses for aviculturists. Signs and symptoms of
                      APV are very similar to and often confused with PBFD.
                    </div>
                    {/* <!-- Social Boxed -->  */}
                    <div className='btn-box text-center'>
                      <Nav.Link as={Link} to={'/APV'}>
                        <span className='theme-btn btn-style-five'>
                          Full Details
                        </span>
                      </Nav.Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End News Section Two --> */}

        {/* <!-- Steps Section --> */}
        <section
          className='steps-section'
          style={{
            backgroundImage: 'url(../images/background/pattern-14.png)',
          }}
        >
          <div className='auto-container'>
            {/* <!-- Sec Title --> */}
            <div className='sec-title centered'>
              {/* <!-- <div className="title">HOW WE DO IT</div> --> */}
              <h2>HOW WE DO IT</h2>
            </div>

            <div className='row clearfix'>
              {/* <!-- Step Block --> */}
              <div className='step-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='icon-box'>
                    <div className='number'>
                      <img
                        src='../images/background/feather1.png'
                        alt=''
                        width='60%'
                      />
                    </div>
                  </div>
                  <h6>Sample Collection</h6>
                </div>
              </div>

              {/* <!-- Step Block --> */}
              <div className='step-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='icon-box'>
                    <div className='number'>
                      <img
                        src='../images/background/title-icon1.png'
                        alt=''
                        width='60%'
                      />
                    </div>
                  </div>
                  <h6>Extract DNA</h6>
                </div>
              </div>

              {/* <!-- Step Block --> */}
              <div className='step-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='icon-box'>
                    <div className='number'>
                      <img
                        alt=''
                        src='../images/background/tut.png'
                        width='80%'
                      />
                    </div>
                  </div>
                  <h6>Lab Analysis</h6>
                </div>
              </div>

              {/* <!-- Step Block --> */}
              <div className='step-block col-lg-3 col-md-6 col-sm-12'>
                <div
                  className='inner-box wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <div className='icon-box'>
                    <div className='number'>
                      <img
                        alt=''
                        src='../images/background/report.png'
                        width='50%'
                      />
                    </div>
                  </div>
                  <h6>DNA Report</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Steps Section --> */}

        {/* <!-- Skill Section --> */}
        {/* <section className='skill-section'>
          <div className='auto-container'>
            <div className='row clearfix'>
              <div className='counter-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  <div className='sec-title'>
                    <div className='title'>Avigenics</div>
                    <h2>Our Products (Coming soon....)</h2>
                  </div>
                  <div
                    className='text'
                    style={{
                      lineHeight: 2,
                      fontSize: '1.4rem',
                      textAlign: 'justify',
                    }}
                  >
                    With our wealth of experience in the field, we offer you the
                    best quality bird rings available, in a defined time with
                    affordable rates. We understand the importance of quality
                    regarding your bird's leg band and we assure you that there
                    is no margin for error. Since the ring is with the bird for
                    life, we take maximum care in ensuring the best possible
                    standard to ensure a good imprint, size, colour and shape of
                    the rings.
                  </div>
                </div>
              </div>

              <div className='image-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  <div className='image titlt' data-tilt data-tilt-max='3'>
                    <img src='images/resource/skill.png' alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <!-- Call To Action Section Two --> */}
        <section
          className='call-to-action-section-two'
          style={{
            backgroundImage: 'url(../images/background/map-pattern-1.png)',
          }}
        >
          <div
            className='pattern-layer-one'
            style={{
              backgroundImage: 'url(../images/background/pattern-22.png)',
            }}
          ></div>
          <div
            className='pattern-layer-two'
            style={{
              backgroundImage: 'url(../images/background/pattern-9.png)',
            }}
          ></div>
          <div className='auto-container'>
            <div className='row clearfix'>
              {/* <!-- Image Column --> */}
              <div className='image-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  <div className='image titlt' data-tilt data-tilt-max='3'>
                    <img src='../images/resource/cta-1.png' alt='' />
                  </div>
                </div>
              </div>

              {/* <!-- Title Column --> */}
              <div className='title-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  <h2>Go To Enquiry</h2>
                  <div className='text'>
                    Make the right choice for your bird DNA test.
                  </div>
                  <a href='/ContactUs' className='theme-btn btn-style-three'>
                    <span className='txt'>Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Call To Action Section Two --> */}

        {/* <!-- Testimonial Section Two --> */}
        <section className='testimonial-section-two'>
          <div
            className='pattern-layer'
            style={{
              backgroundImage: 'url(../images/background/pattern-10.png)',
            }}
          ></div>
          <div className='auto-container'>
            {/* <!-- Sec Title --> */}
            <div className='sec-title centered'>
              <div className='title'>Testimonials</div>
              <h2>
                What others say about us? <br />
              </h2>
              <div className='text'>Coming soon...</div>
            </div>
          </div>
        </section>
        {/* <!-- End Testimonial Section Two --> */}
      </div>
      {/* <!--End pagewrapper--> */}
      {/* <!-- Scroll To Top --> */}
      <div
        className='back-to-top scroll-to-target show-back-to-top'
        data-target='html'
      >
        TOP
      </div>
    </div>
  )
}

export default Home

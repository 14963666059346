import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Table } from 'react-bootstrap'
import { useOrder } from '../../config/OrderContext'
import moment from 'moment'

const UpdatePrices = () => {
  const { config, updateConfig } = useOrder()
  const [configDetails, setConfigDetails] = useState({})

  useEffect(() => {
    setConfigDetails(config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <h1>Update Prices</h1>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <h5> Current Offer Dates:</h5>
        <h6>
          Start Date: {config && Math.round(config.offerStartDate / 10000)}
        </h6>
        <h6>End Date: {config && Math.round(config.offerEndDate / 10000)}</h6>
      </div>
      <br />
      <Form.Group as={Row}>
        <Form.Label column sm='2'>
          Certificates:
        </Form.Label>
        ${' '}
        <Form.Control
          type='number'
          style={{ width: '150px', padding: '0px', margin: '0px' }}
          defaultValue={config && config.certsPrice}
          onChange={(e) => {
            setConfigDetails({
              ...configDetails,
              certsPrice: e.target.valueAsNumber,
            })
          }}
        />
        <Form.Label column sm='2'>
          Offer Start Date
        </Form.Label>
        <Form.Control
          type='date'
          style={{ width: '150px', padding: '0px', margin: '0px' }}
          defaultValue={config && config.offerStartDate}
          onChange={(e) => {
            setConfigDetails({
              ...configDetails,
              offerStartDate: moment(e.target.value)
                .utcOffset('0:00')
                .format('YYYYMMDDHHmm'),
            })
          }}
        />
        <Form.Label column sm='2'>
          Offer End Date
        </Form.Label>
        <Form.Control
          type='date'
          style={{ width: '150px', padding: '0px', margin: '0px' }}
          defaultValue={config && config.offerEndDate}
          onChange={(e) => {
            setConfigDetails({
              ...configDetails,
              offerEndDate: moment(e.target.value)
                .utcOffset('0:00')
                .format('YYYYMMDDHHmm'),
            })
          }}
        />
      </Form.Group>
      <br />
      <Form.Group as={Row}>
        <Form.Label column sm='2'>
          Slab1 Target
        </Form.Label>
        <Form.Control
          type='number'
          style={{ width: '50px', padding: '0px', margin: '0px' }}
          defaultValue={config && config.slab1Target}
          onChange={(e) => {
            setConfigDetails({
              ...configDetails,
              slab1Target: e.target.valueAsNumber,
            })
          }}
        />
        <Form.Label column sm='1'>
          Slab2 Target
        </Form.Label>
        <Form.Control
          type='number'
          style={{ width: '50px', padding: '0px', margin: '0px' }}
          defaultValue={config && config.slab2Target}
          onChange={(e) => {
            setConfigDetails({
              ...configDetails,
              slab2Target: e.target.valueAsNumber,
            })
          }}
        />
        <Form.Label column sm='1'>
          Kits Postage:
        </Form.Label>
        $
        <Form.Control
          type='number'
          style={{ width: '70px', padding: '0px', margin: '0px' }}
          defaultValue={config && config.speedPost}
          onChange={(e) => {
            setConfigDetails({
              ...configDetails,
              speedPost: e.target.valueAsNumber,
            })
          }}
        />
        <Form.Label column sm='2'>
          Certs Postage
        </Form.Label>
        $
        <Form.Control
          type='number'
          style={{ width: '70px', padding: '0px', margin: '0px' }}
          defaultValue={config && config.privateCourier}
          onChange={(e) => {
            setConfigDetails({
              ...configDetails,
              privateCourier: e.target.valueAsNumber,
            })
          }}
        />
      </Form.Group>
      <br />

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Item</th>
            <th>Regular Price $</th>
            <th>Slab1 Price $</th>
            <th>Slab2 Price $</th>
            <th>Offer Price $</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>DNA Only</td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaOnlyPrice}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaOnlyPrice: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaSlab1Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaSlab1Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaSlab2Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaSlab2Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaDiscount}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaDiscount: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
          </tr>
          <tr>
            <td>APV Only</td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.apvOnlyPrice}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    apvOnlyPrice: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.apvSlab1Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    apvSlab1Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.apvSlab2Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    apvSlab2Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.apvDiscount}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    apvDiscount: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
          </tr>
          <tr>
            <td>PBFD Only</td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.pbfdOnlyPrice}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    pbfdOnlyPrice: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.pbfdSlab1Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    pbfdSlab1Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.pbfdSlab2Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    pbfdSlab2Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.pbfdDiscount}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    pbfdDiscount: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
          </tr>
          <tr>
            <td>DNA & APV</td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaApvPrice}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaApvPrice: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaApvSlab1Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaApvSlab1Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaApvSlab2Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaApvSlab2Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaApvDiscount}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaApvDiscount: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
          </tr>
          <tr>
            <td>DNA & PBFD</td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaPbfdPrice}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaPbfdPrice: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaPbfdSlab1Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaPbfdSlab1Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaPbfdSlab2Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaPbfdSlab2Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaPbfdDiscount}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaPbfdDiscount: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
          </tr>
          <tr>
            <td>PBFD & APV</td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.pbfdApvPrice}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    pbfdApvPrice: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.pbfdApvSlab1Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    pbfdApvSlab1Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.pbfdApvSlab2Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    pbfdApvSlab2Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.pbfdApvDiscount}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    pbfdApvDiscount: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
          </tr>
          <tr>
            <td>DNA, PBFD & APV</td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaPbfdApvPrice}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaPbfdApvPrice: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaPbfdApvSlab1Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaPbfdApvSlab1Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaPbfdApvSlab2Price}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaPbfdApvSlab2Price: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
            <td>
              <Form.Control
                type='number'
                style={{ width: '200px', padding: '0px', margin: '0px' }}
                defaultValue={config && config.dnaPbfdApvDiscount}
                onChange={(e) => {
                  setConfigDetails({
                    ...configDetails,
                    dnaPbfdApvDiscount: e.target.valueAsNumber,
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <Button
        type='submit'
        variant='primary'
        onClick={async () => {
          await updateConfig('Sasi', configDetails)
          alert('Updated Successfully')
        }}
      >
        Update
      </Button>
    </>
  )
}

export default UpdatePrices

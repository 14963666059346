import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import firebase from 'firebase/app'
import 'firebase/firestore'

const Price = () => {
  const PriceConfig = firebase.firestore().collection('config')
  const [config, setConfig] = useState({})
  useEffect(() => {
    window.scrollTo(0, 0)
    getPriceConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPriceConfig = async () => {
    await PriceConfig.onSnapshot(
      (querySnapshot) => {
        let rcvdConfig = []
        querySnapshot.forEach((doc) => {
          rcvdConfig.push({ id: doc.id, ...doc.data() })
        })
        setConfig(rcvdConfig[0])
      },
      (err) => {
        return null
      }
    )
  }

  return (
    <div>
      <div className='page-wrapper'>
        {/* <!-- Page Title --> */}
        {!isMobile && (
          <>
            <div className='inner-banner'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='inner-banner-text'>
                      <h2>Current Prices</h2>
                      <div className='inner-line'>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='clearfix' />
              </div>
            </div>
          </>
        )}
        <section className='mission-section clearfix'>
          <div className='auto-container'>
            <div className='row clearfix'>
              <h4>
                If you prefer not to order online, you can download the manual
                order form
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://firebasestorage.googleapis.com/v0/b/avigenics-usa.appspot.com/o/Avigenics%20Order%20Form.pdf?alt=media&token=fd6b169e-c19d-44c0-9026-6cfe175492ee'
                >
                  {'  '}
                  here
                </a>
              </h4>{' '}
              <h6>
                * You should be able to print this form. If you cannot print it,
                please call us for a copy of the order form to be posted to you.
              </h6>
              <h6>
                ** Please note that you still need to call us if you need sample
                kits. Otherwise, you can use your own self-sealing bags.
              </h6>
              <br />
              <br />
              <br />
              <br />
              {/* <!-- Image Column --> */}
              <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <div className='title'>DNA Sexing</div>
                  </div>
                  <div className='bold-text'>
                    <h5>
                      For 1 to {config && config.slab1Target} samples, $
                      {config && config.dnaOnlyPrice} for each sample. <br />
                      For {config && Number(config.slab1Target) + 1} to{' '}
                      {config && Number(config.slab2Target)} samples, $
                      {config && config.dnaSlab1Price} for each sample. <br />
                      For {config && Number(config.slab2Target) + 1} or more
                      samples, ${config && config.dnaSlab2Price} for each sample{' '}
                      <br />
                    </h5>
                  </div>
                </div>
              </div>
              {/* <!-- Content Column --> */}
              <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <div className='title'>PBFD / APV</div>
                  </div>
                  <div className='bold-text'>
                    <h5>
                      PBFD: <br />
                      For 1 to {config && config.slab1Target} samples, $
                      {config && config.pbfdOnlyPrice} for each sample. <br />
                      For {config && Number(config.slab1Target) + 1} to{' '}
                      {config && Number(config.slab2Target)} samples, $
                      {config && config.pbfdSlab1Price} for each sample. <br />
                      For {config && Number(config.slab2Target) + 1} or more
                      samples, ${config && config.pbfdSlab2Price} for each
                      sample <br />
                      <br />
                      APV:
                      <br />
                      For 1 to {config && config.slab1Target} samples, $
                      {config && config.apvOnlyPrice} for each sample. <br />
                      For {config && Number(config.slab1Target) + 1} to{' '}
                      {config && Number(config.slab2Target)} samples, $
                      {config && config.apvSlab1Price} for each sample. <br />
                      For {config && Number(config.slab2Target) + 1} or more
                      samples, ${config && config.apvSlab2Price} for each sample{' '}
                      <br />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='auto-container'>
            <div className='row clearfix'>
              {/* <!-- Image Column --> */}
              <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <div className='title'>
                      Bundled Prices (For 1 to {config && config.slab1Target}{' '}
                      samples):
                    </div>
                  </div>
                  <div className='bold-text'>
                    <h5>
                      DNA and PBFD: ${config && config.dnaPbfdPrice} each
                      sample. <br />
                      DNA and APV: ${config && config.dnaApvPrice} each sample.{' '}
                      <br />
                      PBFD and APV: ${config && config.pbfdApvPrice} each
                      sample. <br />
                      DNA, PBFD and APV: ${config && config.dnaPbfdApvPrice}
                      each sample. <br />
                    </h5>
                  </div>
                </div>
              </div>{' '}
              <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <div className='title'>
                      Bundled Prices (For{' '}
                      {config && Number(config.slab1Target) + 1} to{' '}
                      {config && Number(config.slab2Target)} samples):
                    </div>
                  </div>
                  <div className='bold-text'>
                    <h5>
                      DNA and PBFD: ${config && config.dnaPbfdSlab1Price} each
                      sample. <br />
                      DNA and APV: ${config && config.dnaApvSlab1Price} each
                      sample. <br />
                      PBFD and APV: ${config && config.pbfdApvSlab1Price} each
                      sample. <br />
                      DNA, PBFD and APV: $
                      {config && config.dnaPbfdApvSlab1Price} each sample.{' '}
                      <br />
                    </h5>
                  </div>
                </div>
              </div>
              <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <div className='title'>
                      Bundled Prices (For{' '}
                      {config && Number(config.slab2Target) + 1} or more
                      samples):
                    </div>
                  </div>
                  <div className='bold-text'>
                    <h5>
                      DNA and PBFD: ${config && config.dnaPbfdSlab2Price} each
                      sample. <br />
                      DNA and APV: ${config && config.dnaApvSlab2Price} each
                      sample. <br />
                      PBFD and APV: ${config && config.pbfdApvSlab2Price} each
                      sample. <br />
                      DNA, PBFD and APV: $
                      {config && config.dnaPbfdApvSlab2Price} each sample.{' '}
                      <br />
                    </h5>
                  </div>
                </div>
              </div>
              <div className='content-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <div className='title'>Printed certificates</div>
                  </div>
                  <div className='bold-text'>
                    <h5>
                      ${config && config.certsPrice} each DNA certificate.
                      <br />
                      On top of it, ${config && config.speedPost} for postage
                      will be charged per order.
                      {/* Postage as below (per each batch/order): <br />
                      Speed Post: ${config && config.speedPost}/- <br />
                      Private Courier: ${config && config.privateCourier}/-{' '} */}
                      <br />
                    </h5>
                  </div>
                </div>
                <div className='inner-column'>
                  {/* <!-- Sec Title --> */}
                  <div className='sec-title'>
                    <div className='title'>Sample Kits</div>
                  </div>
                  <div className='bold-text'>
                    <h5>
                      Sample kits are free but we charge only $
                      {config && config.speedPost} for postage per order.
                      {/* Postage as below (per each batch/order): <br />
                      Speed Post: ${config && config.speedPost}/- <br />
                      Private Courier: ${config && config.privateCourier}/-{' '} */}
                      <br />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!--End pagewrapper--> */}

      {/* <!-- Scroll To Top --> */}
    </div>
  )
}

export default Price

import React from 'react'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { Card, Button } from 'react-bootstrap'
import { QRCodeSVG } from 'qrcode.react'
import './Admin.css'

const PDFCertificate = (props) => {
  const curr = new Date()
  curr.setDate(curr.getDate())
  const date = curr.toISOString().split('T')[0]
  const range = props.range

  const resultArray = props.values.filter(
    (item) => item.LabID >= range.start && item.LabID <= range.end
  )

  const generatePdf = () => {
    html2canvas(document.getElementById('CERT1'), { allowTaint: true }).then(
      (canvas) => {
        let HTML_Width = canvas.width
        // let HTML_Height = canvas.height
        let HTML_Height = canvas.height * 0.85
        let top_left_margin = 0
        let PDF_Width = HTML_Width + top_left_margin * 2
        // let PDF_Height = PDF_Width * 1.5 + top_left_margin * 2
        let PDF_Height = 1200

        let canvas_image_width = HTML_Width
        let canvas_image_height = HTML_Height
        let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1
        canvas.getContext('2d')
        let imgData = canvas.toDataURL('image/jpeg', 1.0)
        let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
        pdf.addImage(
          imgData,
          'JPG',
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        )
        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage([PDF_Width, PDF_Height], 'p')
          pdf.addImage(
            imgData,
            'JPG',
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          )
        }
        pdf.save(`PDF to Print ${date}.pdf`)
      }
    )
  }
  return (
    <>
      <div align={'right'}>
        <Button onClick={generatePdf}>Save</Button>
      </div>
      <div id='CERT1'>
        {resultArray &&
          resultArray.map((rslt) => (
            <div key={rslt.LabID} className='d-flex justify-content-around'>
              {/* {(rslt.Results === 'Male' || rslt.Results === 'Female') && ( */}
              <table style={{ width: '2100px' }}>
                <tbody>
                  <tr>
                    <td>
                      <Card>
                        <Card.Body style={{ padding: '16px' }}>
                          <div
                            align={'left'}
                            style={{
                              backgroundImage:
                                rslt.Results === 'Male'
                                  ? 'url(/images/MaleCC.png)'
                                  : rslt.Results === 'Female'
                                  ? 'url(/images/FemaleCC.png)'
                                  : 'url(/images/OtherCC.png)',
                              backgroundSize: '854px',
                              minHeight: '248px',
                              minWidth: '854px',
                              overflow: 'hidden',
                              paddingRight: '5px',
                              paddingLeft: '428px',
                            }}
                          >
                            <div
                              align={'center'}
                              style={{
                                width: '410px',
                                marginTop: '12px',
                                marginLeft: '10px',
                              }}
                            >
                              <h6 style={{ color: 'white' }}>
                                Certificate # {rslt.Certificate}
                              </h6>
                            </div>
                            <div style={{ marginLeft: '31px' }}>
                              <h6
                                style={{
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                }}
                              >
                                Ring#/Bird ID: {rslt['Ring No']}
                              </h6>
                              <h6
                                style={{
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                }}
                              >
                                Species: {rslt['Species Name']}
                              </h6>
                              <h6
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  fontFamily: 'MyFont',
                                }}
                              >
                                Owner: {rslt.Owner}
                              </h6>
                              <h6
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  fontFamily: 'MyFont',
                                }}
                              >
                                Farm Name: {rslt.Farm}
                              </h6>
                              <h6
                                style={{
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                  marginBottom: '2px',
                                }}
                              >
                                Report Date:
                                {new Date(rslt.ResultDate).toLocaleDateString(
                                  'en-US',
                                  {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric',
                                  }
                                )}
                              </h6>
                            </div>
                            <div
                              align='right'
                              style={{
                                paddingRight: '20px',
                              }}
                            >
                              <QRCodeSVG
                                style={{
                                  height: '80px',
                                  width: '80px',
                                }}
                                value={
                                  rslt['Ring No'] +
                                  ' ' +
                                  rslt['Species Name'] +
                                  ':  ' +
                                  rslt.Results
                                    ? rslt.Results
                                    : 'Yet to be updated'
                                }
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
      </div>
      <div align={'right'}>
        <Button onClick={generatePdf}>Save</Button>
      </div>
    </>
  )
}

export default PDFCertificate

import { useState } from 'react'
import {
  Accordion,
  Card,
  Button,
  ListGroup,
  Form,
  Modal,
  Row,
  Col,
  Alert,
} from 'react-bootstrap'
import StripePay from './StripePay'
import * as Icon from 'react-bootstrap-icons'
import OrderSummary from './OrderSummary'
import ModifyOrder from './ModifyOrder'
import { useOrder } from '../config/OrderContext'
import { useGlobal } from '../config/GlobalContext'

const OrdersTobePosted = () => {
  const [selectedOrder, setSelectedOrder] = useState()
  const [payDisable, setPayDisable] = useState(false)
  const [showModifySampleModal, setShowModifySampleModal] = useState(false)
  const [idx, setIdx] = useState(10000)
  const [post, setPost] = useState(false)
  const [showModifyOrder, setShowModifyOrder] = useState(false)
  const [message, setMessage] = useState('')
  const [confirmDialog, setConfirmDialog] = useState(false)
  const {
    allBatches,
    handlePostOrder,
    deleteBatch,
    currentUser,
    payDetails,
    callStripe,
    setCallStripe,
  } = useOrder()
  const { samplesUpdate, forStripe } = useGlobal()
  const allOrders = allBatches
  const orderToShow =
    allOrders && allOrders.filter((batch) => batch.status === 'In Progress')
  const orderToShowHandler = (id) => {
    setSelectedOrder(orderToShow.filter((batch) => batch.id === id)[0])
  }
  const modifySamples = () => {
    setSelectedOrder({
      ...selectedOrder,
      samples: selectedOrder.samples,
    })
    samplesUpdate(selectedOrder.id, selectedOrder.samples)
    setPayDisable(false)
  }
  const postOrder = () => {
    handlePostOrder(
      selectedOrder && selectedOrder.id,
      selectedOrder && selectedOrder.courierCompany
        ? selectedOrder.courierCompany
        : 'No Company',
      selectedOrder && selectedOrder.courierRef
        ? selectedOrder.courierRef
        : 'No Reference'
    )
    setPost(false)
  }

  return (
    <>
      <div className='content-column col-lg-6 col-md-12 col-sm-12'>
        <div className='inner-column'>
          <div className='sec-title'>
            <div className='title'>Pending orders yet to be posted</div>
            <br />{' '}
            <Form.Text style={{ color: 'blue' }}>
              <strong>
                Address to send samples:
                <br /> Avigenics INC,
                <br /> 1562 Belvedere Ave,
                <br /> Okemos, MI 48864,
                <br /> USA.
              </strong>
            </Form.Text>
          </div>
          <Accordion defaultActiveKey='0'>
            {orderToShow && orderToShow.length > 0 ? (
              orderToShow.map((batch, index) => (
                <div key={index}>
                  <Card>
                    <Card.Header style={{ padding: '0px' }}>
                      <Accordion.Toggle
                        as={Button}
                        variant='link'
                        eventKey={`'${index}'`}
                        onClick={() => {
                          orderToShowHandler(batch.id)
                        }}
                      >
                        {batch.batchName}
                      </Accordion.Toggle>
                      {batch.payStatus === 'Not Paid' ? (
                        <>
                          Order Not Paid {'  '}
                          <Button
                            disabled={payDisable}
                            style={{
                              padding: '4px',
                              borderRadius: '30px',
                              margin: '3px',
                              width: '100px',
                            }}
                            onClick={() => {
                              forStripe(batch)
                              setCallStripe(true)
                            }}
                          >
                            Pay Now{' '}
                          </Button>
                        </>
                      ) : (
                        <> Order Paid &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                      )}{' '}
                      <br />
                    </Card.Header>

                    <Card.Body>
                      {batch.payStatus === 'Not Paid' && (
                        <>
                          <Button
                            style={{
                              padding: '4px',
                              borderRadius: '30px',
                              margin: '3px',
                              width: '100px',
                            }}
                            variant='danger'
                            onClick={() => {
                              deleteBatch(batch.id, batch.batchName)
                            }}
                          >
                            Delete
                          </Button>
                          &nbsp;
                          <Button
                            style={{
                              padding: '4px',
                              borderRadius: '30px',
                              margin: '3px',
                              width: '120px',
                            }}
                            onClick={() => {
                              setSelectedOrder(batch)
                              batch.samples &&
                                batch.samples.length > 0 &&
                                setShowModifyOrder(true)
                            }}
                          >
                            Modify Order{' '}
                          </Button>{' '}
                        </>
                      )}
                      <Button
                        style={{
                          padding: '4px',
                          borderRadius: '30px',
                          margin: '3px',
                          width: '100px',
                        }}
                        className='navButtonText'
                        onClick={() => {
                          orderToShowHandler(batch.id)
                          setPost(true)
                        }}
                      >
                        {' '}
                        Post
                      </Button>
                    </Card.Body>
                    <Accordion.Collapse eventKey={`'${index}'`}>
                      <Card.Body>
                        <ListGroup size='sm'>
                          {selectedOrder &&
                            selectedOrder.samples &&
                            selectedOrder.samples.length > 0 &&
                            selectedOrder.samples.map((sample, index) => (
                              <ListGroup.Item key={index}>
                                {index + 1} &nbsp;&nbsp;&nbsp; {sample.ringNo}
                                &nbsp;&nbsp;&nbsp; {sample.selectedSpecies}{' '}
                                &nbsp;&nbsp;&nbsp;{' '}
                                <Icon.PencilSquare
                                  fill='blue'
                                  onClick={() => {
                                    setIdx(index)
                                    setPayDisable(true)
                                    setShowModifySampleModal(true)
                                  }}
                                />{' '}
                                Test:
                                {sample.testType}
                              </ListGroup.Item>
                            ))}
                        </ListGroup>
                        <OrderSummary batch={batch} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              ))
            ) : (
              <div style={{ color: 'red' }}>No Orders to show</div>
            )}
          </Accordion>
        </div>
      </div>

      {callStripe && <StripePay payDetails={payDetails} />}
      <Modal
        show={showModifySampleModal}
        onHide={() => {
          setPayDisable(false)
          setShowModifySampleModal(false)
        }}
      >
        <Modal.Header closeButton>
          Modify details of sample no {idx + 1}:
        </Modal.Header>
        <Form.Group as={Row}>
          <Form.Label column sm='3'>
            {' '}
            &nbsp;&nbsp; Ring No:
          </Form.Label>
          <Col sm='8'>
            <Form.Control
              key={idx}
              defaultValue={
                selectedOrder &&
                selectedOrder.samples &&
                selectedOrder.samples[idx] &&
                selectedOrder.samples[idx].ringNo
              }
              required
              onChange={(e) =>
                (selectedOrder.samples[idx].ringNo = e.target.value)
              }
            />
          </Col>
        </Form.Group>{' '}
        <Form.Group as={Row}>
          <Form.Label column sm='3'>
            {' '}
            &nbsp;&nbsp; Species:
          </Form.Label>
          <Col sm='8'>
            <Form.Control
              key={idx}
              defaultValue={
                selectedOrder &&
                selectedOrder.samples &&
                selectedOrder.samples[idx] &&
                selectedOrder.samples[idx].selectedSpecies
              }
              required
              onChange={(e) =>
                (selectedOrder.samples[idx].selectedSpecies = e.target.value)
              }
            />
          </Col>
        </Form.Group>
        <div align='right'>
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              modifySamples()
              setShowModifySampleModal(false)
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Modal show={post} onHide={() => setPost(false)}>
        <Modal.Header closeButton>Enter postal details here</Modal.Header>
        <Form.Group as={Row}>
          <Form.Label column sm='3'>
            {' '}
            &nbsp;&nbsp; Reference:
          </Form.Label>
          <Col sm='8'>
            <Form.Control
              key={idx}
              value={selectedOrder && selectedOrder.courierRef}
              placeholder='Reference, if any'
              onChange={(e) =>
                setSelectedOrder({
                  ...selectedOrder,
                  courierRef: e.target.value,
                })
              }
            />
          </Col>
        </Form.Group>{' '}
        <Form.Group as={Row}>
          <Form.Label column sm='3'>
            {' '}
            &nbsp;&nbsp; Courier:
          </Form.Label>
          <Col sm='8'>
            <Form.Control
              key={idx}
              value={selectedOrder && selectedOrder.courierCompany}
              placeholder='Postal/Courier Company'
              onChange={(e) =>
                setSelectedOrder({
                  ...selectedOrder,
                  courierCompany: e.target.value,
                })
              }
            />
          </Col>
        </Form.Group>
        <div align='right'>
          <Button
            style={{
              padding: '4px',
              borderRadius: '30px',
              margin: '3px',
              width: '100px',
            }}
            onClick={() => {
              postOrder()
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showModifyOrder}
        onHide={() => {
          setConfirmDialog(true)
          setMessage(
            'If you close, changes will not be saved. Click on submit button to save the order'
          )
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>{' '}
        </Modal.Header>
        <Modal.Body>
          <ModifyOrder
            order={selectedOrder}
            custId={currentUser && currentUser.email}
            setShowModifyOrder={setShowModifyOrder}
            page={'Order'}
          />
        </Modal.Body>
      </Modal>
      <Modal
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-80w'
        style={{ marginTop: '100px' }}
        show={
          message ===
          'If you close, changes will not be saved. Click on submit button to save the order'
        }
        onHide={() => setMessage('')}
      >
        <Modal.Body>
          {' '}
          <Alert variant='warning' onClose={() => setMessage('')}>
            If you select Close button, your changes will not be saved. Click on
            Cancel to return to order page.
            {confirmDialog && (
              <>
                {' '}
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                <Button
                  style={{
                    padding: '1px',
                    borderRadius: '30px',
                    margin: '1px',
                    width: '60px',
                  }}
                  variant='danger'
                  onClick={() => {
                    setMessage()
                    setShowModifyOrder(false)
                    setConfirmDialog(false)
                  }}
                >
                  Close
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  style={{
                    padding: '1px',
                    borderRadius: '30px',
                    margin: '1px',
                    width: '60px',
                  }}
                  onClick={() => {
                    setMessage()
                    setConfirmDialog(false)
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </Alert>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OrdersTobePosted

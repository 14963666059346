import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const SampleCollection = () => {
  return (
    <div>
      {/* <!-- Page Title --> */}
      <section className='page_breadcrumb'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <ul className='page_bread'>
                <li>
                  <Nav.Link style={{ padding: '0px' }} as={Link} to='/'>
                    Home
                  </Nav.Link>
                </li>
                <li className='current_page'>Sample Collection</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Page Title --> */}

      {/* <!-- Mission Section --> */}
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            <h4>
              For DNA Sexing, you can send either Blood or Feather Samples. For
              PBFD and APV we recommend both Blood and Feathers for more
              accuracy.
            </h4>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/blood.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />{' '}
                  <img
                    src='images/resource/bloodsample.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  <div className='title'>Blood Sample Collection</div>
                </div>
                <div className='bold-text lh-lg'>
                  {' '}
                  Wipe the bird's nail with an alcohol swab and let dry(5-10
                  seconds) and ensure that the toenail clipper is thoroughly
                  cleaned between birds. Clip the nail short enough to allow
                  bleeding (avoid clipping too high since this may be
                  uncomfortable for the bird and difficult to stop bleeding). If
                  blood is not present, gently squeeze the toe until blood
                  appears. Allow a small amount of blood to bleed up and
                  carefully touch the card/collection paper to the blood (only
                  one or two drops is required). Allow the card/paper to dry and
                  place it into the self-sealing bag. For detailed instructions
                  or further information write to{' '}
                  <a href='mailto:info@avigenicsusa.com'>
                    info@avigenicsusa.com
                  </a>{' '}
                  or call us on{' '}
                  <a href='tel:+13133389022'>+ 1 (313) 338-9022</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {' '}
            <h4>
              For PBFD or APV, please collect blood and feather samples in
              separate bags but make sure you write same ring ID on both labels.
            </h4>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/feather.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>
            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  <div className='title'>Feather Sample Collection</div>
                </div>
                <div className='bold-text lh-lg'>
                  {' '}
                  Select medium sized feathers for plucking. The feather must be
                  physically plucked (molted feathers cannot be used for this
                  test). Very small feathers are not recommended. Pluck 4-6
                  medium sized feathers from the chest area of the bird and
                  place them in a self sealing bag. Ensure the feather tip,
                  which is attached to the skin, is present (white bulb). For
                  detailed instructions or further information write to{' '}
                  <a href='mailto:info@avigenicsusa.com'>
                    info@avigenicsusa.com
                  </a>{' '}
                  or call us on{' '}
                  <a href='tel:+13133389022'>+ 1 (313) 338-9022</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            {/* <!-- Image Column --> */}
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/eggshell.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                {/* <!-- Sec Title --> */}
                <div className='sec-title'>
                  {/* <div className='title'>AVIGENICS</div> */}
                  <h2>Egg Shell Sample</h2>
                </div>
                <div className='bold-text'>
                  We currently are NOT accepting Egg shell samples.
                  <br /> If you still wish to send Egg Shells, please send us
                  but it is your own risk. If we cannot extract DNA, we may ask
                  for repeat samples and you may have to send blood or feathers.
                  For more information, please write to us at:
                  <a href='mailto:info@avigenicsusa.com'>
                    info@avigenicsusa.com
                  </a>{' '}
                  or call us on{' '}
                  <a href='tel:+13133389022'>+ 1 (313) 338-9022</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section Three --> */}

      {/* <!-- Mission Section --> */}

      {/* <!-- End Services Section Three --></section> */}
    </div>
  )
}
export default SampleCollection

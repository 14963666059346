/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useAdmin } from '../../config/AdminContext'
import { useGlobal } from '../../config/GlobalContext'
import AdminModals from './AdminModals'
import { useAuth } from '../../config/AuthContext'
import { useOrder } from '../../config/OrderContext'
import * as Icon from 'react-bootstrap-icons'
import * as XLSX from 'xlsx'

const DashBoard = () => {
  const { calcTotal } = useGlobal()
  const {
    allBatches,
    updateWithCustName,
    allCustomers,
    setClickedButton,
    setFilterEnabled,
    setSelectedCustomer,
    findDuplicateCustomers,
    findDuplicateOrders,
    duplicateCustomers,
    setDuplicateCustomers,
    duplicateOrders,
    setDuplicateOrders,
    updateWithOrderCost,
    getCustomer,
    loading,
    setLoading,
  } = useAdmin()
  const [noCustName, setNoCustName] = useState()
  const [showModal, setShowModal] = useState({ name: '', show: false })
  const [allSamples] = useState([])
  const [duplicateSamples, setDuplicateSamples] = useState()
  const { currentUser } = useAuth()
  const { allSpecies, setAllSpecies, updateSpecies } = useOrder()
  const [showSpecies, setShowSpecies] = useState(false)
  const [edit, setEdit] = useState(false)
  const [addNew, setAddNew] = useState()
  const [selectedRow, setSelectedRow] = useState()

  const updateCustName = () => {
    let cust = []
    allBatches &&
      allBatches.forEach((batch) => {
        if (
          !batch.customerName ||
          batch.customerName === 'Customer Name need to be updated'
        ) {
          cust = getCustName(batch.custId)
          cust && updateWithCustName(batch.id, cust)
        }
      })
    setNoCustName()
  }

  const updateOrderCost = () => {
    setLoading(true)
    let cost = 0
    allBatches &&
      allBatches.forEach((batch) => {
        const customer = getCustomer(batch.custId)
        if (!batch.orderCost && batch.orderCost !== 0) {
          cost = calcTotal(batch, customer)
          updateWithOrderCost(batch.id, cost)
        }
      })
    setLoading(false)
  }

  const findNoCustName = () => {
    let cust = []
    allBatches &&
      allBatches.forEach((batch) => {
        if (
          !batch.customerName ||
          batch.customerName === 'Customer Name need to be updated'
        ) {
          cust.push({
            order: batch.batchName,
            customer: batch.custId,
            id: batch.id,
          })
        }
      })
    setNoCustName(cust)
  }

  const getCustName = (custId) => {
    const customer = allCustomers.filter(
      (customer) => customer.email === custId
    )
    if (!customer || customer.length === 0) {
      console.log(custId)
    } else {
      return customer[0].name
    }
  }

  const findDuplicateSamples = () => {
    flattenSamples()
    const lookup =
      allSamples &&
      allSamples.reduce((a, e) => {
        a[e.dupValue] = ++a[e.dupValue] || 0
        return a
      }, {})
    setDuplicateSamples(
      allSamples && allSamples.filter((e) => lookup[e.dupValue])
    )
  }

  const flattenSamples = () => {
    allSamples.length = 0
    allBatches.forEach((batch) => {
      batch.samples.map((sample) =>
        allSamples.push({
          dupValue:
            'Ring No: ' +
            sample.ringNo.toUpperCase() +
            ' Species: ' +
            sample.selectedSpecies +
            ' Order: ' +
            batch.batchName,
        })
      )
    })
  }

  const handleRemove = async (index) => {
    const newSpecies = allSpecies.AllSpecies.filter((_, item) => item !== index)
    setAllSpecies((prev) => ({ AllSpecies: newSpecies }))
  }

  const uploadSpecies = async (e) => {
    const file = e.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */

      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
      /* Update state */
      const AllSpecies = []
      data.forEach((item) => {
        AllSpecies.push(item[0])
      })
      setAllSpecies({ AllSpecies })
    }
    reader.readAsBinaryString(file)
  }
  return (
    <div>
      <Button
        className='my-button'
        onClick={() => {
          setFilterEnabled(true)
          setClickedButton('All Customers')
        }}
      >
        Find Customer{' '}
      </Button>{' '}
      <Button
        className='my-button'
        onClick={async () => {
          await setSelectedCustomer({})
          await setShowModal({ name: 'Customer', show: true })
        }}
      >
        Create Customer
      </Button>{' '}
      <Button
        className='my-button'
        onClick={async () => {
          setShowSpecies((prev) => true)
        }}
      >
        Manage Species
      </Button>
      <Modal show={showSpecies} onHide={() => setShowSpecies(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Species</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: '60vh', overflowY: 'scroll' }}>
          <div className='d-flex flex-column'>
            <Form.Group>
              <Form.Control
                type='text'
                value={addNew}
                placeholder='Enter Species'
                onChange={(e) => setAddNew(e.target.value)}
              />
              <Button
                className='my-button'
                onClick={() => {
                  allSpecies.AllSpecies.push(addNew)
                  setAddNew((prev) => '')
                  setAllSpecies({ AllSpecies: allSpecies.AllSpecies })
                }}
              >
                Add Species
              </Button>
            </Form.Group>
            <table>
              <tbody>
                {allSpecies &&
                  allSpecies.AllSpecies.map((species, index) => (
                    <tr key={index}>
                      <td width={'80%'}>
                        <Form.Control
                          type='text'
                          style={{ color: selectedRow === index && 'red' }}
                          defaultValue={species}
                          disabled={!edit}
                          onChange={(e) => {
                            allSpecies.AllSpecies[index] = e.target.value
                          }}
                        />
                      </td>
                      <td>
                        <Icon.TrashFill
                          fill='red'
                          style={{ cursor: 'hand' }}
                          onClick={() => {
                            setSelectedRow(index)
                            handleRemove(index)
                          }}
                        />
                      </td>
                      <td>
                        <Icon.PencilSquare
                          fill='blue'
                          style={{ cursor: 'hand' }}
                          onClick={() => {
                            setEdit((prev) => !prev)
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {' '}
          <Form.Control
            type='file'
            onChange={(e) => {
              uploadSpecies(e)
            }}
          />
          {/* <Button

            className='my-button'
            onClick={() => {
              setShowSpecies((prev) => false)
            }}
          >
            Close
          </Button> */}
          {'   '}
          <Button
            className='my-button'
            onClick={() => {
              alert('Write code to export to excel')
            }}
          >
            Export to Excel
          </Button>
          {'   '}
          <Button
            className='my-button'
            onClick={() => {
              updateSpecies(allSpecies)
              setEdit((prev) => false)
              setAllSpecies({ AllSpecies: allSpecies.AllSpecies })
            }}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Button
        disabled={currentUser.email !== 'avigenics@gmail.com'}
        className='my-button'
        onClick={async () => {
          const resp = await axios.get(
            'https://us-central1-avigenics-dev.cloudfunctions.net/api/'
          )
          console.log(resp)
          // const resp = await axios.get(
          //   'http://localhost:5001/avigenics-dev/us-central1/api'
          // )
          // await axios.post(
          //   'https://us-central1-avigenics-dev.cloudfunctions.net/api/sendsms',
          //   {
          //     mobile: '07801355883',
          //     text: 'Hello',
          //   }
          // )
        }}
      >
        Test{' '}
      </Button> */}
      <h1>Special Functions (Daily Checks)</h1>
      <Button
        className='my-button'
        onClick={() => {
          setDuplicateOrders()
          findDuplicateCustomers(allCustomers)
        }}
      >
        Find Duplicate Emails{' '}
      </Button>{' '}
      <Button
        className='my-button'
        onClick={() => {
          setDuplicateCustomers()
          findDuplicateOrders(allBatches)
        }}
      >
        Find Duplicate Orders{' '}
      </Button>{' '}
      <Button
        className='my-button'
        onClick={() => {
          findDuplicateSamples()
        }}
      >
        Find Duplicate Samples{' '}
      </Button>{' '}
      <Button className='my-button' onClick={findNoCustName}>
        No CustName ?
      </Button>{' '}
      <Button
        className='my-button'
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(allCustomers)
        )}`}
        download={`customers${Date.now()}.json`}
      >
        Bkup Customers{' '}
      </Button>{' '}
      <Button
        className='my-button'
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(allBatches)
        )}`}
        download={`batches${Date.now()}.json`}
      >
        Backup Batches
      </Button>
      {noCustName &&
        noCustName.map((custName) => (
          <div key={custName.id}>
            Order: <span>{custName.order}</span>
            {'______'}
            Email:
            <span>{custName.customer}</span>
            {'______'}
            ID: <span>{custName.id}</span>
          </div>
        ))}
      {noCustName &&
        (noCustName.length > 0 ? (
          <Button
            disabled={currentUser.email !== 'avigenics@gmail.com'}
            className='my-button'
            style={{ backgroundColor: 'red' }}
            onClick={updateCustName}
          >
            Update Customer Names
          </Button>
        ) : (
          <h5>No Need any update</h5>
        ))}
      <Button
        disabled={currentUser.email !== 'avigenics@gmail.com'}
        className='my-button'
        style={{ backgroundColor: 'red' }}
        onClick={updateOrderCost}
      >
        {loading ? 'Updating....' : 'Update Order Cost'}
      </Button>
      <div>
        Duplicate Samples:
        {duplicateSamples &&
          (duplicateSamples.length > 0 ? (
            duplicateSamples.map((sample, index) => (
              <div style={{ backgroundColor: 'gray' }} key={index}>
                <span> {sample.dupValue}</span>
              </div>
            ))
          ) : (
            <h5 className='blink_me2'>No Duplicate Samples Found!</h5>
          ))}
      </div>
      <div>
        Duplicate Orders:
        {duplicateOrders &&
          (duplicateOrders.length > 0 ? (
            duplicateOrders.map((order) => (
              <div style={{ backgroundColor: 'red' }} key={order.id}>
                Duplicate Orders:
                <span> {order.batchName}</span>{' '}
                <span> {order.customerName}</span>
              </div>
            ))
          ) : (
            <h5 className='blink_me2'>No Duplicate Orders Found!</h5>
          ))}
      </div>
      <div>
        Duplicate Emails:
        {duplicateCustomers &&
          (duplicateCustomers.length > 0 ? (
            duplicateCustomers.map((cust) => (
              <div key={cust.id}>
                <span> {cust.name}</span> <span> {cust.email}</span>
              </div>
            ))
          ) : (
            <h5 className='blink_me2'>No Duplicate Customers Found!</h5>
          ))}
      </div>
      {showModal.show && (
        <AdminModals showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  )
}

export default DashBoard

import { useEffect } from 'react'
import { useAdmin } from '../../config/AdminContext'
import ComponentToShow from './ComponentToShow'

const AllOrders = () => {
  const { findFiltered, allBatches } = useAdmin()
  useEffect(() => {
    findFiltered('All')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBatches])

  return <ComponentToShow prev={''} next={''} />
}

export default AllOrders

const AddDelete = () => {
  return (
    <>
      <h6>
        {' '}
        Yes. You can add more samples to or delete samples from current order
        until payment is complete.
      </h6>
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/addmore.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div className='sec-title'>
                  <h3>Add more samples </h3>
                  <h6>
                    {' '}
                    To add more samples, Click on Modify Order button for the
                    specified order under PENDING ORDERS YET TO BE POSTED.
                    <br /> Start adding ring no and species name and click on
                    Add. <br /> Click on{' '}
                    <b style={{ backgroundColor: 'yellow' }}>Submit</b> button
                    when finished.
                    <br />
                  </h6>
                </div>
                <div className='bold-text'></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mission-section clearfix'>
        <div className='auto-container'>
          <div className='row clearfix'>
            <div className='image-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div
                  className='image wow fadeInLeft'
                  data-wow-delay='0ms'
                  data-wow-duration='1500ms'
                >
                  <img
                    src='images/resource/delete1.png'
                    alt=''
                    style={{
                      borderRadius: '15px',
                      boxShadow: '7px 5px 5px 0px rgba(0,0,0,0.5)',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='content-column col-lg-6 col-md-12 col-sm-12'>
              <div className='inner-column'>
                <div className='sec-title'>
                  <h3>Delete samples </h3>
                </div>
                <h6>
                  You can delete the samples while you are on Modify screen as
                  shown here. <br />
                  or <br /> By clicking on the trash can icon after clicking
                  order number Click on Submit.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddDelete
